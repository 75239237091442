import { combineReducers } from 'redux';
import { draftInvoiceReducer } from './drafts/reducers';
import { finalInvoiceReducer } from './finals/reducers';
import { invoiceConfigReducer } from './configs/reducers';
import { invoiceItemTemplateReducer } from './itemTemplates/reducres';

export const invoiceReducers = combineReducers({
    drafts: draftInvoiceReducer,
    finals: finalInvoiceReducer,
    configs: invoiceConfigReducer,
    itemTemplates: invoiceItemTemplateReducer,
});
