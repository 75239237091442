import React, { useState } from 'react';
import Customer from '../../../../models/customer';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/MoreVert';
import Notes from './Notes/Notes';
import Note from '../../../../models/note';
import { getCompanyAndDepartmentString, getAddressString } from '../utils';
import { personToString } from '../../../../utils/modelsToString';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';

interface ContactViewerProps {
    customer?: Customer;
    onOpenEdit: (customer: Customer) => void;
    onDelete: (c: Customer) => void;
    onClone: (c: Customer) => void;
    onAddInvoiceForCustomer: (c: Customer) => void;
    onShowInvoices: (c: Customer) => void;
}

const ContactViewer: React.FC<ContactViewerProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action: string) => () => {
        setAnchorEl(null);
        if (!props.customer) {
            return;
        }
        switch (action) {
            case 'open-for-edit':
                props.onOpenEdit(props.customer);
                break;
            case 'add-invoice':
                props.onAddInvoiceForCustomer(props.customer);
                break;
            case 'show-invoices':
                props.onShowInvoices(props.customer);
                break;
            case 'clone':
                props.onClone(props.customer);
                break;
            case 'delete':
                if (window.confirm('Wollen Sie den Kontakt unwiederruflich löschen?')) {
                    props.onDelete(props.customer);
                }
                break;
            default:
                break;
        }
    };

    const my = 1;

    return (
        <Box textAlign="left">
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant="h6">Kontakt-Details</Typography>
                    {
                        props.customer ?
                            <Typography variant="body2">
                                Erstellt: {moment.unix(props.customer.objectInfos.createdAt).format('DD.MM.YYYY HH:mm')} von {props.customer.objectInfos.createdBy}
                                {props.customer.objectInfos.updatedAt ?
                                    <span>
                                        , Zuletzt modifiziert: {moment.unix(props.customer.objectInfos.updatedAt).format(' DD.MM.YYYY HH:mm')} von {props.customer.objectInfos.updatedBy}
                                    </span> : ''}
                            </Typography>
                            : null
                    }
                </Box>
                <Box>
                    {
                        props.customer ? <React.Fragment>
                            <IconButton aria-controls="action-menu" aria-haspopup="true" onClick={handleClick}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="action-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose('')}
                            >
                                <MenuItem onClick={handleClose('open-for-edit')}>Bearbeiten</MenuItem>
                                <MenuItem onClick={handleClose('add-invoice')} disabled>Neue Rechnung</MenuItem>
                                <MenuItem onClick={handleClose('show-invoices')} disabled>Alte Rechnungen anzeigen</MenuItem>
                                <MenuItem onClick={handleClose('clone')} disabled>Duplizieren</MenuItem>
                                <MenuItem onClick={handleClose('delete')}>
                                    <Typography color="error">
                                        Löschen
                                </Typography>
                                </MenuItem>
                            </Menu>
                        </React.Fragment> : null
                    }
                </Box>
            </Box>
            <Divider />
            {props.customer ?
                <React.Fragment>
                    <Box my={my}>
                        <Typography variant="subtitle2">
                            Firma:
                        </Typography>
                        <Typography variant="body1">
                            {getCompanyAndDepartmentString(props.customer)}
                        </Typography>
                    </Box>
                    <Box my={my}>
                        <Typography variant="subtitle2">
                            Kontaktperson:
                        </Typography>
                        <Typography variant="body1">
                            {personToString(props.customer.contactPerson, undefined, true)}
                        </Typography>
                    </Box>

                    <Box display="flex">
                        <Box my={my} width="50%">
                            <Typography variant="subtitle2">
                                Rechnungsadresse:
                        </Typography>
                            <Typography variant="body1">
                                {getAddressString(props.customer.invoiceAddress)}
                            </Typography>
                        </Box>
                        <Box my={my} width="50%">
                            <Typography variant="subtitle2">
                                Postadresse:
                        </Typography>
                            <Typography variant="body1">
                                {getAddressString(props.customer.postAddress)}
                            </Typography>
                        </Box>
                    </Box>

                    {props.customer.VAT.length > 0 ?
                        <Box my={my}>
                            <Typography variant="subtitle2">
                                USt.-ID.:
                            </Typography>
                            <Typography variant="body1">
                                {props.customer.VAT}
                            </Typography>
                        </Box> : null}

                    <Box display="flex">
                        {props.customer.references.customerNumber.length > 0 ?
                            <Box my={my} width="50%">
                                <Typography variant="subtitle2">
                                    Kundennummer:
                                </Typography>
                                <Typography variant="body1">
                                    {props.customer.references.customerNumber}
                                </Typography>
                            </Box>
                            : null}

                        {props.customer.references.internalReference.length > 0 ?
                            <Box my={my} width="50%">
                                <Typography variant="subtitle2">
                                    Interne Referenz:
                                </Typography>
                                <Typography variant="body1">
                                    {props.customer.references.internalReference}
                                </Typography>
                            </Box>
                            : null}
                    </Box>


                    <Box display="flex">
                        <Box my={my} width="50%">
                            <Typography variant="subtitle2">
                                Zustellart der Rechnung:
                            </Typography>
                            <Typography variant="body1">
                                {
                                    props.customer.contactPreferences.useEmailForInvoice ?
                                        props.customer.contactPreferences.emailForInvoice
                                        : 'per Post'
                                }
                            </Typography>
                        </Box>

                        {props.customer.contactPreferences.phone.length > 0 ?
                            <Box my={my}>
                                <Typography variant="subtitle2">
                                    Telefon
                            </Typography>
                                <Typography variant="body1">
                                    {props.customer.contactPreferences.phone}
                                </Typography>
                            </Box>
                            : null}
                    </Box>

                    {
                        props.customer.contactPreferences.otherEmails.length > 0 ?
                            <Box my={my} width="50%">
                                <Typography variant="subtitle2">
                                    Emailadressen:
                            </Typography>
                                <Typography variant="body1">
                                    {
                                        props.customer.contactPreferences.otherEmails.map((email: string, ind: number) => {
                                            let v = email;
                                            const lastInd = props.customer ? props.customer.contactPreferences.otherEmails.length - 1 : 0;
                                            if (ind < lastInd) {
                                                v += ', '
                                            }
                                            return v;
                                        })
                                    }
                                </Typography>
                            </Box>
                            : null
                    }

                    {
                        props.customer.contactPreferences.websites.length > 0 ?
                            <Box my={my} width="50%">
                                <Typography variant="subtitle2">
                                    Webseiten:
                            </Typography>
                                <Typography variant="body1">
                                    {
                                        props.customer.contactPreferences.websites.map((email: string, ind: number) => {
                                            let v = email;
                                            const lastInd = props.customer ? props.customer.contactPreferences.websites.length - 1 : 0;
                                            if (ind < lastInd) {
                                                v += ', '
                                            }
                                            return v;
                                        })
                                    }
                                </Typography>
                            </Box>
                            : null
                    }

                    <Divider />
                    <Notes
                        notes={props.customer.notes}
                        onAddNote={(note: Note) => {
                            console.warn('todo: add note', note);
                        }}
                        onRemoveNote={(note: Note) => {
                            console.warn('todo: add note', note);
                        }}
                    />

                    <Box textAlign="right">
                        <Fab onClick={
                            () => props.customer ? props.onOpenEdit(props.customer) : null
                        }>
                            <EditIcon />
                        </Fab>
                    </Box>
                </React.Fragment>
                :
                <Box p={2} textAlign="center">
                    <Typography>
                        Wählen Sie einen Kontakt aus
                    </Typography>
                </Box>
            }

        </Box>

    );
};

export default ContactViewer;
