import React from 'react';

const FlexGrow: React.FC = () => {
    return (
        <div style={{
            flexGrow: 1
        }}
        ></div>
    );
};
export default FlexGrow;