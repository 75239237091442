import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        flexBasis: "80px",
        backgroundColor: theme.palette.grey[800],
        color: '#ffffff',
    },
    link: {
        color: '#ffffff',
        textDecoration: 'none',
        margin: theme.spacing(0, 1),
    },
    activeLink: {
        fontWeight: 'bold',
    }
}));

const Footer: React.FC = () => {
    const classes = useStyles();

    return (
        <Grid container item justify="center" alignItems="center" className={classes.root} >
            <Typography variant="body2">
                <NavLink className={classes.link} activeClassName={classes.activeLink} to="/start/impressum">Impressum</NavLink>
                <NavLink className={classes.link} activeClassName={classes.activeLink} to="/start/privacy">Datenschutzerklärung</NavLink>
            </Typography>
        </Grid>
    );
};
export default Footer;
