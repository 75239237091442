import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

interface DurationEditorProps {
    duration?: number;
    onChange: (newValue: number) => void;
}

const DurationEditor: React.FC<DurationEditorProps> = (props) => {
    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'

    const [hours, setHours] = useState(props.duration ? Math.floor(props.duration) : 0);
    const [minutes, setMinutes] = useState(props.duration ? (Math.round((60 * props.duration) % 60)) : 0);

    const minuteInputChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        let value: number | string = event.target.value as string;
        value = parseInt(value);
        value = Number.isNaN(value) ? 0 : value;

        value = value > 59 ? 59 : value
        setMinutes(value);
    }

    const hoursInputChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        let value: number | string = event.target.value as string;
        value = parseInt(value);
        value = Number.isNaN(value) ? 0 : value;
        setHours(value);
    }

    useEffect(() => {
        const value = Math.round((hours + minutes / 60.0) * 100.0);
        props.onChange(value / 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hours, minutes]);

    return (
        <Box display="flex" flexWrap="wrap">
            <Box width="50%">

                <TextField
                    fullWidth
                    label="Stunden"
                    type="number"
                    value={hours.toString()}
                    variant={variant} margin={margin}
                    inputProps={{
                        min: "0"
                    }}
                    onChange={hoursInputChanged}
                />
            </Box>
            <Box width="50%" pl={1}>
                <TextField
                    fullWidth
                    label="Minuten"
                    type="number"
                    inputProps={{
                        min: "0", max: "59"
                    }}
                    value={minutes.toString()}
                    variant={variant} margin={margin}
                    onChange={minuteInputChanged}
                />
            </Box>
        </Box>
    );
};

export default DurationEditor;
