import {
    FinalInvoiceState,
    FinalInvoiceActionTypes,
    FETCH_FINAL_INVOICES_START, FETCH_FINAL_INVOICES_FAILED, RECEIVE_FINAL_INVOICES,
    ADD_FINAL_INVOICE, UPDATE_FINAL_INVOICE, SELECT_FINAL_INVOICE, SET_FINAL_INVOICE_QUERY,
} from './types'
import { updateObject, updateArrayItemById } from '../../utils'
import moment from 'moment';


const initialState: FinalInvoiceState = {
    loading: false,
    items: [],
    selected: undefined,
    query: {
        type: 'last',
        lastDays: 30,
        start: moment().endOf('day').subtract(60, 'days').unix(),
        end: moment().endOf('day').unix(),
    }
}

export function finalInvoiceReducer(
    state = initialState,
    action: FinalInvoiceActionTypes
): FinalInvoiceState {
    switch (action.type) {
        case FETCH_FINAL_INVOICES_START:
            return updateObject(state, { loading: true });
        case FETCH_FINAL_INVOICES_FAILED:
            return updateObject(state, { loading: false });
        case RECEIVE_FINAL_INVOICES:
            return updateObject(state, {
                loading: false, items: [...action.invoices]
            });
        case ADD_FINAL_INVOICE:
            return updateObject(state,
                { loading: false, items: [...state.items, action.newInvoice], }
            );
        case UPDATE_FINAL_INVOICE:
            const newSelection = (state.selected && state.selected.id === action.updatedInvoice.id)
                ? { ...action.updatedInvoice } : state.selected;
            return updateObject(state, {
                loading: false,
                items: updateArrayItemById(state.items, action.updatedInvoice),
                selected: newSelection
            });
        case SELECT_FINAL_INVOICE:
            return updateObject(state, { selected: action.invoice });
        case SET_FINAL_INVOICE_QUERY:
            return updateObject(state, { query: action.query });
        default:
            return state
    }
}


