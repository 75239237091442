import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DraftInvoiceState } from '../../../../store/invoices/drafts/types';
import { FinalInvoiceState, InvoiceQuery } from '../../../../store/invoices/finals/types';
import { selectDraftInvoice } from '../../../../store/invoices/drafts/actions';
import { fetchFinalInvoices, fetchDraftInvoices, getInvoiceTags } from '../../../../store/invoices/actionsAsync';

import DraftInvoicesTable from '../../../../components/ui/Invoice/InvoiceTables/DraftInvoicesTable/DraftInvoicesTable';
import DraftInvoicePreview from '../../../../components/ui/Invoice/Preview/DraftInvoicePreview/DraftInvoicePreview';

import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from '@material-ui/core/Typography';
import FlexGrow from '../../../../components/common/FlexGrow/FlexGrow';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Divider, IconButton } from '@material-ui/core';
import { Invoice } from '../../../../models/invoice';
import { initNewEditor } from '../../../../store/invoiceEditor/actions';
import FinalInvoicePreview from '../../../../components/ui/Invoice/Preview/FinalInvoicePreview/FinalInvoicePreview';
import FinalInvoicesTable from '../../../../components/ui/Invoice/InvoiceTables/FinalInvoicesTable/FinalInvoicesTable';
import { selectFinalInvoice, setFinalInvoiceQuery } from '../../../../store/invoices/finals/actions';
import moment from 'moment';
import { downloadPDF } from '../../../../utils/invoiceToPdf';
import { fetchAllCustomers } from '../../../../store/addressbook/actionAsync';
import { InvoiceConfigsState } from '../../../../store/invoices/configs/types';
import { getInvoiceItemTemplates } from '../../../../store/invoices/itemTemplates/actionsAsync';
import ColumnHeader from '../../../../components/common/ColumnHeader';




const InvoiceDashboard: React.FC = () => {
    const isLoadingDrafts = useSelector((state: { invoices: { drafts: DraftInvoiceState } }) => state.invoices.drafts.loading);
    const isLoadingFinals = useSelector((state: { invoices: { finals: FinalInvoiceState } }) => state.invoices.finals.loading);
    const draftInvoices = useSelector((state: { invoices: { drafts: DraftInvoiceState } }) => state.invoices.drafts.items);
    const selectedDraftInvoice = useSelector((state: { invoices: { drafts: DraftInvoiceState } }) => state.invoices.drafts.selected);

    const finalInvoices = useSelector((state: { invoices: { finals: FinalInvoiceState } }) => state.invoices.finals.items);
    const selectedFinalInvoice = useSelector((state: { invoices: { finals: FinalInvoiceState } }) => state.invoices.finals.selected);
    const finalInvoiceQuery = useSelector((state: { invoices: { finals: FinalInvoiceState } }) => state.invoices.finals.query);

    const documentTemplates = useSelector((state: { invoices: { configs: InvoiceConfigsState } }) => state.invoices.configs.templates);
    const invoiceTags = useSelector((state: { invoices: { configs: InvoiceConfigsState } }) => state.invoices.configs.tags);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDraftInvoices());
        const q = finalInvoiceQueryToRequest(finalInvoiceQuery);
        dispatch(fetchFinalInvoices(q.start, q.end));
        dispatch(fetchAllCustomers());
        dispatch(getInvoiceItemTemplates());
        dispatch(getInvoiceTags());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const finalInvoiceQueryToRequest = (query: InvoiceQuery): { start: number, end: number } => {
        if (query.type === 'last') {
            return {
                start: moment().endOf('day').subtract(query.lastDays, 'days').unix(),
                end: 0,
            };
        }
        return {
            start: query.start,
            end: query.end
        };
    }


    return (
        <React.Fragment>
            {isLoadingDrafts || isLoadingFinals ? <LinearProgress /> : null}
            <Grid container spacing={0}>
                <Grid item xs={12} md={8}>
                    <Box borderRight="solid 1px rgba(0, 0, 0, 0.12)">
                        <ColumnHeader borderBottom>
                            <Typography variant="h4">
                                {'Rechnungen'}
                            </Typography>
                        </ColumnHeader>
                        <Box
                            bgcolor="white"
                            style={{
                                overflow: 'scroll',
                                maxHeight: 'calc(100vh - 75px)'
                            }}
                        >
                            <Box>
                                <Box p={2}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="h6">
                                            Entwürfe
                                        </Typography>
                                        <FlexGrow />
                                        <Tooltip title="Liste aktualisieren">
                                            <IconButton aria-label="refresh"
                                                onClick={() => { dispatch(fetchDraftInvoices()); }}
                                            >
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Neue Rechnung">
                                            <IconButton color="primary" aria-label="add"
                                                onClick={() => {
                                                    history.push('/home/invoice-editor');
                                                    dispatch(initNewEditor('create'));
                                                }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <DraftInvoicesTable
                                        invoices={draftInvoices}
                                        invoiceTags={invoiceTags}
                                        selectedID={selectedDraftInvoice ? selectedDraftInvoice.id : undefined}
                                        onSelectInvoice={(invoice) => {
                                            dispatch(selectDraftInvoice(invoice));
                                            dispatch(selectFinalInvoice(undefined));
                                        }}
                                        onOpenInvoice={(invoice) => {
                                            dispatch(selectFinalInvoice(undefined));
                                            dispatch(selectDraftInvoice(invoice));
                                            history.push('/home/invoice');
                                        }}
                                    />
                                </Box>

                            </Box>

                            <Box mt={4} p={2} pb={30}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6">
                                        Vorherige Rechnungen
                                            </Typography>
                                    <FlexGrow />
                                    <Tooltip title="Liste aktualisieren">
                                        <IconButton aria-label="refresh"
                                            onClick={() => {
                                                const q = finalInvoiceQueryToRequest(finalInvoiceQuery);
                                                dispatch(fetchFinalInvoices(q.start, q.end));
                                            }}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <FinalInvoicesTable
                                    invoices={finalInvoices}
                                    invoiceTags={invoiceTags}
                                    selectedID={selectedFinalInvoice?.id}
                                    onSelectInvoice={(invoice) => {
                                        dispatch(selectFinalInvoice(invoice));
                                        dispatch(selectDraftInvoice(undefined));
                                    }}
                                    query={finalInvoiceQuery}
                                    onInvoiceQueryChanged={(query: InvoiceQuery) => {
                                        dispatch(setFinalInvoiceQuery(query))
                                        const q = finalInvoiceQueryToRequest(query);
                                        dispatch(fetchFinalInvoices(q.start, q.end));
                                    }}
                                    onOpenInvoice={(invoice) => {
                                        dispatch(selectDraftInvoice(undefined));
                                        dispatch(selectFinalInvoice(invoice));
                                        history.push('/home/invoice');
                                    }}
                                />
                            </Box>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    {
                        selectedDraftInvoice ? null : <FinalInvoicePreview
                            invoice={selectedFinalInvoice}
                            onOpen={() => {
                                history.push('/home/invoice');
                            }}
                            onClone={(invoice: Invoice) => {
                                dispatch(initNewEditor('create', { ...invoice }));
                                history.push('/home/invoice-editor');
                            }}
                            onStorno={(invoice: Invoice) => console.warn('todo: create strono invoice')}
                            onDownload={(invoice: Invoice) => {
                                let templateDef = documentTemplates.find(t => t.id === invoice.templateReference.id);
                                if (!templateDef) {
                                    templateDef = documentTemplates[0];
                                }
                                downloadPDF(invoice, templateDef.template)
                            }}
                        />
                    }

                    {selectedDraftInvoice ?
                        <DraftInvoicePreview
                            invoice={selectedDraftInvoice}
                            onOpen={() => {
                                history.push('/home/invoice');
                            }}
                        />
                        : null}



                </Grid>

            </Grid>
        </React.Fragment>
    );
};

export default InvoiceDashboard;
