import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { changeBaseURL } from '../api';

export interface AppState {
    version: string;
    apiURL: string;
    organisation: string;
}

export const initialState: AppState = {
    version: process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '',
    apiURL: '',
    organisation: process.env.REACT_APP_DEFAULT_ORG ? process.env.REACT_APP_DEFAULT_ORG : '',
};

const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        reset: (state) => {
            state = { ...initialState };
            return state;
        },
        setVersion: (state, action: PayloadAction<string>) => {
            state.version = action.payload;
        },
        setApiURL: (state, action: PayloadAction<string>) => {
            changeBaseURL(action.payload);
            state.apiURL = action.payload;
        },
        setOrganisation: (state, action: PayloadAction<string>) => {
            state.organisation = action.payload;
        }
    },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
