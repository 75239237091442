import {
    InvoiceItemTemplatesState,
    InvoiceItemTemplateActionTypes,
    FETCH_INVOICE_ITEM_TEMPLATES_START,
    FETCH_INVOICE_ITEM_TEMPLATES_FAILED,
    RECEIVE_INVOICE_ITEM_TEMPLATES,
    ADD_INVOICE_ITEM_TEMPLATE,
    REMOVE_INVOICE_ITEM_TEMPLATE,
} from './types'
import { updateObject } from '../../utils'


const initialState: InvoiceItemTemplatesState = {
    loading: false,
    templates: [],
}

export function invoiceItemTemplateReducer(
    state = initialState,
    action: InvoiceItemTemplateActionTypes
): InvoiceItemTemplatesState {
    switch (action.type) {
        case FETCH_INVOICE_ITEM_TEMPLATES_START:
            return updateObject(state, { loading: true });
        case FETCH_INVOICE_ITEM_TEMPLATES_FAILED:
            return updateObject(state, { loading: false });
        case RECEIVE_INVOICE_ITEM_TEMPLATES:
            return updateObject(state, {
                loading: false, templates: [...action.templates]
            });
        case ADD_INVOICE_ITEM_TEMPLATE:
            return updateObject(state,
                { loading: false, templates: [...state.templates, action.template], }
            );
        case REMOVE_INVOICE_ITEM_TEMPLATE:
            return updateObject(state, {
                loading: false,
                templates: state.templates.filter(temp => temp.id !== action.template.id),
            });
        default:
            return state
    }
}


