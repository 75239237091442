import React from 'react';
import Person from '../../../../models/person';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

interface PersonFormProps {
    person: Person;
    title?: string;
    withoutPaper?: boolean;
    showErrors?: boolean;
    onPersonChange?: (person: Person, valid: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3),
        },
        titleRow: {
            paddingBottom: theme.spacing(2),
            textAlign: 'start',
        },
        inputContainer: {
            '& > *': {
                margin: theme.spacing(0, 1),
                minWidth: 100,
            }
        },
        formControl: {
            minWidth: 100,
            //margin: theme.spacing(1),

        },
    })
);

const PersonForm: React.FC<PersonFormProps> = (props) => {
    const classes = useStyles();
    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'

    const inputLabelGender = React.useRef<HTMLLabelElement>(null);
    const inputLabelTitle = React.useRef<HTMLLabelElement>(null);
    const [labelWidthGender, setLabelWidthGender] = React.useState(0);
    const [labelWidthTitle, setLabelWidthTitle] = React.useState(0);

    React.useEffect(() => {
        setLabelWidthGender(inputLabelGender.current!.offsetWidth);
        setLabelWidthTitle(inputLabelTitle.current!.offsetWidth);
    }, []);

    const handleChange = (key: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = (event.target.value as string);
        // console.log(key + ' : ' + value);
        if (props.onPersonChange) {
            props.onPersonChange({
                ...props.person,
                [key]: value,
            }, true);
        }
    };

    const isDisabled = (): boolean => {
        return props.person.gender === 'general';
    }


    const content = (
        <React.Fragment>
            {props.title ? <div className={classes.titleRow}>
                <Typography variant="h6">
                    {props.title}
                </Typography> </div> : null
            }
            <Box className={classes.inputContainer} width="100%" display="flex" flexWrap="wrap">
                <Box >
                    <FormControl variant="outlined" className={classes.formControl}
                        margin={margin}
                    >
                        <InputLabel ref={inputLabelGender} id="demo-simple-select-outlined-label">
                            Anrede
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={props.person.gender}
                            labelWidth={labelWidthGender}
                            onChange={handleChange('gender')}
                        >
                            <MenuItem value="">
                                <em>Keine</em>
                            </MenuItem>
                            <MenuItem value={"female"}>Frau</MenuItem>
                            <MenuItem value={"male"}>Herr</MenuItem>
                            <MenuItem value={"general"}>Damen und Herren</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl variant="outlined" className={classes.formControl}
                        margin={margin}
                    >
                        <InputLabel ref={inputLabelTitle} id="demo-simple-select-outlined-label">
                            Titel
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={props.person.title}
                            onChange={handleChange('title')}
                            labelWidth={labelWidthTitle}
                            disabled={isDisabled()}
                        >
                            <MenuItem value="">
                                <em>Keine</em>
                            </MenuItem>
                            <MenuItem value={"Dr."}>Dr.</MenuItem>
                            <MenuItem value={"Prof."}>Prof.</MenuItem>
                            <MenuItem value={"Prof. Dr."}>Prof. Dr.</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box flexGrow={1}>
                    <TextField
                        fullWidth
                        label="Vorname(n)"
                        value={props.person.firstName}
                        variant={variant} margin={margin}
                        name="firstName"
                        onChange={handleChange('firstName')}
                        disabled={isDisabled()}
                    />
                </Box>
                <Box flexGrow={1}>
                    <TextField
                        fullWidth
                        label="Nachname"
                        value={props.person.lastName}
                        variant={variant} margin={margin}
                        onChange={handleChange('lastName')}
                        name="lastName"
                        disabled={isDisabled()}
                    />
                </Box>
            </Box>
        </React.Fragment>
    );

    if (props.withoutPaper) {
        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }

    return (
        <Paper className={classes.root}>
            {content}
        </Paper>
    );
};

export default PersonForm;
