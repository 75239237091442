import { Invoice } from "../../models/invoice";
import Customer from "../../models/customer";

export type editorMode = "create" | "edit";

// State:
export interface InvoiceEditorState {
    mode: editorMode;
    invoice?: Invoice;
    forCustomer?: Customer;
}

// Action Types:
export const INIT_NEW_EDITOR = 'INIT_NEW_EDITOR';

interface InitNewEditorAction {
    type: typeof INIT_NEW_EDITOR;
    mode: editorMode;
    invoice?: Invoice;
    forCustomer?: Customer;
}


export type InvoiceEditorActionTypes =
    InitNewEditorAction;
