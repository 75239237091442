import React from 'react';
import { InvoiceTotals } from '../../../../../../models/invoice';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

interface TotalsViewProps {
    totals: InvoiceTotals,
    currency: string,
    smallText?: boolean,
}

const TotalsView: React.FC<TotalsViewProps> = (props) => {
    const maxWidth = 400;
    return (
        <Box textAlign="right" justifyContent="flex-end" width="100%" display="flex">
            <Box width={maxWidth} maxWidth={maxWidth} display="block" textAlign="left" pt={1}>
                <Box display="flex" borderBottom="solid grey 1px">
                    <Box width="50%">
                        <Typography variant={props.smallText ? "body1" : "subtitle1"} >
                            Zwischensumme Netto
                        </Typography>
                        {props.totals.taxes ? <React.Fragment>
                            {
                                props.totals.taxes.map((tax, index) =>
                                    <Typography variant={props.smallText ? "body1" : "subtitle1"} key={index}>
                                        zzgl. USt. {tax.name}
                                    </Typography>
                                )
                            }
                        </React.Fragment> : null}
                    </Box>
                    <Box textAlign="right" width="50%">
                        <Typography variant={props.smallText ? "body1" : "subtitle1"} >
                            {props.totals.netto.toLocaleString('de-DE', { style: 'currency', currency: props.currency })}
                        </Typography>
                        {
                            props.totals.taxes.map((tax, index) =>
                                <Typography variant={props.smallText ? "body1" : "subtitle1"} key={index}>
                                    {tax.value.toLocaleString('de-DE', { style: 'currency', currency: props.currency })}
                                </Typography>
                            )
                        }
                    </Box>

                </Box>

                <Box display="flex">
                    <Box width="50%">
                        <Typography variant={props.smallText ? "subtitle1" : "h6"} >
                            Gesamt Brutto
                        </Typography>
                    </Box>
                    <Box textAlign="right" width="50%">
                        <Typography variant={props.smallText ? "subtitle1" : "h6"} >
                            {props.totals.brutto.toLocaleString('de-DE', { style: 'currency', currency: props.currency })}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TotalsView;
