import React, { useState, useEffect } from 'react';
import DeliveryDateEditor from './DeliveryDateEditor/DeliveryDateEditor';
import { DeliveryDate } from '../../../../../models/invoice';
import Box from '@material-ui/core/Box';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import moment from 'moment';

interface InvoiceDatesEditorProps {
    value: { invoiceDate: number; deliveryDate: DeliveryDate };
    onChange: (newValue: { invoiceDate: number; deliveryDate: DeliveryDate }) => void;
    forceShowErrors?: boolean;
    disableInvoiceDate?: boolean;
}


const InvoiceDatesEditor: React.FC<InvoiceDatesEditorProps> = (props) => {
    const [invoiceDate, setInvoiceDate] = useState<moment.Moment | null>(
        props.value.invoiceDate ? moment.unix(props.value.invoiceDate) : null,
    );
    const [invoiceDateTouched, setInvoiceDateTouched] = useState(false);
    const [invoiceDateError, setInvoiceDateError] = useState<string | null>(null);


    const margin = "dense";
    const format = "DD-MM-YYYY";

    /*useEffect(() => {
        setInvoiceDate(
            props.value.invoiceDate ? moment.unix(props.value.invoiceDate) : null,
        );
    }, [props.value.invoiceDate])*/

    useEffect(() => {
        valuesUpdated('invoiceDate', invoiceDate ? invoiceDate.unix() : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceDate])

    const valuesUpdated = (key: string, newValue: number | DeliveryDate) => {
        const newValues = {
            ...props.value,
            [key]: newValue,
        }
        props.onChange(newValues);
    }


    const handleInvoiceDateChange = (date: moment.Moment | null) => {
        const newDate = date;

        setInvoiceDateTouched(true);
        setInvoiceDate(() => {
            if (newDate && !newDate.isValid()) {
                return newDate;
            }
            return newDate;
        });

    };

    return (
        <React.Fragment>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Box width="50%" minWidth={200}>
                    <KeyboardDatePicker
                        fullWidth
                        margin={margin}
                        id="invoice-date-picker-dialog"
                        label="Rechnungsdatum"
                        format={format}
                        value={invoiceDate}
                        onChange={(date) => handleInvoiceDateChange(date)}
                        required
                        inputVariant="outlined"
                        onBlur={() => setInvoiceDateTouched(true)}
                        error={(props.forceShowErrors || invoiceDateTouched) && (!invoiceDate || Boolean(invoiceDateError))}
                        onError={(error) => {
                            setInvoiceDateError(error ? error.toString() : null)
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'start change date',
                        }}
                        disabled={props.disableInvoiceDate}
                    />
                </Box>
            </MuiPickersUtilsProvider>

            <DeliveryDateEditor
                value={props.value.deliveryDate}
                forceShowErrors={props.forceShowErrors}
                dateFormat={format}
                onChange={
                    (newDate) => { valuesUpdated("deliveryDate", newDate); }
                }
            />
        </React.Fragment>
    );
};

export default InvoiceDatesEditor;
