import Customer from "../customer";
import { InvoiceCustomerInfo } from "./invoiceComponents";


export const customerToInvoiceCustomer = (c: Customer): InvoiceCustomerInfo => {
    return {
        customerId: c.id,
        customerNumber: c.references.customerNumber,
        companyName: c.companyName,
        department: c.department,
        contactPerson: { ...c.contactPerson },
        address: { ...c.invoiceAddress },
        VAT: c.VAT,
        showVAT: c.VAT.length > 0,
        internalReference: c.references.internalReference,
        showCustomerNumber: c.references.customerNumber.length > 0,
        showInternalReference: c.references.internalReference.length > 0,
        useEmailForInvoice: c.contactPreferences.useEmailForInvoice,
        emailForInvoice: c.contactPreferences.emailForInvoice,
        defaultTaxRate: c.paymentPreferences.zeroTaxRateByDefault ? 0 : 0.19,
    }
}


export const invoiceCustomerToCustomer = (c: InvoiceCustomerInfo): Customer => {
    return new Customer({
        id: '',
        companyName: c.companyName,
        department: c.department,
        contactPerson: { ...c.contactPerson },
        postAddress: { ...c.address },
        invoiceAddress: { ...c.address },
        VAT: c.VAT,
        references: { customerNumber: c.customerNumber, internalReference: c.internalReference },
        contactPreferences: {
            useEmailForInvoice: c.useEmailForInvoice,
            emailForInvoice: c.emailForInvoice,
            phone: '',
            otherEmails: [],
            websites: []
        }
    });
}
