import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';


interface ManageEmailListProps {
    emails: Array<string>;
    onChange: (emails: Array<string>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputContainer: {
            '& > *': {
                margin: theme.spacing(1),
                minWidth: 100,
            }
        },
    })
);

const ManageEmailList: React.FC<ManageEmailListProps> = (props) => {
    const classes = useStyles();

    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'

    const [newEmailText, setNewEmailText] = useState("");

    return (
        <React.Fragment>
            <List>
                {
                    props.emails.length < 1 ?
                        <ListItem>Keine Emailadressen</ListItem> :
                        < React.Fragment >
                            {props.emails.map((email: string, index: number) =>
                                <ListItem key={email + index.toString()}>
                                    <ListItemText
                                        primary={email}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete"
                                            onClick={() => {
                                                if (!window.confirm('Wollen Sie das Element wirklich löschen?')) {
                                                    return;
                                                }
                                                const newEmails = props.emails.slice();
                                                newEmails.splice(index, 1);
                                                props.onChange(newEmails);
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </React.Fragment>
                }
            </List>
            <Box display="flex" className={classes.inputContainer}>
                <Box flexGrow={1}>
                    <TextField
                        label="Neue emailadresse"
                        variant={variant} margin={margin} fullWidth
                        value={newEmailText}
                        onChange={(event) => {
                            setNewEmailText(event.target.value);
                        }}
                    />
                </Box>
                <Button size="small"
                    onClick={() => {
                        const newEmails = props.emails.slice();
                        newEmails.push(newEmailText);
                        setNewEmailText('');
                        props.onChange(newEmails);
                    }}
                >Hinzufügen</Button>
            </Box>

        </React.Fragment >
    );
};

export default ManageEmailList;
