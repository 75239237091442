import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="left" />;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            backgroundColor: theme.palette.error.dark,
        },
        close: {
            padding: theme.spacing(0.5),
        },
    }),
);
interface ErrorBarProps {
    open: boolean;
    message: string;
    close: () => void;
}

const ErrorBar: React.FC<ErrorBarProps> = (props) => {
    const classes = useStyles();

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        props.close();
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={props.open}
            autoHideDuration={6000}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
        >
            <SnackbarContent
                className={classes.bar}
                aria-describedby="message-id"
                message={<span id="message-id">{props.message}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};
export default ErrorBar;
