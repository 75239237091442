import React from 'react';
import { Invoice } from '../../../../../models/invoice';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { getCompany, getAddress } from '../utils';
import { personToString } from '../../../../../utils/modelsToString';
import moment from 'moment';
import ColumnHeader from '../../../../common/ColumnHeader';

interface DraftInvoicePreviewProps {
    invoice?: Invoice;
    onOpen: () => void;
}

const DraftInvoicePreview: React.FC<DraftInvoicePreviewProps> = (props) => {
    const my = 1;
    return (
        <Box textAlign="left">
            <ColumnHeader borderBottom={true}>
                <Typography variant="h6">Entwurf-Vorschau</Typography>
            </ColumnHeader>
            <Box p={2}>
                {props.invoice ?
                    <div>
                        <Box my={my}>
                            <Typography variant="subtitle2">
                                Rechnungsdatum:
                                    </Typography>
                            <Typography variant="body1">
                                {props.invoice.content.dates.invoiceDate ?
                                    moment.unix(props.invoice.content.dates.invoiceDate).format('DD.MM.YYYY')
                                    : 'nicht definiert'
                                }
                            </Typography>
                        </Box>

                        <Box my={my}>
                            <Typography variant="subtitle2">
                                Kunde:
                         </Typography>
                            <Typography variant="body1">
                                {getCompany(props.invoice)}
                            </Typography>
                            <Typography variant="body1">
                                {personToString(props.invoice.content.customer.contactPerson)}
                            </Typography>
                            <Typography variant="body1">
                                {getAddress(props.invoice.content.customer.address)}
                            </Typography>
                        </Box>

                        <Box my={my}>
                            <Typography variant="subtitle2">
                                Posten:
                        </Typography>
                            <Typography variant="body1">
                                {
                                    props.invoice.content.items ?
                                        props.invoice.content.items.map(
                                            (item, index) => <span key={index}>
                                                {item.title}
                                                {props.invoice && props.invoice.content.items && index < props.invoice.content.items.length - 1 ? ' | ' : ''}
                                            </span>
                                        ) :
                                        "Keine Posten"
                                }
                            </Typography>
                        </Box>

                        <Box my={my}>
                            <Typography variant="subtitle2">
                                Summe:
                        </Typography>
                            <Typography variant="body1">
                                Netto: {props.invoice.content.totals.netto.toLocaleString('de-DE', { style: 'currency', currency: props.invoice.content.payment.currency })}
                                <span> | </span> Brutto: {props.invoice.content.totals.brutto.toLocaleString('de-DE', { style: 'currency', currency: props.invoice.content.payment.currency })}
                            </Typography>
                        </Box>

                        <Box textAlign="center">
                            <Button variant="contained"
                                onClick={() => props.onOpen()}

                            >
                                Öffnen
                    </Button>
                        </Box>
                    </div>
                    : <p>Wählen Sie einen Entwurf aus.</p>}
            </Box>
        </Box>

    );
};

export default DraftInvoicePreview;
