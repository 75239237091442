import {
    CustomerState,
    CustomerActionTypes,
    FETCH_CUSTOMERS_START,
    FETCH_CUSTOMERS_FAILED,
    RECEIVE_CUSTOMERS,
    ADD_CUSTOMER,
    UPDATE_CUSTOMER,
    REMOVE_CUSTOMER,
    SELECT_CUSTOMER,
    INIT_CUSTOMER_EDITOR,
} from './types'
import { updateObject, updateArrayItemById } from '../utils'


const initialState: CustomerState = {
    loading: false,
    customers: [],
    selectedCustomer: undefined,
    editor: {
        mode: 'create',
        customer: undefined,
    }
}

export function customerReducer(
    state = initialState,
    action: CustomerActionTypes
): CustomerState {
    switch (action.type) {
        case FETCH_CUSTOMERS_START:
            return updateObject(state, { loading: true });
        case FETCH_CUSTOMERS_FAILED:
            return updateObject(state, { loading: false });
        case RECEIVE_CUSTOMERS:
            return updateObject(state, { customers: [...action.customers], loading: false, })
        case ADD_CUSTOMER:
            return updateObject(state,
                { loading: false, customers: [...state.customers, action.customer], }
            );
        case UPDATE_CUSTOMER:
            const newSelection = (state.selectedCustomer && state.selectedCustomer.id === action.customer.id)
                ? { ...action.customer } : state.selectedCustomer;
            return updateObject(state, {
                loading: false,
                items: updateArrayItemById(state.customers, action.customer),
                selected: newSelection
            });
        case REMOVE_CUSTOMER:
            return updateObject(state, { loading: false, customers: state.customers.filter(item => item.id !== action.customerID), selectedCustomer: state.selectedCustomer?.id === action.customerID ? undefined : state.selectedCustomer });
        case SELECT_CUSTOMER:
            return updateObject(state, { selectedCustomer: action.customer });
        case INIT_CUSTOMER_EDITOR:
            return updateObject(state, {
                editor: {
                    mode: action.mode,
                    customer: action.customer
                }
            });
        default:
            return state
    }
}
