import {
    OPEN_NAVIGATION_DRAWER,
    CLOSE_NAVIGATION_DRAWER,
    START_LOADING,
    FINISH_LOADING,
    SET_ERROR,
    SystemActionTypes,

} from "./types";

export function openNavigationDrawer(): SystemActionTypes {
    return {
        type: OPEN_NAVIGATION_DRAWER,
    }
}

export function closeNavigationDrawer(): SystemActionTypes {
    return {
        type: CLOSE_NAVIGATION_DRAWER,
    }
}

export function startLoading(): SystemActionTypes {
    return {
        type: START_LOADING,
    }
}

export function finishLoading(): SystemActionTypes {
    return {
        type: FINISH_LOADING,
    }
}

export function setError(message: string): SystemActionTypes {
    return {
        type: SET_ERROR,
        message: message,
    }
}
