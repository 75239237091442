import React, { useState, useEffect } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { DeliveryDate } from '../../../../../../models/invoice';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

interface DeliveryDateEditorProps {
    value: DeliveryDate;
    onChange: (newValue: DeliveryDate) => void;
    forceShowErrors?: boolean;
    dateFormat?: string;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        formControl: {
            minWidth: 100,
            //margin: theme.spacing(1),

        },
    })
);

const DeliveryDateEditor: React.FC<DeliveryDateEditorProps> = (props) => {
    const classes = useStyles();

    const [startDate, setStartDate] = useState<moment.Moment | null>(
        props.value.startDate ? moment.unix(props.value.startDate) : null,
    );
    const [endDate, setEndDate] = useState<moment.Moment | null>(
        props.value.endDate ? moment.unix(props.value.endDate) : null,
    );

    const [startDateTouched, setStartDateTouched] = useState(false);
    const [endDateTouched, setEndDateTouched] = useState(false);

    const [startDateError, setStartDateError] = useState<string | null>(null);
    const [endDateError, setEndDateError] = useState<string | null>(null);

    const margin = "dense";

    /*useEffect(() => {
        setStartDate(
            props.value.startDate ? moment.unix(props.value.startDate) : null,
        );
        setEndDate(
            props.value.endDate ? moment.unix(props.value.endDate) : null,
        )
    }, [props])*/

    useEffect(() => {
        props.onChange({
            ...props.value,
            startDate: startDate ? startDate.unix() : 0
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate]);

    useEffect(() => {
        props.onChange({
            ...props.value,
            endDate: endDate ? endDate.unix() : 0
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate]);

    const handleStartDateChange = (date: moment.Moment | null) => {
        setStartDateTouched(true);
        const newDate = date;

        setStartDate(() => {
            if (newDate && !newDate.isValid()) {
                return newDate;
            }
            return newDate;
        });

    };

    const handleEndDateChange = (date: moment.Moment | null) => {
        const newDate = date;

        setEndDateTouched(true);
        setEndDate(() => {
            if (newDate && !newDate.isValid()) {
                return newDate;
            }
            return newDate;
        });

    };

    const handleChange = (key: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = (event.target.value as string);
        props.onChange({
            ...props.value,
            [key]: value,
        })
    };

    let inputLabel = 'Datum';
    let inputs = (<p></p>);
    if (props.value.type === "type4" || props.value.type === "type6") {
        inputLabel = "Anfang";
        // show 2 inputs
        inputs = (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Box display="flex">
                    <Box flexGrow={1} ml={1}>
                        <KeyboardDatePicker
                            fullWidth
                            margin={margin}
                            id="start-date-picker-dialog"
                            label={inputLabel}
                            inputVariant="outlined"
                            format={props.dateFormat}
                            value={startDate}
                            onChange={handleStartDateChange}
                            required
                            maxDate={endDate ? endDate : moment('2220-12-31')}
                            onBlur={() => setStartDateTouched(true)}
                            error={(props.forceShowErrors || startDateTouched) && (!startDate || Boolean(startDateError))}
                            onError={(error) => {
                                setStartDateError(error ? error.toString() : null)
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'start change date',
                            }}
                        />
                    </Box>
                    <Box flexGrow={1} ml={1}>
                        <KeyboardDatePicker
                            fullWidth
                            margin={margin}
                            id="end-date-picker-dialog"
                            label="Ende"
                            inputVariant="outlined"
                            format={props.dateFormat}
                            value={endDate}
                            onChange={handleEndDateChange}
                            required
                            minDate={startDate ? startDate : moment(0)}
                            onBlur={() => setEndDateTouched(true)}
                            error={(props.forceShowErrors || endDateTouched) && (!endDate || Boolean(endDateError))}
                            onError={(error) => {
                                setEndDateError(error ? error.toString() : null)
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'end change date',
                            }}
                        />
                    </Box>
                </Box>
            </MuiPickersUtilsProvider>

        )

    } else if (props.value.type === "type3" || props.value.type === "type5") {
        inputs = (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Box flexGrow={1} ml={1}>
                    <KeyboardDatePicker
                        fullWidth
                        margin={margin}
                        id="start-date-picker-dialog"
                        label={inputLabel}
                        format={props.dateFormat}
                        value={startDate}
                        onChange={handleStartDateChange}
                        required
                        inputVariant="outlined"
                        maxDate={endDate ? endDate : moment('2220-12-31')}
                        onBlur={() => setStartDateTouched(true)}
                        error={(props.forceShowErrors || startDateTouched) && (!startDate || Boolean(startDateError))}
                        onError={(error) => {
                            setStartDateError(error ? error.toString() : null)
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'start change date',
                        }}
                    />
                </Box>
            </MuiPickersUtilsProvider>
        )
    }

    return (
        <Box display="flex" flexWrap="wrap">
            <Box >
                <FormControl variant="outlined" className={classes.formControl}
                    margin={margin}
                >
                    <Select
                        labelId="select-type-label"
                        id="select-type"
                        value={props.value.type}
                        onChange={handleChange('type')}
                    >
                        <MenuItem value={"type1"}>Leistungsdatum entspricht Rechnungsdatum</MenuItem>
                        <MenuItem value={"type2"}>Lieferdatum entspricht Rechnungsdatum</MenuItem>
                        <MenuItem value={"type3"}>Leistungsdatum</MenuItem>
                        <MenuItem value={"type4"}>Leistungszeitraum</MenuItem>
                        <MenuItem value={"type5"}>Lieferdatum</MenuItem>
                        <MenuItem value={"type6"}>Lieferzeitraum</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box flexGrow="1">
                {inputs}
            </Box>
        </Box>
    );
};

export default DeliveryDateEditor;
