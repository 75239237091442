import React, { useState } from 'react';
import { Invoice, InvoiceTag } from '../../../../../models/invoice';
import { InvoiceQuery } from '../../../../../store/invoices/finals/types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import SearchInput from '../../../../common/SearchInput/SearchInput';
import InvoiceQueryEditor from './InvoiceQueryEditor/InvoiceQueryEditor';
import InvoiceTagSymbol from '../InvoiceTagSymbol/InvoiceTagSymbol';

import { getInvoiceElementForTable } from '../utils';

import Fuse from 'fuse.js';
import Tooltip from '@material-ui/core/Tooltip';
import { InvoiceConfigsState } from '../../../../../store/invoices/configs/types';
import { useSelector } from 'react-redux';
import { downloadPDF } from '../../../../../utils/invoiceToPdf';
import FlexGrow from '../../../../common/FlexGrow/FlexGrow';

interface FinalInvoicesTableProps {
    invoices: Invoice[];
    selectedID?: string;
    invoiceTags: InvoiceTag[];
    onSelectInvoice: (invoice: Invoice | undefined) => void;
    onOpenInvoice: (invoice: Invoice) => void;
    query: InvoiceQuery;
    onInvoiceQueryChanged: (query: InvoiceQuery) => void;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 500,
        },
        notSelectedRow: {
            '&:hover, &:focus': {
                backgroundColor: '#f5f5f5',
            }
        },
        selectedRow: {
            backgroundColor: '#ededed',
        }
    })
);

const rowsPerPageOptions = [10, 25, 50];

const FinalInvoicesTable: React.FC<FinalInvoicesTableProps> = (props) => {
    const classes = useStyles();

    const documentTemplates = useSelector((state: { invoices: { configs: InvoiceConfigsState } }) => state.invoices.configs.templates);
    const [checkedInvoices, setCheckedInvoices] = React.useState<Invoice[]>([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);

    const [searchTerm, setSearchTerm] = useState('');
    const options: Fuse.FuseOptions<Invoice> = {
        keys: [
            'invoiceNumber',
            'content.customer.companyName',
            'content.customer.department',
            'content.customer.VAT',
            'content.customer.internalReference',
            'content.customer.customerNumber',
            'content.customer.contactPerson.firstName',
            'content.customer.contactPerson.lastName',
            'content.customer.address.street',
            'content.customer.address.streetExtra',
            'content.customer.address.city',
            'content.customer.address.postalCode',
            'content.customer.address.country',
            'content.items.title',
            'tags',
            'content.customInfos.value'
        ],
        threshold: 0.2,
    };
    const fuse = new Fuse(props.invoices, options);

    const filteredInvoices = (searchTerm.length > 0 ? fuse.search(searchTerm) as Invoice[] :
        props.invoices).sort((a, b) => b.content.dates.invoiceDate - a.content.dates.invoiceDate);

    if (filteredInvoices.length > 0 && filteredInvoices.length <= (rowsPerPage * (page))) {
        setPage(0);
    }

    const displayedInvoices = filteredInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const getTagColor = (tagName: string): string => {
        const tagProt = props.invoiceTags.find(tag => tag.tag === tagName);
        if (!tagProt || !tagProt.color) {
            return 'grey';
        }
        return tagProt.color;
    }

    const handleCheckAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setCheckedInvoices(displayedInvoices);
            return;
        }
        resetCheckedInvoices();
    };

    const handleClick = (inv: Invoice) => {
        const checkedIndex = checkedInvoices.indexOf(inv);
        let newCheckedInvoices: Invoice[] = [];

        if (checkedIndex === -1) {
            newCheckedInvoices = newCheckedInvoices.concat(checkedInvoices, inv);
        } else if (checkedIndex === 0) {
            newCheckedInvoices = newCheckedInvoices.concat(checkedInvoices.slice(1));
        } else if (checkedIndex === checkedInvoices.length - 1) {
            newCheckedInvoices = newCheckedInvoices.concat(checkedInvoices.slice(0, -1));
        } else if (checkedIndex > 0) {
            newCheckedInvoices = newCheckedInvoices.concat(
                checkedInvoices.slice(0, checkedIndex),
                checkedInvoices.slice(checkedIndex + 1),
            );
        }

        setCheckedInvoices(newCheckedInvoices);
    };

    const resetCheckedInvoices = () => setCheckedInvoices([]);

    const isChecked = (inv: Invoice) => checkedInvoices.indexOf(inv) !== -1;

    const handleChangePage = (event: unknown, newPage: number) => {
        resetCheckedInvoices();
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        resetCheckedInvoices();
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleInvoiceQueryChanged = (query: InvoiceQuery) => {
        resetCheckedInvoices();
        props.onInvoiceQueryChanged(query);
    }

    const handleSearchTermChanged = (term: string) => {
        resetCheckedInvoices();
        setSearchTerm(term);
    }

    const downloadCheckedInvoices = () => {
        let index = 0;

        let interval = setInterval(() => {
            if (index < checkedInvoices.length) {
                let invoice = checkedInvoices[index];
                let templateDef = documentTemplates.find(t => t.id === invoice.templateReference.id);
                if (!templateDef) {
                    templateDef = documentTemplates[0];
                }
                downloadPDF(invoice, templateDef.template);
                index++;
            }
            else {
                clearInterval(interval);
            }
        }, 200);
    }

    const tableColumns = [
        { valueRef: 'invoiceDate', label: 'Datum', align: 'left' },
        { valueRef: 'customInfos.PROJECTNR', label: 'Proj.nr.', align: 'left', style: { width: "50px" } },
        { valueRef: 'invoiceNumber', label: 'Rechn.nr', align: 'left' },
        { valueRef: 'customInfos.PROJECT', label: 'Projekt', align: 'left' },
        { valueRef: 'customer', label: 'Kunde', align: 'left' },
        // { valueRef: 'nrInvoiceItems', label: 'Posten', align: 'right' },
        { valueRef: 'totals.netto', label: 'Netto', align: 'right' },
        // { valueRef: 'totals.brutto', label: 'Brutto', align: 'right' },
        { valueRef: 'tags', label: 'Tags', align: 'left', style: { minWidth: "120px" } },
    ]

    return (
        <Box>
            <Box display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
            >
                <Tooltip title="Ausgewählte Rechnungen als PDF herunterladen">
                    <span>
                        <Button
                            style={{ minHeight: 40, marginTop: 4 }}
                            variant="outlined"
                            startIcon={<CloudDownloadIcon />}
                            disabled={checkedInvoices.length === 0}
                            onClick={downloadCheckedInvoices}
                        >
                            PDF
                    </Button>
                    </span>
                </Tooltip>
                <FlexGrow />
                <InvoiceQueryEditor
                    query={props.query}
                    onChange={handleInvoiceQueryChanged}
                />
                <SearchInput
                    onSearch={handleSearchTermChanged}
                    triggerOnChange={true}
                />
            </Box>

            <TableContainer component={"div"}>
                <Table className={classes.table} aria-label="draft invoices" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox style={{ margin: -10 }}
                                    indeterminate={checkedInvoices.length > 0 && checkedInvoices.length < displayedInvoices.length}
                                    checked={checkedInvoices.length > 0 && checkedInvoices.length === displayedInvoices.length}
                                    onChange={handleCheckAllClick}
                                />
                            </TableCell>
                            {tableColumns.map((col, index) =>
                                <TableCell key={index.toString()}
                                    style={col.style}
                                >{col.label} </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredInvoices.length < 1 ?
                            <TableRow>
                                <TableCell colSpan={tableColumns.length} align="center">Keine Rechnungen gefunden</TableCell>
                            </TableRow> :
                            null
                        }
                        {displayedInvoices.map((row: Invoice, index: number) => {
                            const isItemChecked = isChecked(row);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return <TableRow key={row.id}
                                className={row.id === props.selectedID ? classes.selectedRow : classes.notSelectedRow}
                                style={{ cursor: "pointer" }}
                            >
                                <TableCell padding="checkbox" onClick={() => { handleClick(row); }}>
                                    <Checkbox
                                        checked={isItemChecked}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </TableCell>
                                {tableColumns.map((col, index) => {
                                    return <TableCell key={index.toString()}
                                        onClick={() => {
                                            props.onSelectInvoice(row)
                                        }}
                                        onDoubleClick={() => {
                                            props.onOpenInvoice(row)
                                        }}
                                        style={col.style}
                                    >
                                        {col.valueRef === 'tags' ?
                                            <React.Fragment>
                                                {
                                                    row.tags.sort((a, b) => a.localeCompare(b)).map(data => {
                                                        return (
                                                            <InvoiceTagSymbol tag={data} color={getTagColor(data)} key={data} />
                                                        );
                                                    })
                                                }
                                            </React.Fragment>
                                            : getInvoiceElementForTable(row, col.valueRef)}

                                    </TableCell>
                                }
                                )}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={filteredInvoices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>

    );
};

export default FinalInvoicesTable;
