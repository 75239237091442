import { Typography, Box } from '@material-ui/core';
import React from 'react';
import ColumnHeader from '../../components/common/ColumnHeader';
import PasswordChange from './SettingsComponents/PasswordChange';

interface SettingsProps {
}


const Settings: React.FC<SettingsProps> = (props) => {

    return (
        <Box height="100vh" bgcolor="white">
            <ColumnHeader borderBottom={true}>
                <Typography variant="h4">
                    {'Einstellungen'}
                </Typography>
            </ColumnHeader>
            <Box p={2} textAlign="left">
                <PasswordChange />
            </Box>
        </Box>
    );
};

export default Settings;
