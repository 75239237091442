import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/SearchOutlined';

interface SearchInputProps {
    onSearch: (search: string) => void;
    triggerOnChange?: boolean;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <FormControl variant="outlined" margin="dense">
            <InputLabel htmlFor="search-field">Suche</InputLabel>
            <OutlinedInput
                id="search-field"
                value={searchTerm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    setSearchTerm(value);
                    if (props.triggerOnChange) {
                        props.onSearch(value);
                    }
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="trigger search"
                            onClick={() => props.onSearch(searchTerm)}
                            edge="end"
                        >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            />
        </FormControl>
    );
};
export default SearchInput;
