import Address from "../../../../models/address";
import { Invoice } from "../../../../models/invoice/invoice";

export const getCompany = (invoice: Invoice): string => {
    return invoice.content.customer.companyName + (invoice.content.customer.department ? ' - ' + invoice.content.customer.department : '');
}

export const getAddress = (address: Address): string => {
    let value = address.street;
    if (address.streetExtra) {
        value += ', ' + address.streetExtra;
    }
    value += ' | ' + address.postalCode + ' ' + address.city;
    return value;
}
