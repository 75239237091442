import InvoiceItem from "../../../models/invoice/invoiceItem";

export interface InvoiceItemTemplatesState {
    loading: boolean;
    templates: InvoiceItem[];
}

// Action Types:
export const FETCH_INVOICE_ITEM_TEMPLATES_START = "FETCH_INVOICE_ITEM_TEMPLATES_START";
export const FETCH_INVOICE_ITEM_TEMPLATES_FAILED = "FETCH_INVOICE_ITEM_TEMPLATES_FAILED";
export const RECEIVE_INVOICE_ITEM_TEMPLATES = "RECEIVE_INVOICE_ITEM_TEMPLATES";
export const ADD_INVOICE_ITEM_TEMPLATE = "ADD_INVOICE_ITEM_TEMPLATE";
export const REMOVE_INVOICE_ITEM_TEMPLATE = "REMOVE_INVOICE_ITEM_TEMPLATE";


interface FetchInvoiceItemTemplatesStartAction {
    type: typeof FETCH_INVOICE_ITEM_TEMPLATES_START;
}

interface FetchInvoiceItemTemplatesFailedAction {
    type: typeof FETCH_INVOICE_ITEM_TEMPLATES_FAILED;
}

interface ReceiveInvoiceItemTemplatesAction {
    type: typeof RECEIVE_INVOICE_ITEM_TEMPLATES;
    templates: InvoiceItem[];
}

interface AddInvoiceItemTemplateAction {
    type: typeof ADD_INVOICE_ITEM_TEMPLATE;
    template: InvoiceItem;
}

interface RemoveInvoiceItemTemplateAction {
    type: typeof REMOVE_INVOICE_ITEM_TEMPLATE;
    template: InvoiceItem;
}



export type InvoiceItemTemplateActionTypes =
    FetchInvoiceItemTemplatesStartAction |
    FetchInvoiceItemTemplatesFailedAction |
    ReceiveInvoiceItemTemplatesAction |
    AddInvoiceItemTemplateAction |
    RemoveInvoiceItemTemplateAction;
