import React, { useState } from 'react';
import { InvoiceQuery, InvoiceQueryType } from '../../../../../../store/invoices/finals/types';
import { Box, RadioGroup, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem, Button, Popover, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

interface InvoiceQueryEditorProps {
    query: InvoiceQuery,
    onChange: (query: InvoiceQuery) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

const InvoiceQueryEditor: React.FC<InvoiceQueryEditorProps> = (props) => {
    const classes = useStyles();

    const [startDate, setStartDate] = useState<moment.Moment | null>(
        props.query.start ? moment.unix(props.query.start) : null,
    );
    const [endDate, setEndDate] = useState<moment.Moment | null>(
        props.query.end ? moment.unix(props.query.end) : null,
    );

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
        const value = event.target.value as string;
        props.onChange(
            {
                ...props.query,
                [key]: key === 'type' ? (value as InvoiceQueryType) : parseInt(value),
            }
        );
        if (key === 'lastDays') {
            handleClose();
        }
    };

    const handleDateChange = (key: string) => (date: moment.Moment | null) => {
        const newDate = date;

        if (key === 'start') {
            setStartDate(() => {
                if (newDate && !newDate.isValid()) {
                    return newDate;
                }
                props.onChange(
                    {
                        ...props.query,
                        start: newDate ? newDate.unix() : 0,
                    }
                )
                return newDate;
            });
        } else if (key === 'end') {
            setEndDate(() => {
                if (newDate && !newDate.isValid()) {
                    return newDate;
                }
                props.onChange(
                    {
                        ...props.query,
                        end: newDate ? newDate.unix() : 0,
                    }
                )
                return newDate;
            });
        }
    };



    const lastDayOptions = [30, 60, 180, 365];

    const margin = "dense";
    const format = "DD.MM.YYYY";

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const lastLabel = <Box>
        <FormControl className={classes.formControl}>
            <InputLabel id="last-days-select-label">Letzte Tage</InputLabel>
            <Select
                labelId="last-days-select-label"
                id="last-days-select"
                value={props.query.lastDays}
                onChange={handleChange("lastDays")}
            >
                {lastDayOptions.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
            </Select>
        </FormControl>
    </Box>;

    const rangeLabel = < Box display="flex" p={1} >
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Box width="50%" mr={1} minWidth={200}>
                <KeyboardDatePicker
                    fullWidth
                    margin={margin}
                    id="start-date-picker-dialog"
                    label="Von"
                    format={format}
                    value={startDate}
                    onChange={handleDateChange("start")}
                    required
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                        'aria-label': 'start change date',
                    }}
                />
            </Box>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Box width="50%" minWidth={200}>
                <KeyboardDatePicker
                    fullWidth
                    margin={margin}
                    id="end-date-picker-dialog"
                    label="Bis"
                    format={format}
                    value={endDate}
                    onChange={handleDateChange("end")}
                    required
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                        'aria-label': 'end change date',
                    }}
                />
            </Box>
        </MuiPickersUtilsProvider>
    </Box>;

    return (
        <div>
            <Button
                variant="outlined"
                onClick={handleClick}
                style={{ minHeight: 40, marginTop: 4, marginRight: 16 }}
            >
                {props.query.type === "last"
                    ? "Letzte " + props.query.lastDays + " Tage"
                    : startDate?.format(format) + " - " + endDate?.format(format)}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Box textAlign="left" display="flex" p={1} pt={1} pl={3}>
                    <FormControl component="fieldset">
                        <Typography variant="h6">Zeitraum</Typography>
                        <RadioGroup aria-label="query-type" name="qtype" value={props.query.type}
                            onChange={handleChange("type")}>
                            <FormControlLabel value="last" control={<Radio />} label={lastLabel} style={{ minWidth: 200, marginRight: 32 }} />
                            <FormControlLabel value="range" control={<Radio />} label={rangeLabel} />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Popover>
        </div>
    );
};

export default InvoiceQueryEditor;
