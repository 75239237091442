import {
    editorMode,
    INIT_NEW_EDITOR,
    InvoiceEditorActionTypes
} from "./types";
import { Invoice } from "../../models/invoice";
import Customer from "../../models/customer";

export function initNewEditor(
    mode: editorMode,
    invoice?: Invoice,
    forCustomer?: Customer,
): InvoiceEditorActionTypes {
    return {
        type: INIT_NEW_EDITOR,
        mode: mode,
        invoice: invoice,
        forCustomer: forCustomer,
    }
}
