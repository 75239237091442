import InvoiceItem, { getNettoPriceOfItem, getBruttoPriceOfItem } from "./invoiceItem";

type SurchargeType = 'brutto' | 'netto';

export default interface Surcharge {
    id: string;
    title: string;
    description: string;
    itemReferences: Array<string>;
    type: SurchargeType;
    amount: number;
    taxRate: number;
}

export const getReferencedInvoiceItemIndicesOfSurchargeItem = (surchargeItem: Surcharge, invoiceItems: InvoiceItem[]) => {
    let indices: number[] = [];

    surchargeItem.itemReferences.forEach(item => {
        let index = invoiceItems.findIndex((invoiceItem) => {
            return item === invoiceItem.id;
        });

        if (index > -1) indices.push(index + 1);
    });

    return indices.sort();
}

export const getReferencedInvoiceItemsOfDiscountItem = (discountItem: Surcharge, invoiceItems: InvoiceItem[]) => {
    return invoiceItems.filter(invoiceItem => discountItem.itemReferences.includes(invoiceItem.id));
}

export const getReferenceSumOfDiscountItem = (discountItem: Surcharge, invoiceItems: InvoiceItem[]): number => {
    let referenceSum = 0;
    let referencedItems = getReferencedInvoiceItemsOfDiscountItem(discountItem, invoiceItems);

    switch (discountItem.type) {
        case "netto":
            referencedItems.forEach(item => {
                referenceSum += getNettoPriceOfItem(item);
            });
            break;
        case "brutto":
            referencedItems.forEach(item => {
                referenceSum += getBruttoPriceOfItem(item);
            });
            break;
    }

    return referenceSum;
}

export const getNettoPriceOfSurchargeItem = (discountItem: Surcharge, invoiceItems: InvoiceItem[]): number => {
    return getReferenceSumOfDiscountItem(discountItem, invoiceItems) * (discountItem.amount / 100);
}

export const getBruttoPriceOfSurchageItem = (discountItem: Surcharge, invoiceItems: InvoiceItem[]): number => {
    return getNettoPriceOfSurchargeItem(discountItem, invoiceItems) * (1 + discountItem.taxRate);
}
