import moment from 'moment';
import { personToString } from '../../../../utils/modelsToString';
import { Invoice } from '../../../../models/invoice';

export const getInvoiceElementForTable = (invoice: Invoice, selector: string): string => {
    const sItems = selector.split('.');
    let itemValue = '';
    if (sItems.length > 1) {
        // nested reference
        switch (sItems[0]) {
            case 'totals':
                itemValue = getTotalValue(invoice, sItems[1]);
                break;
            case 'customInfos':
                itemValue = getCustomInfo(invoice, sItems[1]);
                break;
        }
    } else {
        // simple reference
        switch (selector) {
            case 'invoiceDate':
                itemValue = getInvoiceDate(invoice);
                break;
            case 'invoiceNumber':
                itemValue = invoice.invoiceNumber;
                break;
            case 'customer':
                itemValue = getCustomer(invoice).short;
                break;
            case 'nrInvoiceItems':
                itemValue = getNumberOfItems(invoice);
                break;
            case 'updatedAt':
                itemValue = getModifiedDate(invoice);
                break;
            case 'tags':
                itemValue = getTags(invoice);
                break;
        }
    }
    return itemValue;
}

export const getModifiedDate = (invoice: Invoice): string => {
    return invoice.objectInfos.updatedAt && invoice.objectInfos.updatedAt > 0 ?
        moment.unix(invoice.objectInfos.updatedAt).format('DD.MM.YYYY HH:mm') :
        moment.unix(invoice.objectInfos.createdAt).format('DD.MM.YYYY HH:mm');
}

export const getInvoiceDate = (invoice: Invoice): string => {
    return invoice.content.dates.invoiceDate ?
        moment.unix(invoice.content.dates.invoiceDate).format('DD.MM.YYYY') : '-';
}

export const getNetto = (invoice: Invoice): string => {
    return invoice.content.totals.netto ?
        invoice.content.totals.netto.toLocaleString('de-DE', { style: 'currency', currency: invoice.content.payment.currency }) : '-';
}

const getCustomInfo = (invoice: Invoice, selector: string): string => {
    const item = invoice.content.customInfos.find(item => item.key === selector);
    return item ? item.value : '-';
}

const getTotalValue = (invoice: Invoice, selector: string): string => {
    switch (selector) {
        case 'netto':
            return getNetto(invoice);
        case 'brutto':
            return getBrutto(invoice);
    }
    return '-';
}

export const getBrutto = (invoice: Invoice): string => {
    return invoice.content.totals.brutto ?
        invoice.content.totals.brutto.toLocaleString('de-DE', { style: 'currency', currency: invoice.content.payment.currency }) : '-';
}

export const getNumberOfItems = (invoice: Invoice): string => {
    let number = 0;
    if (invoice.content.items) number += invoice.content.items.length;
    if (invoice.content.surcharges) number += invoice.content.surcharges.length;
    return number > 0 ?
        number.toString() : '-';
}

export const getTags = (invoice: Invoice): string => {
    return invoice.tags && invoice.tags.length > 0 ?
        invoice.tags.join(', ') : 'keine';
}

export const getCustomer = (invoice: Invoice): {
    short: string;
    full: string;
} => {
    const value = {
        short: '',
        full: ''
    }

    const customer = invoice.content.customer;
    const person = personToString(customer.contactPerson, [
        { gender: 'male', prefix: 'Herr' },
        { gender: 'female', prefix: 'Frau' },
    ]);
    value.full += [customer.companyName, customer.department, person, customer.address.city + ', ' + customer.address.country].join(' - ');
    const shortLength = 25;
    value.short = value.full.length > shortLength ? value.full.slice(0, shortLength) + '...' : value.full;
    return value;
}

export const unitDisplay = (unit: string): string => {
    switch (unit) {
        case 'percentage':
            return 'Prozent';
        case 'piece':
            return 'Stück';
        case 'all-inclusive':
            return 'pauschal';
        case 'time':
            return 'Zeit'
    }
    return 'Stück';
}

export const timeDisplay = (quantity: number): string => {
    const hours = Math.floor(quantity);
    let timeStr = '';
    if (hours > 0) {
        timeStr += hours.toFixed(0) + 'h ';
    }
    timeStr += Math.round((60 * quantity) % 60).toFixed(0) + 'm';
    return timeStr;
}
