import { ppmviLogo } from "./ppmvi-logo";


export interface DocumentTemplate {
    id: string;
    name: string;
    template?: any;
}

const templatePPMVI = {
    id: 'default_de_id', name: 'DE', template: {
        localisation: {
            code: 'de-DE', // check javascript locales
            dateFormat: 'DD.MM.YYYY' // check moment js
        },
        page: {
            pageMargins: [40, 250, 40, 90],
            pageSize: 'A4',
            styles: {
                header: {
                    fontSize: 11,
                    //bold: true
                },
                headerRight: {
                    fontSize: 11,
                    alignment: 'right'
                },
                body: {
                    fontSize: 10,
                },
            },
        },
        header: {
            content: [
                {
                    type: 'address-v1',
                    template: {
                        stack: [

                        ],

                    }
                },
            ]
        },
        body: {
            content: [
                {
                    type: 'stack',
                    items: [
                        {
                            type: 'table',
                            table: {
                                widths: [230],
                                body: [
                                    {
                                        type: 'singleTableRow', items: [{
                                            type: 'staticText', item: {
                                                text: 'ppm visuals & internet GmbH | Herzogstraße 3 | 92637 Weiden'
                                            }
                                        }]
                                    },
                                ]
                            },
                            style: {
                                fontSize: 6,
                                layout: {
                                    defaultBorder: false,
                                },
                            }
                        },
                        {
                            type: 'table',
                            table: {
                                widths: [230],
                                body: [
                                    {
                                        type: 'singleTableRow',
                                        items: [
                                            {
                                                type: 'invoiceData',
                                                prefix: '',
                                                suffix: '',
                                                separator: '',
                                                items: [
                                                    {
                                                        ref: 'content.customer',
                                                        showRef: undefined,
                                                        dtype: 'address',
                                                        mappings: [
                                                            { key: 'male', value: 'Herrn', valuePl: undefined },
                                                            { key: 'female', value: 'Frau', valuePl: undefined },
                                                        ]
                                                    }
                                                ],
                                                style: undefined,
                                            },
                                        ]
                                    },
                                    {
                                        type: 'singleTableRow',
                                        items: [
                                            {
                                                type: 'invoiceData',
                                                prefix: 'USt.-ID.: ',
                                                suffix: '',
                                                separator: '',
                                                showRef: 'content.customer.showVAT',
                                                items: [
                                                    {
                                                        ref: 'content.customer.VAT',
                                                        dtype: 'string',
                                                    }
                                                ],
                                                style: undefined
                                            },
                                        ]
                                    }
                                ]
                            },
                            style: {
                                layout: {
                                    defaultBorder: false,
                                },
                                margin: [0, 4, 0, 0],
                                style: 'header'
                            }
                        }
                    ],
                    style: {
                        absolutePosition: { x: 40, y: 135 },
                    }
                },
                // <-- end address part
                // ---> start header
                {
                    type: 'columns',
                    style: {
                        margin: [0, 30, 0, 0]
                    },
                    columns: [
                        {
                            type: 'stack',
                            style: {
                                width: 'auto',
                            },
                            items: [
                                {
                                    type: 'invoiceData',
                                    prefix: '',
                                    suffix: '',
                                    separator: '',
                                    showRef: 'content.standardInfos.TITLE.show',
                                    items: [
                                        {
                                            ref: 'content.standardInfos.TITLE.value',
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                        bold: true,
                                        // margin: [0, 30, 0, 0]
                                    }
                                },
                                // Invoice number -->
                                {
                                    type: 'invoiceData',
                                    prefix: 'Rechnungsnr.: ',
                                    suffix: '',
                                    separator: '-',
                                    items: [
                                        {
                                            ref: 'content.customInfos.PROJECTNR.value',
                                            showRef: 'content.customInfos.PROJECTNR.show',
                                            dtype: 'string',
                                        },
                                        {
                                            ref: 'invoiceNumber', // use invoice number suffix
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                    }
                                },
                                // Project infos -->
                                {
                                    type: 'invoiceData',
                                    prefix: 'Projekt: ',
                                    suffix: '',
                                    separator: '',
                                    showRef: 'content.customInfos.PROJECT.show',
                                    items: [
                                        {
                                            ref: 'content.customInfos.PROJECT.value',
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                    }
                                },
                                // VENDOR NUMBER
                                {
                                    type: 'invoiceData',
                                    prefix: '',
                                    suffix: '',
                                    separator: '',
                                    showRef: 'content.customInfos.VENDOR.show',
                                    items: [
                                        {
                                            ref: 'content.customInfos.VENDOR.value',
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                    }
                                },
                                {
                                    type: 'invoiceData',
                                    prefix: '',
                                    suffix: '',
                                    separator: '',
                                    showRef: 'content.customer.showCustomerNumber',
                                    items: [
                                        {
                                            ref: 'content.customer.customerNumber',
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                    }
                                },
                                {
                                    type: 'invoiceData',
                                    prefix: '',
                                    suffix: '',
                                    separator: '',
                                    showRef: 'content.customInfos.EXTRA_REFERENCE.show',
                                    items: [
                                        {
                                            ref: 'content.customInfos.EXTRA_REFERENCE.value',
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                    }
                                },
                            ]
                        },
                        // second column -->
                        {
                            type: 'stack', items: [
                                {
                                    type: 'invoiceData',
                                    prefix: 'Rechnungsdatum: ',
                                    suffix: '',
                                    separator: '',
                                    items: [
                                        {
                                            ref: 'content.dates.invoiceDate',
                                            dtype: 'date',
                                        }
                                    ],
                                    style: {
                                        style: 'headerRight',
                                        // margin: [0, 15, 0, 0]
                                    }
                                },
                            ]
                        }
                    ],
                },
                // <--- end header

                // ---> start greeting
                {
                    type: 'invoiceData',
                    prefix: 'Sehr geehrte',
                    suffix: ',',
                    separator: '',
                    items: [
                        {
                            ref: 'content.customer.contactPerson',
                            dtype: 'name',
                            mappings: [
                                { key: 'male', value: 'r Herr' },
                                { key: 'female', value: ' Frau' },
                                { key: 'general', value: ' Damen und Herren' },
                            ]
                        }
                    ],
                    style: {
                        style: 'body',
                        margin: [0, 40, 0, 0],
                    }
                },
                // ---> end greeting

                {
                    type: 'invoiceData',
                    prefix: '\n',
                    suffix: '',
                    separator: '',
                    showRef: 'content.standardInfos.INTRO.show',
                    items: [
                        {
                            ref: 'content.standardInfos.INTRO.value',
                            dtype: 'string',
                        }
                    ],
                    style: {
                        style: 'body',
                    }
                },

                // ---------------------------------- Start Invoice items ---------------------
                {
                    type: 'table',
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: ['auto', 'auto', '*', '*', '*', 'auto', '*'],
                        dontBreakRows: true,
                        body: [
                            {
                                type: 'singleTableRow',
                                items: [
                                    { type: 'staticText', item: { text: 'POS' } },
                                    { type: 'staticText', item: { text: [{ text: 'Bezeichnung' }, { text: '', italics: true, fontSize: 10 }] } },
                                    { type: 'staticText', item: { text: 'Menge', alignment: 'right' } },
                                    { type: 'staticText', item: { text: 'Einzelpreis', alignment: 'right' } },
                                    { type: 'staticText', item: { text: 'Netto', alignment: 'right' } },
                                    { type: 'staticText', item: { text: 'USt.' } },
                                    { type: 'staticText', item: { text: 'Brutto', alignment: 'right' } }
                                ]
                            }, // Header row
                            {
                                type: 'arrayToTableRows',
                                arrayRef: 'content.items',
                                cells: [
                                    {
                                        items: [
                                            { name: 'index', style: { alignment: 'center' } }
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'title', style: undefined },
                                            { name: 'description', style: { italics: true, fontSize: 8 } }
                                        ]
                                    },
                                    {
                                        items: [
                                            {
                                                name: 'itemQuantity', style: { alignment: 'right', noWrap: true }, mappings: [
                                                    { key: 'percentage', value: '%' },
                                                    { key: 'all-inclusive', value: 'pauschal' },
                                                    { key: 'time', value: 'h' },
                                                    { key: 'piece', value: 'Stück' },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'unitPrice', dtype: 'currency', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'nettoItemPrice', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'taxRate', dtype: 'percent', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'bruttoItemPrice', style: { alignment: 'right' } },
                                        ]
                                    }
                                ]
                            }
                            // todo: add discounts here
                            ,
                            {
                                type: 'arrayToTableRows',
                                arrayRef: 'content.surcharges',
                                cells: [
                                    {
                                        items: [
                                            { name: 'surchargeIndex', style: { alignment: 'center' } }
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'title', style: undefined },
                                            { name: 'description', style: { italics: true, fontSize: 8 } },
                                            { name: 'surchargeItemRefs', style: { italics: true, fontSize: 8 } }
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'surchargeAmount', dtype: 'percentage', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'surchargeBasePrice', dtype: 'currency', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'nettoSurchargePrice', dtype: 'currency', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'taxRate', dtype: 'percent', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'bruttoSurchargePrice', dtype: 'currency', style: { alignment: 'right' } },
                                        ]
                                    }
                                ]
                            }

                        ],
                    },
                    style: {
                        layout: 'lightHorizontalLines',
                        margin: [0, 10, 0, 0],
                        style: 'body'
                    }
                },
                // ---------------------------------- End Invoice items ---------------------

                // ---> start totals
                {
                    type: 'columns',
                    style: {
                        id: 'NoBreak',
                        margin: [0, 25, 0, 0]
                    },
                    columns: [
                        { type: 'staticText', item: { text: '', width: '*' } },
                        { type: 'staticText', item: { text: '', width: '*' } },
                        {
                            type: 'table',
                            table: {
                                body: [
                                    // --> netto line:
                                    {
                                        type: 'singleTableRow',
                                        items: [
                                            { type: 'staticText', item: { text: 'Summe Netto:', border: [false] } },
                                            {
                                                type: 'invoiceData',
                                                prefix: '',
                                                suffix: '',
                                                separator: '',
                                                items: [
                                                    {
                                                        ref: 'content.totals.netto',
                                                        dtype: 'currency',
                                                    }
                                                ], style: { border: [false] }
                                            },
                                        ],
                                    },
                                    // --> USt. sums
                                    {
                                        type: 'arrayToTableRows',
                                        arrayRef: 'content.totals.taxes',
                                        cells: [
                                            {
                                                items: [
                                                    { name: 'name', prefix: 'Summe USt. (', suffix: '):', style: { border: [false] } }
                                                ]
                                            },
                                            {
                                                items: [
                                                    { name: 'value', prefix: '+', dtype: 'currency', style: { border: [false] } },
                                                ]
                                            },
                                        ]
                                    },

                                    // --> Brutto
                                    {
                                        type: 'singleTableRow',
                                        items: [
                                            { type: 'staticText', item: { text: 'Summe Brutto:', border: [false, true, false, false] } },
                                            {
                                                type: 'invoiceData',
                                                prefix: '',
                                                suffix: '',
                                                separator: '',
                                                items: [
                                                    {
                                                        ref: 'content.totals.brutto',
                                                        dtype: 'currency',
                                                    }
                                                ], style: { border: [false, true, false, false] }
                                            },
                                        ],
                                    },
                                ],
                                dontBreakRows: true,
                            },
                            style: {
                                layout: {
                                    defaultBorder: false,
                                },
                                style: 'body',
                                alignment: 'right',
                                bold: true,
                            }
                        },
                    ],
                },
                // <--- end totals

                // ---> start delivery date
                {
                    type: 'invoiceData',
                    prefix: '',
                    suffix: '',
                    separator: '',
                    items: [
                        {
                            ref: 'content.dates.deliveryDate',
                            dtype: 'deliveryDate',
                            mappings: [
                                { key: 'type1', value: 'Leistungsdatum entspricht Rechnungsdatum' },
                                { key: 'type2', value: 'Lieferdatum entspricht Rechnungsdatum' },
                                { key: 'type3', value: 'Leistungsdatum' },
                                { key: 'type4', value: 'Leistungszeitraum' },
                                { key: 'type5', value: 'Lieferdatum' },
                                { key: 'type6', value: 'Lieferzeitraum' },
                            ]
                        }
                    ],
                    style: {
                        style: 'body',
                        margin: [0, 15, 0, 0]
                    }
                },
                // <--- end delivery date

                // ---> Zahlungsmodalitaet
                {
                    type: 'invoiceData',
                    prefix: 'Zahlungsmodalität: ',
                    suffix: '',
                    separator: '',
                    showRef: 'content.payment.showPaymentGoal',
                    items: [
                        {
                            ref: 'content.payment',
                            dtype: 'paymentGoal',
                            mappings: [
                                { key: 'cash-dicount-days', value: ' Tage ' },
                                { key: 'cash-dicount-value', value: ' Skonto, ' },
                                { key: 'cash-dicount-suffix', value: ' Tage netto Kasse' },
                                { key: 'no-cash-discount-prefix', value: 'ohne Abzug, ' },
                                { key: 'no-cash-discount-suffix', value: ' Tage nach Rechnungseingang' },
                            ]
                        }
                    ],
                    style: {
                        style: 'body',
                    }
                },
                // <--- Zahlungsmodalitaet

                // ---> reverse charge
                {
                    type: 'invoiceData',
                    prefix: 'Reverse Charge',
                    suffix: '',
                    separator: '',
                    showRef: 'content.payment.useReverseCharge',
                    items: [
                        {
                            ref: 'empty',
                        }
                    ],
                    style: {
                        style: 'body',
                    }
                },
                // <--- reverse charge

                // ---> EU row
                {
                    type: 'invoiceData',
                    prefix: '\n',
                    suffix: '\n',
                    separator: '',
                    showRef: 'content.standardInfos.EUINV.show',
                    items: [
                        {
                            ref: 'content.standardInfos.EUINV.value',
                            dtype: 'string',
                        }
                    ],
                    style: {
                        style: 'body',
                    }
                },
                // <--- EU row

                // ---> Regards
                {
                    type: 'invoiceData',
                    prefix: '',
                    suffix: '',
                    separator: '',
                    showRef: 'content.standardInfos.REGARDS.show',
                    items: [
                        {
                            ref: 'content.standardInfos.REGARDS.value',
                            dtype: 'string',
                        }
                    ],
                    style: {
                        style: 'body',
                        margin: [0, 15, 0, 0]
                    }
                },
                // <--- regards
            ]
        },
        footer: {
            pageNumber: {
                prefix: '',
                suffix: '',
                separator: ' von ',
                style: {
                    bold: true, margin: [30, 0, 30, 0], fontSize: 11, alignment: 'center'
                }
            },
            content: [
                {
                    type: 'staticText', layer: 'background', item: {
                        text: 'ppm visuals & internet GmbH', bold: true, margin: [30, 0, 30, 0], fontSize: 9
                    }
                },
                {
                    type: 'columns',
                    layer: 'background',
                    style: {
                        fontSize: 8, margin: [30, 10, 0, 15]
                    },
                    columns: [
                        { type: 'staticText', item: { text: 'Herzogstraße 3 . D-92637 Weiden\nT +49 (0) 961-3 88 18 19 - 51\nF +49 (0) 961-3 88 18 19 - 40', color: '#c5c5c5' } },
                        { type: 'staticText', item: { text: 'Geschäftsführende Gesellschafter\nStefanie Kellner und\nJochen Bayerl', color: '#c5c5c5' } },
                        { type: 'staticText', item: { text: 'Sparkasse Oberpfalz Nord\nIBAN\tDE80 75350000 00 11133444\nBIC\tBYLADEM1WEN', color: '#c5c5c5' } },
                        { type: 'staticText', item: { text: 'Amtsgericht Weiden HRB 3906\nUSt-IdNr. DE278898983\nD-U-N-S® Nummer 342389919', color: '#c5c5c5' } },
                    ],
                },
                {
                    type: 'staticText', layer: 'background', item: {
                        text: 'www.ppm-vi.de\thello@ppm-vi.de', alignment: 'right', color: '#05A88', fontSize: 7, bold: true, margin: [30, 0, 30, 0]
                    }
                },
            ]
        },
        background: [
            {
                type: 'image',
                layer: 'background',
                item: {
                    image: 'logo',
                    absolutePosition: { x: 245, y: 0 },
                    width: 130,
                }
            },
        ],
        images: {
            logo: ppmviLogo,
        }
    }
}

const templatePPMVI_letter = {
    id: 'de_letter_id', name: 'DE_Briefpapier', template: {
        localisation: {
            code: 'de-DE', // check javascript locales
            dateFormat: 'DD.MM.YYYY' // check moment js
        },
        page: {
            pageMargins: [40, 250, 40, 90],
            pageSize: 'A4',
            styles: {
                header: {
                    fontSize: 11,
                    //bold: true
                },
                headerRight: {
                    fontSize: 11,
                    alignment: 'right'
                },
                body: {
                    fontSize: 10,
                },
            },
        },
        header: {
            content: [
                {
                    type: 'address-v1',
                    template: {
                        stack: [

                        ],

                    }
                },
            ]
        },
        body: {
            content: [
                {
                    type: 'stack',
                    items: [
                        {
                            type: 'table',
                            table: {
                                widths: [230],
                                body: [
                                    {
                                        type: 'singleTableRow', items: [{
                                            type: 'staticText', item: {
                                                text: 'ppm visuals & internet GmbH | Herzogstraße 3 | 92637 Weiden'
                                            }
                                        }]
                                    },
                                ]
                            },
                            style: {
                                fontSize: 6,
                                layout: {
                                    defaultBorder: false,
                                },
                            }
                        },
                        {
                            type: 'table',
                            table: {
                                widths: [230],
                                body: [
                                    {
                                        type: 'singleTableRow',
                                        items: [
                                            {
                                                type: 'invoiceData',
                                                prefix: '',
                                                suffix: '',
                                                separator: '',
                                                items: [
                                                    {
                                                        ref: 'content.customer',
                                                        showRef: undefined,
                                                        dtype: 'address',
                                                        mappings: [
                                                            { key: 'male', value: 'Herrn', valuePl: undefined },
                                                            { key: 'female', value: 'Frau', valuePl: undefined },
                                                        ]
                                                    }
                                                ],
                                                style: undefined,
                                            },
                                        ]
                                    },
                                    {
                                        type: 'singleTableRow',
                                        items: [
                                            {
                                                type: 'invoiceData',
                                                prefix: 'USt.-ID.: ',
                                                suffix: '',
                                                separator: '',
                                                showRef: 'content.customer.showVAT',
                                                items: [
                                                    {
                                                        ref: 'content.customer.VAT',
                                                        dtype: 'string',
                                                    }
                                                ],
                                                style: undefined
                                            },
                                        ]
                                    }
                                ]
                            },
                            style: {
                                layout: {
                                    defaultBorder: false,
                                },
                                margin: [0, 4, 0, 0],
                                style: 'header'
                            }
                        }
                    ],
                    style: {
                        absolutePosition: { x: 40, y: 135 },
                    }
                },
                // <-- end address part
                // ---> start header
                {
                    type: 'columns',
                    style: {
                        margin: [0, 30, 0, 0]
                    },
                    columns: [
                        {
                            type: 'stack',
                            style: {
                                width: 'auto',
                            },
                            items: [
                                {
                                    type: 'invoiceData',
                                    prefix: '',
                                    suffix: '',
                                    separator: '',
                                    showRef: 'content.standardInfos.TITLE.show',
                                    items: [
                                        {
                                            ref: 'content.standardInfos.TITLE.value',
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                        bold: true,
                                        // margin: [0, 30, 0, 0]
                                    }
                                },
                                // Invoice number -->
                                {
                                    type: 'invoiceData',
                                    prefix: 'Rechnungsnr.: ',
                                    suffix: '',
                                    separator: '-',
                                    items: [
                                        {
                                            ref: 'content.customInfos.PROJECTNR.value',
                                            showRef: 'content.customInfos.PROJECTNR.show',
                                            dtype: 'string',
                                        },
                                        {
                                            ref: 'invoiceNumber', // use invoice number suffix
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                    }
                                },
                                // Project infos -->
                                {
                                    type: 'invoiceData',
                                    prefix: 'Projekt: ',
                                    suffix: '',
                                    separator: '',
                                    showRef: 'content.customInfos.PROJECT.show',
                                    items: [
                                        {
                                            ref: 'content.customInfos.PROJECT.value',
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                    }
                                },
                                // VENDOR NUMBER
                                {
                                    type: 'invoiceData',
                                    prefix: '',
                                    suffix: '',
                                    separator: '',
                                    showRef: 'content.customInfos.VENDOR.show',
                                    items: [
                                        {
                                            ref: 'content.customInfos.VENDOR.value',
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                    }
                                },
                                {
                                    type: 'invoiceData',
                                    prefix: '',
                                    suffix: '',
                                    separator: '',
                                    showRef: 'content.customer.showCustomerNumber',
                                    items: [
                                        {
                                            ref: 'content.customer.customerNumber',
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                    }
                                },
                                {
                                    type: 'invoiceData',
                                    prefix: '',
                                    suffix: '',
                                    separator: '',
                                    showRef: 'content.customInfos.EXTRA_REFERENCE.show',
                                    items: [
                                        {
                                            ref: 'content.customInfos.EXTRA_REFERENCE.value',
                                            dtype: 'string',
                                        }
                                    ],
                                    style: {
                                        style: 'header',
                                    }
                                },
                            ]
                        },
                        // second column -->
                        {
                            type: 'stack', items: [
                                {
                                    type: 'invoiceData',
                                    prefix: 'Rechnungsdatum: ',
                                    suffix: '',
                                    separator: '',
                                    items: [
                                        {
                                            ref: 'content.dates.invoiceDate',
                                            dtype: 'date',
                                        }
                                    ],
                                    style: {
                                        style: 'headerRight',
                                        // margin: [0, 15, 0, 0]
                                    }
                                },
                            ]
                        }
                    ],
                },
                // <--- end header

                // ---> start greeting
                {
                    type: 'invoiceData',
                    prefix: 'Sehr geehrte',
                    suffix: ',',
                    separator: '',
                    items: [
                        {
                            ref: 'content.customer.contactPerson',
                            dtype: 'name',
                            mappings: [
                                { key: 'male', value: 'r Herr' },
                                { key: 'female', value: ' Frau' },
                                { key: 'general', value: ' Damen und Herren' },
                            ]
                        }
                    ],
                    style: {
                        style: 'body',
                        margin: [0, 40, 0, 0],
                    }
                },
                // ---> end greeting

                {
                    type: 'invoiceData',
                    prefix: '\n',
                    suffix: '',
                    separator: '',
                    showRef: 'content.standardInfos.INTRO.show',
                    items: [
                        {
                            ref: 'content.standardInfos.INTRO.value',
                            dtype: 'string',
                        }
                    ],
                    style: {
                        style: 'body',
                    }
                },

                // ---------------------------------- Start Invoice items ---------------------
                {
                    type: 'table',
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: ['auto', 'auto', '*', '*', '*', 'auto', '*'],
                        dontBreakRows: true,
                        body: [
                            {
                                type: 'singleTableRow',
                                items: [
                                    { type: 'staticText', item: { text: 'POS' } },
                                    { type: 'staticText', item: { text: [{ text: 'Bezeichnung' }, { text: '', italics: true, fontSize: 10 }] } },
                                    { type: 'staticText', item: { text: 'Menge', alignment: 'right' } },
                                    { type: 'staticText', item: { text: 'Einzelpreis', alignment: 'right' } },
                                    { type: 'staticText', item: { text: 'Netto', alignment: 'right' } },
                                    { type: 'staticText', item: { text: 'USt.' } },
                                    { type: 'staticText', item: { text: 'Brutto', alignment: 'right' } }
                                ]
                            }, // Header row
                            {
                                type: 'arrayToTableRows',
                                arrayRef: 'content.items',
                                cells: [
                                    {
                                        items: [
                                            { name: 'index', style: { alignment: 'center' } }
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'title', style: undefined },
                                            { name: 'description', style: { italics: true, fontSize: 8 } }
                                        ]
                                    },
                                    {
                                        items: [
                                            {
                                                name: 'itemQuantity', style: { alignment: 'right', noWrap: true }, mappings: [
                                                    { key: 'percentage', value: '%' },
                                                    { key: 'all-inclusive', value: 'pauschal' },
                                                    { key: 'time', value: 'h' },
                                                    { key: 'piece', value: 'Stück' },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'unitPrice', dtype: 'currency', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'nettoItemPrice', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'taxRate', dtype: 'percent', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'bruttoItemPrice', style: { alignment: 'right' } },
                                        ]
                                    }
                                ]
                            },
                            {
                                type: 'arrayToTableRows',
                                arrayRef: 'content.surcharges',
                                cells: [
                                    {
                                        items: [
                                            { name: 'surchargeIndex', style: { alignment: 'center' } }
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'title', style: undefined },
                                            { name: 'description', style: { italics: true, fontSize: 8 } },
                                            { name: 'surchargeItemRefs', style: { italics: true, fontSize: 8 } }
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'surchargeAmount', dtype: 'percentage', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'surchargeBasePrice', dtype: 'currency', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'nettoSurchargePrice', dtype: 'currency', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'taxRate', dtype: 'percent', style: { alignment: 'right' } },
                                        ]
                                    },
                                    {
                                        items: [
                                            { name: 'bruttoSurchargePrice', dtype: 'currency', style: { alignment: 'right' } },
                                        ]
                                    }
                                ]
                            }
                        ],
                    },
                    style: {
                        layout: 'lightHorizontalLines',
                        margin: [0, 10, 0, 0],
                        style: 'body'
                    }
                },
                // ---------------------------------- End Invoice items ---------------------

                // ---> start totals
                {
                    type: 'columns',
                    style: {
                        id: 'NoBreak',
                        margin: [0, 25, 0, 0]
                    },
                    columns: [
                        { type: 'staticText', item: { text: '', width: '*' } },
                        { type: 'staticText', item: { text: '', width: '*' } },
                        {
                            type: 'table',
                            table: {
                                body: [
                                    // --> netto line:
                                    {
                                        type: 'singleTableRow',
                                        items: [
                                            { type: 'staticText', item: { text: 'Summe Netto:', border: [false] } },
                                            {
                                                type: 'invoiceData',
                                                prefix: '',
                                                suffix: '',
                                                separator: '',
                                                items: [
                                                    {
                                                        ref: 'content.totals.netto',
                                                        dtype: 'currency',
                                                    }
                                                ], style: { border: [false] }
                                            },
                                        ],
                                    },
                                    // --> USt. sums
                                    {
                                        type: 'arrayToTableRows',
                                        arrayRef: 'content.totals.taxes',
                                        cells: [
                                            {
                                                items: [
                                                    { name: 'name', prefix: 'Summe USt. (', suffix: '):', style: { border: [false] } }
                                                ]
                                            },
                                            {
                                                items: [
                                                    { name: 'value', prefix: '+', dtype: 'currency', style: { border: [false] } },
                                                ]
                                            },
                                        ]
                                    },

                                    // --> Brutto
                                    {
                                        type: 'singleTableRow',
                                        items: [
                                            { type: 'staticText', item: { text: 'Summe Brutto:', border: [false, true, false, false] } },
                                            {
                                                type: 'invoiceData',
                                                prefix: '',
                                                suffix: '',
                                                separator: '',
                                                items: [
                                                    {
                                                        ref: 'content.totals.brutto',
                                                        dtype: 'currency',
                                                    }
                                                ], style: { border: [false, true, false, false] }
                                            },
                                        ],
                                    },
                                ],
                                dontBreakRows: true,
                            },
                            style: {
                                layout: {
                                    defaultBorder: false,
                                },
                                style: 'body',
                                alignment: 'right',
                                bold: true,
                            }
                        },
                    ],
                },
                // <--- end totals

                // ---> start delivery date
                {
                    type: 'invoiceData',
                    prefix: '',
                    suffix: '',
                    separator: '',
                    items: [
                        {
                            ref: 'content.dates.deliveryDate',
                            dtype: 'deliveryDate',
                            mappings: [
                                { key: 'type1', value: 'Leistungsdatum entspricht Rechnungsdatum' },
                                { key: 'type2', value: 'Lieferdatum entspricht Rechnungsdatum' },
                                { key: 'type3', value: 'Leistungsdatum' },
                                { key: 'type4', value: 'Leistungszeitraum' },
                                { key: 'type5', value: 'Lieferdatum' },
                                { key: 'type6', value: 'Lieferzeitraum' },
                            ]
                        }
                    ],
                    style: {
                        style: 'body',
                        margin: [0, 15, 0, 0]
                    }
                },
                // <--- end delivery date

                // ---> Zahlungsmodalitaet
                {
                    type: 'invoiceData',
                    prefix: 'Zahlungsmodalität: ',
                    suffix: '',
                    separator: '',
                    showRef: 'content.payment.showPaymentGoal',
                    items: [
                        {
                            ref: 'content.payment',
                            dtype: 'paymentGoal',
                            mappings: [
                                { key: 'cash-dicount-days', value: ' Tage ' },
                                { key: 'cash-dicount-value', value: ' Skonto, ' },
                                { key: 'cash-dicount-suffix', value: ' Tage netto Kasse' },
                                { key: 'no-cash-discount-prefix', value: 'ohne Abzug, ' },
                                { key: 'no-cash-discount-suffix', value: ' Tage nach Rechnungseingang' },
                            ]
                        }
                    ],
                    style: {
                        style: 'body',
                    }
                },
                // <--- Zahlungsmodalitaet

                // ---> reverse charge
                {
                    type: 'invoiceData',
                    prefix: 'Reverse Charge',
                    suffix: '',
                    separator: '',
                    showRef: 'content.payment.useReverseCharge',
                    items: [
                        {
                            ref: 'empty',
                        }
                    ],
                    style: {
                        style: 'body',
                    }
                },
                // <--- reverse charge

                // ---> EU row
                {
                    type: 'invoiceData',
                    prefix: '\n',
                    suffix: '\n',
                    separator: '',
                    showRef: 'content.standardInfos.EUINV.show',
                    items: [
                        {
                            ref: 'content.standardInfos.EUINV.value',
                            dtype: 'string',
                        }
                    ],
                    style: {
                        style: 'body',
                    }
                },
                // <--- EU row

                // ---> Regards
                {
                    type: 'invoiceData',
                    prefix: '',
                    suffix: '',
                    separator: '',
                    showRef: 'content.standardInfos.REGARDS.show',
                    items: [
                        {
                            ref: 'content.standardInfos.REGARDS.value',
                            dtype: 'string',
                        }
                    ],
                    style: {
                        style: 'body',
                        margin: [0, 15, 0, 0]
                    }
                },
                // <--- regards
            ]
        },
        footer: {
            pageNumber: {
                prefix: '',
                suffix: '',
                separator: ' von ',
                style: {
                    bold: true, margin: [30, 0, 30, 0], fontSize: 11, alignment: 'center'
                }
            },
            content: []
        },
    }
}


export const ppmVItemplates = [
    templatePPMVI,
    templatePPMVI_letter
];
