import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import InvoiceItem, { getNettoPriceOfItem } from '../../../../../../models/invoice/invoiceItem';
import { timeDisplay, unitDisplay } from '../../../InvoiceTables/utils';

interface InvoiceItemPreviewProps {
    item: InvoiceItem;
    currentIndex: number;
    currency: string;
    isLast?: boolean;
    onMove: (newIndex: number) => void;
    onOpenEdit: () => void;
    onRemove: () => void;
}

const InvoiceItemPreview: React.FC<InvoiceItemPreviewProps> = (props) => {
    const widthPriceCol = 105;
    const widthAmountAndTaxCol = 70;
    const widthUnitCol = 85;
    const px = 1;
    const py = 1;



    return (
        <React.Fragment>
            <Box display="flex" alignItems="center">
                <Box width="60px" minWidth={60} px={px} py={py} textAlign="center">
                    {
                        props.currentIndex < 1 ? null :
                            <Tooltip title="Nach oben verschieben">
                                <IconButton aria-label="delete" size="small"
                                    onClick={() => props.onMove(props.currentIndex - 1)}
                                >
                                    <ArrowDropUpIcon />
                                </IconButton>
                            </Tooltip>
                    }
                    <Typography variant="body1">
                        {props.currentIndex + 1}
                    </Typography>
                    {
                        props.isLast ? null :
                            <Tooltip title="Nach unten verschieben">
                                <IconButton aria-label="delete" size="small"
                                    onClick={() => props.onMove(props.currentIndex + 1)}
                                >
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </Tooltip>
                    }
                </Box>
                <Box flexGrow={1} px={px} py={py}>
                    <Typography variant="body1">
                        {props.item.title}
                    </Typography>
                    <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                        {props.item.description}
                    </Typography>
                </Box>
                <Box width={widthAmountAndTaxCol + 20} minWidth={widthAmountAndTaxCol} textAlign="right" px={px} py={py}>
                    <Typography variant="body1">
                        {props.item.unit === 'time' ?
                            timeDisplay(props.item.quantity) :
                            props.item.quantity.toLocaleString('de-DE')
                        }
                    </Typography>
                </Box>
                <Box width={widthUnitCol} minWidth={widthUnitCol} px={px} py={py}>
                    <Typography variant="body1">
                        {unitDisplay(props.item.unit)}
                    </Typography>
                </Box>

                <Box width={widthPriceCol} minWidth={widthPriceCol} textAlign="right" px={px} py={py}>
                    <Typography variant="body1">
                        {props.item.unitPrice.toLocaleString('de-DE', { style: 'currency', currency: props.currency })}
                    </Typography>
                </Box>
                <Box width={widthPriceCol} minWidth={widthPriceCol} textAlign="right" px={px} py={py}>
                    <Typography variant="body1">
                        {getNettoPriceOfItem(props.item).toLocaleString('de-DE', { style: 'currency', currency: props.currency })}
                    </Typography>
                </Box>
                <Box width={widthAmountAndTaxCol} minWidth={widthAmountAndTaxCol} textAlign="right" px={px} py={py}>
                    <Typography variant="body1">
                        {(props.item.taxRate).toLocaleString('de-DE', { style: 'percent' })}
                    </Typography>
                </Box>
                <Box pl={px} py={py} textAlign="center" width={80} minWidth={80}>
                    <Tooltip title="Angaben bearbeiten">
                        <IconButton aria-label="open edit" size="small"
                            onClick={() => props.onOpenEdit()}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Entfernen">
                        <IconButton aria-label="delete" size="small"
                            onClick={() => {
                                if (window.confirm("Möchten Sie das Element entfernen?")) {
                                    props.onRemove()
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Divider light />
        </React.Fragment>

    );
};

export default InvoiceItemPreview;
