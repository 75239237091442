import React, { useState } from 'react';
import Customer from '../../../../../../models/customer';
import ContactTable from '../../../../Addressbook/ContactTable/ContactTable';
import Button from '@material-ui/core/Button';
import DangerBtn from '../../../../../common/DangerBtn/DangerBtn';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

interface CustomerSearchDialogProps {
    open: boolean;
    customers: Array<Customer>;
    onClose: (selectedCustomer: Customer | null) => void;
}

const CustomerSearchDialog: React.FC<CustomerSearchDialogProps> = (props) => {
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

    const handleClose = () => {
        props.onClose(null);
    };

    const customerSearch = (
        <Dialog onClose={handleClose} aria-labelledby="customer-dialog" open={props.open}
            fullWidth
            maxWidth="lg">
            <DialogTitle id="customer-dialog-title">Wählen Sie einen Kunden aus</DialogTitle>
            <DialogContent dividers>
                <ContactTable
                    customers={props.customers}
                    onSelect={(customer) => {
                        if (customer === undefined) {
                            setSelectedCustomer(null);
                            return;
                        }
                        setSelectedCustomer(customer);
                    }}
                    selectedId={selectedCustomer ? selectedCustomer.id : undefined}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                    disabled={!selectedCustomer}
                    onClick={
                        () => {
                            props.onClose(selectedCustomer);
                        }
                    }
                >Übernehmen</Button>
                <DangerBtn
                    onClick={
                        () => {
                            props.onClose(null);
                        }
                    }>Abbrechen</DangerBtn>
            </DialogActions>
        </Dialog>

    );

    return (
        <React.Fragment>
            {props.open ?
                customerSearch
                : null}
        </React.Fragment>
    );
};

export default CustomerSearchDialog;
