import { DocumentTemplate } from "../../../models/invoiceTemplates";
import { InvoiceTag } from "../../../models/invoice";

export interface InvoiceConfigsState {
    loading: boolean;
    templates: DocumentTemplate[];
    tags: InvoiceTag[];
}

export const FETCH_INVOICE_CONFIGS_START = "FETCH_INVOICE_CONFIGS_START";

export const FETCH_INVOICE_TAGS_START = "FETCH_INVOICE_TAGS_START";
export const FETCH_INVOICE_TAGS_FAILED = "FETCH_INVOICE_TAGS_FAILED";
export const RECEIVE_INVOICE_TAGS = "RECEIVE_INVOICE_TAGS";

interface FetchInvoiceConfigsStartAction {
    type: typeof FETCH_INVOICE_CONFIGS_START;
}

interface FetchInvoiceTagsStartAction {
    type: typeof FETCH_INVOICE_TAGS_START;
}

interface FetchInvoiceTagsFailedAction {
    type: typeof FETCH_INVOICE_TAGS_FAILED;
}

interface ReceiveInvoiceTagsAction {
    type: typeof RECEIVE_INVOICE_TAGS;
    tags: InvoiceTag[];
}

export type InvoiceConfigActionTypes =
    FetchInvoiceConfigsStartAction |
    FetchInvoiceTagsStartAction |
    FetchInvoiceTagsFailedAction |
    ReceiveInvoiceTagsAction;
