import React, { useState } from 'react';
import { Typography, Box, TextField, Button, CircularProgress } from '@material-ui/core';
import { changePassword } from '../../../api';
import { checkPasswordRules } from '../../../utils/checkPasswordRules';

interface PasswordChangeProps {
}

const variant = 'outlined';
const margin = 'dense';

const PasswordChange: React.FC<PasswordChangeProps> = (props) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);

    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const btnEnabled = (): boolean => {
        if (loading || oldPassword.length < 1 || !checkPasswordRules(newPassword) || newPassword !== confirmPassword) {
            return false;
        }
        return true;
    }

    const updatePasswordCall = async () => {
        setLoading(true);
        setSuccess('');
        setError('');
        try {
            await changePassword({
                userId: '',
                newPassword: newPassword,
                oldPassword: oldPassword,
            });
            setSuccess('Passwort wurde erfolgreich geändert.')
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (err) {
            console.error(err.response);
            setError('Passwortänderung fehlgeschlagen.');
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box maxWidth={500}>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    updatePasswordCall()
                }}>
                <Typography variant="h5">
                    {'Passwort Ändern'}
                </Typography>
                <TextField variant={variant} margin={margin} fullWidth
                    type="password"
                    id="current-password"
                    autoComplete="current-password"
                    name="current-password"
                    label="Altes Passwort"
                    value={oldPassword}
                    onChange={(event) => setOldPassword(event.target.value)}
                />
                <TextField variant={variant} margin={margin} fullWidth
                    type="password"
                    id="new-password"
                    name="new-password"
                    label="Neues Passwort"
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                    onBlur={() => setShowPasswordError(true)}
                />
                {showPasswordError && !checkPasswordRules(newPassword) ? <Typography color="error">{'Passwort muss mindestens 8 Zeichen lang sein, und mindestens drei von den folgenden vier Zeichenkategorien enthalten: Kleinbuchstaben, Großbuchstaben, Zahlen, Sonderzeichen'}</Typography> : null}
                <TextField variant={variant} margin={margin} fullWidth
                    type="password"
                    id="confirm"
                    name="confirm"
                    label="Passwort Wiederholen"
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                />
                <Box mt={1}>
                    <Button
                        type="submit"
                        color="primary" variant="contained"
                        disabled={!btnEnabled()}
                    >
                        {'Passwort Ändern'}
                        {loading ? <Box component="span" ml={1} display="flex" alignItems="center" ><CircularProgress size={16} /></Box> : null}
                    </Button>
                </Box>
                <Box mt={1}
                >
                    {error ? <Typography color="error">{error}</Typography> : null}
                    {success ? <Typography color="primary">{success}</Typography> : null}
                </Box>
            </form>
        </Box>
    );
};

export default PasswordChange;
