import {
    DraftInvoiceState,
    DraftInvoiceActionTypes,
    FETCH_DRAFT_INVOICES_START, FETCH_DRAFT_INVOICES_FAILED, RECEIVE_DRAFT_INVOICES,
    ADD_DRAFT_INVOICE, UPDATE_DRAFT_INVOICE, REMOVE_DRAFT_INVOICE, SELECT_DRAFT_INVOICE,
} from './types'
import { updateObject, updateArrayItemById } from '../../utils'


const initialState: DraftInvoiceState = {
    loading: false,
    items: [],
    selected: undefined,
}

export function draftInvoiceReducer(
    state = initialState,
    action: DraftInvoiceActionTypes
): DraftInvoiceState {
    switch (action.type) {
        case FETCH_DRAFT_INVOICES_START:
            return updateObject(state, { loading: true });
        case FETCH_DRAFT_INVOICES_FAILED:
            return updateObject(state, { loading: false });
        case RECEIVE_DRAFT_INVOICES:
            return updateObject(state, {
                loading: false, items: [...action.invoices]
            });
        case ADD_DRAFT_INVOICE:
            return updateObject(state,
                { loading: false, items: [...state.items, action.newInvoice], }
            );
        case UPDATE_DRAFT_INVOICE:
            const newSelection = (state.selected && state.selected.id === action.updatedInvoice.id)
                ? { ...action.updatedInvoice } : state.selected;
            return updateObject(state, {
                loading: false,
                items: updateArrayItemById(state.items, action.updatedInvoice),
                selected: newSelection
            });
        case SELECT_DRAFT_INVOICE:
            return updateObject(state, { selected: action.invoice });
        case REMOVE_DRAFT_INVOICE:
            return updateObject(state, { loading: false, items: state.items.filter(item => item.id !== action.id), selected: state.selected?.id === action.id ? undefined : state.selected });
        default:
            return state
    }
}


