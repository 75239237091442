import React from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import Drawer from '../../components/navigation/Drawer/Drawer';
import InvoiceDashboard from './Invoices/InvoiceDashboard/InvoiceDashboard';
import InvoiceEditorPage from './Invoices/InvoiceEditorPage/InvoiceEditorPage';
import InvoicePage from './Invoices/InvoicePage/InvoicePage';
import CustomerEditorPage from './Addressbook/CustomerEditorPage';
import AddressbookDashboard from './Addressbook/AddressbookDashboard';
import { Box } from '@material-ui/core';
import Settings from './Settings';


const Home: React.FC = () => {

    return (
        <div style={{
            display: 'flex'
        }}>
            <Drawer side="left"></Drawer>
            <Box flexGrow={1}>
                <Switch>
                    <Route path="/home" exact component={InvoiceDashboard} />
                    <Route path="/home/invoice" exact component={InvoicePage} />
                    <Route path="/home/invoice-editor" component={InvoiceEditorPage} />
                    <Route path="/home/addressbook" component={AddressbookDashboard} />
                    <Route path="/home/customer-editor" component={CustomerEditorPage} />
                    <Route path="/home/settings" component={Settings} />
                    <Route path="/home/not-found" component={() => <p>Die Seite konnte nicht gefunden werden. Bitte URL kontrollieren.</p>} />
                    <Redirect to="/home/not-found" />
                </Switch>
            </Box>
        </div>
    )
}

export default Home;
