import React from 'react';
import Note from '../../../../../models/note';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

interface NotesProps {
    notes: Note[];
    onAddNote: (note: Note) => void;
    onRemoveNote: (note: Note) => void;
}

const Notes: React.FC<NotesProps> = (props) => {
    return (
        <Box mt={1}>
            <Typography variant="subtitle2">
                Notizen
            </Typography>
            <Typography variant="body1" align="center">
                Keine Notizen
            </Typography>
            <Button disabled>Notiz Hinzufügen</Button>
        </Box>
    );
};

export default Notes;
