import React from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import { useSelector } from 'react-redux';
import { AuthState } from '../../store/auth/types';

import Footer from '../../components/navigation/Footer/Footer';

import Login from './Login/Login';
// import Landing from './Landing/Landing';
import Impressum from './Impressum/Impressum';
import { Grid } from '@material-ui/core';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';



const Start: React.FC = () => {
    const isAuth = useSelector((state: { auth: AuthState }) => state.auth.isAuth)

    return (
        <Grid container direction="column" justify="space-between" style={{ minHeight: "100vh" }}>
            <Switch>
                {/*<Route path="/start" exact component={Landing} />*/}
                <Route path="/start/login" component={Login} />
                <Route path="/start/impressum" component={Impressum} />
                <Route path="/start/privacy" component={PrivacyPolicy} />
                {isAuth ?
                    <Redirect to="/start/login"></Redirect> :
                    <Redirect to="/start/login" />}
            </Switch>
            <Footer />
        </Grid>
    )
}

export default Start;
