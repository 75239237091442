import React, { useState } from 'react';
import { Invoice } from '../../../../../models/invoice';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { getCompany, getAddress } from '../utils';
import { personToString } from '../../../../../utils/modelsToString';
import moment from 'moment';
import FlexGrow from '../../../../common/FlexGrow/FlexGrow';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ColumnHeader from '../../../../common/ColumnHeader';

interface FinalInvoicePreviewProps {
    invoice?: Invoice;
    onOpen: () => void;
    onClone: (invoice: Invoice) => void;
    onStorno: (invoice: Invoice) => void;
    onDownload: (invoice: Invoice) => void;
}

const FinalInvoicePreview: React.FC<FinalInvoicePreviewProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action: string) => () => {
        setAnchorEl(null);
        switch (action) {
            case 'download':
                if (props.invoice) {
                    props.onDownload(props.invoice);
                }
                break;
            case 'clone':
                if (props.invoice) {
                    props.onClone(props.invoice);
                }
                break;
            case 'storno':
                if (props.invoice) {
                    props.onStorno(props.invoice);
                }
                break;
            default:
                break;
        }
    };

    const my = 1;

    return (
        <Box textAlign="left">
            <ColumnHeader borderBottom={true}>
                <Typography variant="h6">Vorschau</Typography>
                <FlexGrow />
                {
                    props.invoice ? <React.Fragment>
                        <IconButton aria-controls="action-menu" aria-haspopup="true" onClick={handleClick}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="action-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose('')}
                        >
                            <MenuItem onClick={handleClose('download')}>Herunterladen</MenuItem>
                            <MenuItem onClick={handleClose('clone')}>Duplizieren</MenuItem>
                            <MenuItem onClick={handleClose('storno')} disabled>
                                <Typography color="error">
                                    Stornorechnung erstellen
                        </Typography>
                            </MenuItem>
                        </Menu>
                    </React.Fragment> : null
                }

            </ColumnHeader>
            {props.invoice ?
                <Box p={2}>
                    <Box display="flex">
                        <Box my={my} flexGrow={1}>
                            <Typography variant="subtitle2">
                                Rechnungsnummer:
                            </Typography>
                            <Typography variant="body1">
                                {props.invoice.invoiceNumber}
                            </Typography>
                        </Box>
                        <Box my={my}>
                            <Typography variant="subtitle2">
                                Rechnungsdatum:
                            </Typography>
                            <Typography variant="body1">
                                {props.invoice.content.dates.invoiceDate ?
                                    moment.unix(props.invoice.content.dates.invoiceDate).format('DD.MM.YYYY')
                                    : 'nicht definiert'
                                }
                            </Typography>
                        </Box>

                    </Box>

                    <Box my={my}>
                        <Typography variant="subtitle2">
                            Kunde:
                         </Typography>
                        <Typography variant="body1">
                            {getCompany(props.invoice)}
                        </Typography>
                        <Typography variant="body1">
                            {personToString(props.invoice.content.customer.contactPerson)}
                        </Typography>
                        <Typography variant="body1">
                            {getAddress(props.invoice.content.customer.address)}
                        </Typography>
                    </Box>

                    <Box my={my}>
                        <Typography variant="subtitle2">
                            Posten:
                        </Typography>
                        <Typography variant="body1">
                            {
                                props.invoice.content.items ?
                                    props.invoice.content.items.map(
                                        (item, index) => <span key={index}>
                                            {item.title}
                                            {props.invoice && props.invoice.content.items && index < props.invoice.content.items.length - 1 ? ' | ' : ''}
                                        </span>
                                    ) :
                                    "Keine Posten"
                            }
                        </Typography>
                    </Box>

                    <Box my={my}>
                        <Typography variant="subtitle2">
                            Summe:
                        </Typography>
                        <Typography variant="body1">
                            Netto: {props.invoice.content.totals.netto.toLocaleString('de-DE', { style: 'currency', currency: props.invoice.content.payment.currency })}
                            <span> | </span> Brutto: {props.invoice.content.totals.brutto.toLocaleString('de-DE', { style: 'currency', currency: props.invoice.content.payment.currency })}
                        </Typography>
                    </Box>

                    <Box textAlign="center">
                        <Button variant="contained"
                            onClick={() => props.onOpen()}

                        >
                            Öffnen
                    </Button>
                    </Box>
                </Box>
                : <Box p={2}> <p>Wählen Sie eine Rechnung aus.</p></Box>}
        </Box>


    );
};

export default FinalInvoicePreview;
