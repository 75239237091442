import {
    InvoiceEditorState, InvoiceEditorActionTypes, INIT_NEW_EDITOR,
} from './types'
import { updateObject } from '../utils'


const initialState: InvoiceEditorState = {
    mode: 'create',
}

export function invoiceEditorReducer(
    state = initialState,
    action: InvoiceEditorActionTypes
): InvoiceEditorState {
    switch (action.type) {
        case INIT_NEW_EDITOR:
            return updateObject(state, {
                mode: action.mode,
                invoice: action.invoice,
                forCustomer: action.forCustomer,
            });
        default:
            return state
    }
}
