import React, { useState, useEffect } from 'react';
import InvoiceItem, { getNettoPriceOfItem } from '../../../../../models/invoice/invoiceItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TotalsView from './TotalsView/TotalsView';
import InvoiceItemPreview from './InvoiceIItemPreview/InvoiceItemPreview';
import InvoiceItemEditor from './InvoiceItemEditor/InvoiceItemEditor';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import DiscountItemEditor from './DiscountItemEditor/DiscountItemEditor';
import DiscountItemPreview from './DiscountItemPreview/DiscountItemPreview';
import Surcharge, { getNettoPriceOfSurchargeItem } from '../../../../../models/invoice/surcharge';
import { InvoiceTotals } from '../../../../../models/invoice';

interface InvoiceItemsEditorProps {
    items: InvoiceItem[],
    discounts: Surcharge[],
    totals: InvoiceTotals,
    currency: string;
    defaultTaxRate?: number;
    templates: InvoiceItem[];
    loadingTemplate: boolean;
    onCreateTemplate: (item: InvoiceItem) => void;
    onRemoveTemplate: (item: InvoiceItem) => void;
    onChange: (items: InvoiceItem[], discounts: Surcharge[], totals: InvoiceTotals) => void;
}

const InvoiceItemsEditor: React.FC<InvoiceItemsEditorProps> = (props) => {
    // init state
    const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[]>(props.items);
    const [discountItems, setDiscountItems] = useState<Surcharge[]>(props.discounts);
    const [totals, setTotals] = useState<InvoiceTotals>(props.totals);

    // react to external changes
    useEffect(() => { setInvoiceItems([...props.items]) }, [props.items]);
    useEffect(() => { setDiscountItems([...props.discounts]) }, [props.discounts]);
    useEffect(() => { setTotals({ ...props.totals }) }, [props.totals]);

    // emit change after values not changed for a while (reduce rerendering when typing fast)
    useEffect(() => {
        const timer = setTimeout(() => {
            props.onChange(invoiceItems, discountItems, totals);
        }, 500);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totals]);

    useEffect(() => {
        // calculate totals
        const newTotals: InvoiceTotals = {
            netto: 0.0,
            brutto: 0.0,
            taxes: [],
        }

        invoiceItems.forEach(item => {
            const currentNetto = getNettoPriceOfItem(item);
            newTotals.netto += currentNetto;
            if (item.taxRate > 0) {
                const tInd = newTotals.taxes.findIndex(t => t.rate === item.taxRate);
                if (tInd < 0) {
                    newTotals.taxes.push({
                        name: item.taxRate.toLocaleString('de-DE', { style: 'percent' }),
                        rate: item.taxRate,
                        value: currentNetto * item.taxRate,
                        show: true,
                        sortIndex: Math.round(item.taxRate * 100),
                    })
                } else {
                    newTotals.taxes[tInd].value += currentNetto * item.taxRate;
                }
            }
        })

        discountItems.forEach(item => {
            const currentNetto = getNettoPriceOfSurchargeItem(item, invoiceItems);
            newTotals.netto += currentNetto;
            if (item.taxRate > 0) {
                const tInd = newTotals.taxes.findIndex(t => t.rate === item.taxRate);
                if (tInd < 0) {
                    newTotals.taxes.push({
                        name: item.taxRate.toLocaleString('de-DE', { style: 'percent' }),
                        rate: item.taxRate,
                        value: currentNetto * item.taxRate,
                        show: true,
                        sortIndex: Math.round(item.taxRate * 100),
                    })
                } else {
                    newTotals.taxes[tInd].value += currentNetto * item.taxRate;
                }
            }
        })

        newTotals.taxes = newTotals.taxes.sort((a, b) => a.sortIndex - b.sortIndex);

        newTotals.brutto = newTotals.netto;
        newTotals.taxes.forEach(t => {
            newTotals.brutto += t.value;
        })

        setTotals(newTotals);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceItems, discountItems]);

    // Invoice Item methods:
    const addInvoiceItem = (item: InvoiceItem) => {
        setInvoiceItems(prev => { return [...prev, item]; })
    }

    const updateInvoiceItem = (index: number, item: InvoiceItem) => {
        setInvoiceItems(prev => {
            prev[index] = item;
            return [...prev];
        })
    }

    const removeInvoiceItem = (index: number) => {
        setInvoiceItems(prev => {
            prev.splice(index, 1);
            return [...prev];
        })
    }

    const changeInvoiceItemPosition = (oldIndex: number, newIndex: number) => {
        closeItemEditor();
        setInvoiceItems(prev => {
            if (oldIndex < 0 || oldIndex > prev.length - 1 || newIndex < 0 || newIndex > prev.length - 1) {
                return prev;
            }
            prev.splice(newIndex, 0, prev.splice(oldIndex, 1)[0]);
            return [...prev];
        })
    }

    // Discount Item methods:
    const addDiscountItem = (item: Surcharge) => {
        setDiscountItems(prev => { return [...prev, item]; })
    }

    const updateDiscountItem = (index: number, item: Surcharge) => {
        setDiscountItems(prev => {
            prev[index] = item;
            return [...prev];
        })
    }

    const removeDiscountItem = (index: number) => {
        setDiscountItems(prev => {
            prev.splice(index, 1);
            return [...prev];
        })
    }

    const changedDiscountItemPosition = (oldIndex: number, newIndex: number) => {
        closeDiscountItemEditor();
        setDiscountItems(prev => {
            if (oldIndex < 0 || oldIndex > prev.length - 1 || newIndex < 0 || newIndex > prev.length - 1) {
                return prev;
            }
            prev.splice(newIndex, 0, prev.splice(oldIndex, 1)[0]);
            return [...prev];
        })
    }

    const [editedItemIndex, setEditedItemIndex] = useState(-1);
    const [editedDiscountItemIndex, setEditedDiscountItemIndex] = useState(-1);

    const [openNewItemEditor, setOpenNewItemEditor] = useState(false);
    const [openNewDiscountItemEditor, setOpenNewDiscountItemEditor] = useState(false);

    const closeItemEditor = (forceClose?: boolean): boolean => {
        if (!forceClose && editedItemIndex > -1 && !window.confirm('Ein Element wird gerade bearbeitet. Änderungen können verloren gehen, wenn vorher nicht gespeichert. Wollen Sie trotzdem fortfahren?')) {
            return false;
        }
        setEditedItemIndex(-1);
        return true;
    }

    const closeDiscountItemEditor = (forceClose?: boolean): boolean => {
        if (!forceClose && editedDiscountItemIndex > -1 && !window.confirm('Ein Element wird gerade bearbeitet. Änderungen können verloren gehen, wenn vorher nicht gespeichert. Wollen Sie trotzdem fortfahren?')) {
            return false;
        }
        setEditedDiscountItemIndex(-1);
        return true;
    }

    const px = 1;
    const py = 1;


    const invoiceItemList = (
        <React.Fragment>{invoiceItems.map((item, index) => {
            return index === editedItemIndex ?
                <InvoiceItemEditor
                    key={index.toString()}
                    currency={props.currency}
                    onSave={(newItem) => {
                        updateInvoiceItem(index, newItem)
                        closeItemEditor(true)
                    }}
                    loading={props.loadingTemplate}
                    onSaveAsTemplate={(item) => props.onCreateTemplate(item)}
                    onCancel={
                        () => {
                            closeItemEditor(true)
                        }
                    }
                    item={item}
                /> :
                <InvoiceItemPreview
                    key={index.toString()}
                    item={item}
                    currentIndex={index}
                    currency={props.currency}
                    isLast={index === invoiceItems.length - 1}
                    onMove={(newIndex) => {
                        changeInvoiceItemPosition(index, newIndex)
                    }}
                    onOpenEdit={() => {
                        if (!closeItemEditor()) {
                            return;
                        }
                        setEditedItemIndex(index)
                    }}
                    onRemove={() => {
                        if (!closeItemEditor()) {
                            return;
                        }
                        removeInvoiceItem(index)
                    }}
                />
        })
        }</React.Fragment>
    );

    const discountItemsList = (
        <React.Fragment>
            {discountItems.map((discountItem, index) => {
                return index === editedDiscountItemIndex
                    ? <DiscountItemEditor
                        key={index.toString()}
                        invoiceItems={invoiceItems}
                        currency={props.currency}
                        defaultTaxRate={props.defaultTaxRate}
                        onSave={(newDiscountItem) => {
                            updateDiscountItem(index, newDiscountItem);
                            closeDiscountItemEditor(true);
                        }}
                        onCancel={() => {
                            closeDiscountItemEditor(true);
                        }}
                        discountItem={discountItem}
                    />
                    : <DiscountItemPreview
                        key={index.toString()}
                        discountItem={discountItem}
                        invoiceItems={invoiceItems}
                        currentIndex={index}
                        currency={props.currency}
                        isLast={index === discountItems.length - 1}
                        onMove={(newIndex) => {
                            changedDiscountItemPosition(index, newIndex)
                        }}
                        onOpenEdit={() => {
                            if (!closeDiscountItemEditor()) {
                                return;
                            }
                            setEditedDiscountItemIndex(index)
                        }}
                        onRemove={() => {
                            if (!closeDiscountItemEditor()) {
                                return;
                            }
                            removeDiscountItem(index)
                        }}
                    />;
            })}
        </React.Fragment>
    );


    const widthPriceCol = 105;
    const widthAmountAndTaxCol = 60;
    const widthUnitCol = 85;
    const widthPercentageAndPosCol = 105;

    const itemHeader = (
        <React.Fragment>
            <Box display="flex">
                <Box width="60px" px={px} py={py}>
                    <Typography variant="subtitle2">
                        Pos.
                    </Typography>
                </Box>
                <Box flexGrow={1} px={px} py={py}>
                    <Typography variant="subtitle2">
                        Titel und Beschreibung
                    </Typography>
                </Box>
                <Box width={widthAmountAndTaxCol} textAlign="right" px={px} py={py}>
                    <Typography variant="subtitle2">
                        Menge
                    </Typography>
                </Box>
                <Box width={widthUnitCol} px={px} py={py}>
                    <Typography variant="subtitle2">
                        Einheit
                    </Typography>
                </Box>

                <Box width={widthPriceCol} textAlign="right" px={px} py={py}>
                    <Typography variant="subtitle2">
                        Einzelpreis
                    </Typography>
                </Box>
                <Box width={widthPriceCol} textAlign="right" px={px} py={py}>
                    <Typography variant="subtitle2">
                        Netto
                    </Typography>
                </Box>
                <Box width={widthAmountAndTaxCol} textAlign="right" px={px} py={py}>
                    <Typography variant="subtitle2">
                        USt.
                    </Typography>
                </Box>
                <Box width="80px"></Box>
            </Box>
            <Divider />
        </React.Fragment>
    );

    const discountHeader = (
        <React.Fragment>
            <Box display="flex">
                <Box width="60px" px={px} py={py}>
                    <Typography variant="subtitle2">
                        Pos.
                    </Typography>
                </Box>
                <Box flexGrow={1} px={px} py={py}>
                    <Typography variant="subtitle2">
                        Titel und Beschreibung
                    </Typography>
                </Box>
                <Box width={widthPercentageAndPosCol} px={px} py={py}>
                    <Typography variant="subtitle2">
                        Prozentsatz
                    </Typography>
                </Box>
                <Box width={widthPercentageAndPosCol} px={px} py={py}>
                    <Typography variant="subtitle2">
                        Posten
                    </Typography>
                </Box>
                <Box width={widthUnitCol} px={px} py={py}>
                    <Typography variant="subtitle2">
                        Summe
                    </Typography>
                </Box>

                <Box width={widthPriceCol} textAlign="right" px={px} py={py}>
                    <Typography variant="subtitle2">
                        Netto
                    </Typography>
                </Box>
                <Box width={widthAmountAndTaxCol} textAlign="right" px={px} py={py}>
                    <Typography variant="subtitle2">
                        USt.
                    </Typography>
                </Box>
                <Box width="80px"></Box>
            </Box>
            <Divider />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    {itemHeader}
                    {
                        invoiceItems.length < 1 ?
                            <Box py={3} textAlign="center">
                                <Typography variant="body1">
                                    Keine Posten vorhanden
                                </Typography>
                            </Box>
                            : invoiceItemList
                    }
                    <Collapse in={openNewItemEditor}>
                        <InvoiceItemEditor
                            currency={props.currency}
                            defaultTaxRate={props.defaultTaxRate}
                            loading={props.loadingTemplate}
                            templates={props.templates}
                            onSave={(item) => {
                                addInvoiceItem(item);
                                setOpenNewItemEditor(false);
                            }}
                            onRemoveTemplate={(item) => props.onRemoveTemplate(item)}
                            onSaveAsTemplate={(item) => props.onCreateTemplate(item)}
                            onCancel={() => {
                                setOpenNewItemEditor(false);
                            }}
                        />
                    </Collapse>
                </Grid>
                <Grid item xs={12} md={5}>
                    {
                        openNewItemEditor ? null :
                            <Box display="flex">
                                <Box textAlign="center" p={1}>
                                    <Button variant="contained" color="primary"
                                        onClick={() => {
                                            setOpenNewItemEditor(true);
                                        }}
                                    >Posten hinzufügen</Button>
                                </Box>
                            </Box>
                    }

                </Grid>
                <Grid item xs={12}>
                    <div style={{ height: 16 }} />
                    {discountHeader}
                    {
                        discountItems.length < 1 ?
                            <Box py={3} textAlign="center">
                                <Typography variant="body1">
                                    Keine Aufschläge vorhanden
                                </Typography>
                            </Box>
                            : discountItemsList
                    }

                    <Collapse in={openNewDiscountItemEditor}>
                        <DiscountItemEditor
                            invoiceItems={invoiceItems}
                            currency={props.currency}
                            defaultTaxRate={props.defaultTaxRate}
                            templates={props.templates}
                            onSave={(discountItem) => {
                                addDiscountItem(discountItem);
                                setOpenNewDiscountItemEditor(false);
                            }}
                            onCancel={() => {
                                setOpenNewDiscountItemEditor(false);
                            }}
                        />
                    </Collapse>
                </Grid>
                <Grid item xs={12} md={5}>
                    {
                        openNewDiscountItemEditor ? null :
                            <Box display="flex">
                                <Box textAlign="center" p={1}>
                                    <Button variant="contained" color="secondary"
                                        onClick={() => setOpenNewDiscountItemEditor(true)}>
                                        Aufschlag hinzufügen
                                    </Button>
                                </Box>
                            </Box>
                    }

                </Grid>
                <Grid item xs={12}>
                    <TotalsView totals={totals} currency={props.currency} />
                </Grid>
            </Grid>
        </React.Fragment >
    );
};

export default InvoiceItemsEditor;
