import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

interface InvoiceTagSymbolProps {
    tag: string;
    color: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tag: {
            margin: '2px',
            height: '15px',
            width: '15px',
            borderRadius: '50%',
            display: 'inline-block',
            backgroundColor: 'grey'
        }
    })
);

const InvoiceTagSymbol: React.FC<InvoiceTagSymbolProps> = (props) => {
    const classes = useStyles();
    return (
        <Tooltip title={props.tag}>
            <span className={classes.tag} style={{ backgroundColor: props.color }}> </span>
        </Tooltip>
    );
};

export default InvoiceTagSymbol;
