import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import useQuery from '../../../utils/useQuery';

import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { login, LoginProps } from '../../../store/auth/actionsAsync';
import { AuthState } from '../../../store/auth/types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RootState } from '../../../store';
import { appActions } from '../../../store/appSlice';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    loginBox: {
        margin: theme.spacing(2, 1),
    },
    loginBoxContent: {
        padding: theme.spacing(4, 5),
    },
    inputItem: {
        margin: theme.spacing(1, 0),
    }
}));

const Login: React.FC = () => {
    const classes = useStyles();
    const query = useQuery();

    const dispatch = useDispatch();
    const isAuth = useSelector((state: { auth: AuthState }) => state.auth.isAuth);
    const isAuthLoading = useSelector((state: { auth: AuthState }) => state.auth.loading);

    const serverAddress = useSelector((state: RootState) => state.app.apiURL)
    const defaultOrganisation = useSelector((state: RootState) => state.app.organisation)

    const [rememberMe, setRememberMe] = useState(true);

    const orgQuery = query.get('org');
    const [formValues, setFormValues] = useState<LoginProps>({
        instanceId: orgQuery ? orgQuery : defaultOrganisation,
        username: '',
        password: '',
    });

    let inputs = new Map<String, any>();

    useEffect(() => {
        setFormValues(prev => {
            return {
                ...prev,
                instanceId: defaultOrganisation
            }
        })
    }, [defaultOrganisation])

    const toggleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(event.target.checked);
    }

    const handleInputChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setFormValues(prev => {
            return {
                ...prev,
                [key]: value
            }
        });
    }

    const onEnterFocusInput = (event: any, input: String) => {
        if (event.keyCode === 13) {
            inputs.get(input).focus();
        }
    }

    const onSubmit = (event: any) => {
        event.preventDefault();
        dispatch(appActions.setOrganisation(formValues.instanceId))
        dispatch(login(
            formValues, rememberMe
        ));
    }

    return (
        <React.Fragment>
            {isAuth ? <Redirect to="/home" /> : null}
            <Grid container item
                className={classes.root}
                justify="center" direction="column" alignItems="center">
                <Grid item sm={8} md={4}>
                    <Paper className={classes.loginBox}>
                        <form className={classes.loginBoxContent} onSubmit={onSubmit}>
                            <Typography variant="h4">
                                Login
                            </Typography>
                            <TextField
                                className={classes.inputItem}
                                id="org-server"
                                label="Server"
                                fullWidth
                                type="text"
                                autoComplete="server"
                                onKeyDown={(event) => onEnterFocusInput(event, "instanceId")}
                                value={serverAddress}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    localStorage.setItem('apiURL', value)
                                    dispatch(appActions.setApiURL(value))
                                }}
                            />
                            <TextField
                                className={classes.inputItem}
                                id="org-input"
                                label="Organisation"
                                fullWidth
                                type="text"
                                autoComplete="organization"
                                inputRef={(input) => inputs.set("instanceId", input)}
                                onKeyDown={(event) => onEnterFocusInput(event, "username")}
                                value={formValues.instanceId}
                                onChange={handleInputChange('instanceId')}
                            />
                            <TextField
                                className={classes.inputItem}
                                id="username-input"
                                label="Benutzername"
                                fullWidth
                                type="text"
                                autoComplete="username"
                                inputRef={(input) => inputs.set("username", input)}
                                onKeyDown={(event) => onEnterFocusInput(event, "password")}
                                onChange={handleInputChange('username')}
                            />
                            <TextField
                                className={classes.inputItem}
                                id="standard-password-input"
                                label="Passwort"
                                fullWidth
                                type="password"
                                autoComplete="current-password"
                                inputRef={(input) => inputs.set("password", input)}
                                onChange={handleInputChange('password')}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={rememberMe} onChange={toggleRememberMe} value="rememberMe" />
                                }
                                label="Remember Me"
                            />
                            <Button
                                className={classes.inputItem}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={onSubmit}
                                disabled={!formValues.instanceId.length || !formValues.username.length || !formValues.password.length}
                            >
                                Sign In
                            </Button>
                        </form>
                        {isAuthLoading ? <LinearProgress /> : null}
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Login;
