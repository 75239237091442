import {
    DraftInvoiceActionTypes,
    FETCH_DRAFT_INVOICES_START, FETCH_DRAFT_INVOICES_FAILED, RECEIVE_DRAFT_INVOICES,
    ADD_DRAFT_INVOICE, UPDATE_DRAFT_INVOICE, REMOVE_DRAFT_INVOICE, SELECT_DRAFT_INVOICE,
} from "./types";
import { Invoice } from "../../../models/invoice";

export function fetchingDraftInvoicesStart(): DraftInvoiceActionTypes {
    return {
        type: FETCH_DRAFT_INVOICES_START,
    }
}

export function fetchingDraftInvoicesFailed(): DraftInvoiceActionTypes {
    return {
        type: FETCH_DRAFT_INVOICES_FAILED,
    }
}

export function receiveDraftInvoices(invoices: Invoice[]): DraftInvoiceActionTypes {
    return {
        type: RECEIVE_DRAFT_INVOICES,
        invoices: invoices,
    }
}

export function addDraftInvoice(newInvoice: Invoice): DraftInvoiceActionTypes {
    return {
        type: ADD_DRAFT_INVOICE,
        newInvoice: newInvoice,
    }
}

export function updateDraftInvoice(updatedInvoice: Invoice): DraftInvoiceActionTypes {
    return {
        type: UPDATE_DRAFT_INVOICE,
        updatedInvoice: updatedInvoice,
    }
}


export function selectDraftInvoice(invoice: Invoice | undefined): DraftInvoiceActionTypes {
    return {
        type: SELECT_DRAFT_INVOICE,
        invoice: invoice,
    }
}

export function removeDraftInvoice(id: string): DraftInvoiceActionTypes {
    return {
        type: REMOVE_DRAFT_INVOICE,
        id: id,
    }
}

