import axios from 'axios';
import Customer from './models/customer';
import { Invoice, InvoiceTags, InvoiceTag } from './models/invoice';
import Note from './models/note';
import InvoiceItem from './models/invoice/invoiceItem';

const api = axios.create({
    // baseURL: `http://localhost:3231`
    // baseURL: `https://api-v2.inxp.de`
});
export default api;

export const changeBaseURL = (url: string) => {
    api.defaults.baseURL = url;
}

// invoice requests
interface InvoicesResponse {
    invoices: Invoice[];
}
export const createInvoiceReq = (invoice: Invoice) => api.post<Invoice>('/v1/invoices/invoice', invoice);
export const updateInvoiceReq = (invoice: Invoice) => api.put<Invoice>('/v1/invoices/invoice', invoice);
export const deleteInvoiceReq = (req: { invoiceId: string }) => api.post('/v1/invoices/invoice/delete', req);
export const fetchAllInvoicesReq = () => api.get<InvoicesResponse>('/v1/invoices/');
export const fetchInvoicesByStatusReq = (status: string) => api.get<InvoicesResponse>('/v1/invoices/get/by-status/' + status)
export const fetchFinalInvoicesByDateReq = (start: number, end: number) => api.post<InvoicesResponse>('/v1/invoices/get/final-by-date', { start: start, end: end });
export const fetchInvoiceByIDReq = (id: string) => api.get<Invoice>('/v1/invoices/id/' + id);
export const startEditInvoiceReq = (id: string) => api.post<Invoice>('/v1/invoices/invoice/start-edit', { invoiceId: id });
export const finishEditInvoiceReq = (id: string) => api.post('/v1/invoices/invoice/finish-edit', { invoiceId: id });
export const addInvoiceTagReq = (id: string, tag: string) => api.post<Invoice>('/v1/invoices/invoice/add-tag', { invoiceId: id, tag: { tag: tag } });
export const removeInvoiceTagReq = (id: string, tag: string) => api.post<Invoice>('/v1/invoices/invoice/remove-tag', { invoiceId: id, tag: { tag: tag } });
export const setInvoiceStatusReq = (id: string, status: string) => api.put<Invoice>('/v1/invoices/invoice/status', { invoiceId: id, status: status });
export const addInvoiceNoteReq = (id: string, note: Note) => api.post<Invoice>('/v1/invoices/invoice/add-note', { objectId: id, note: note });
export const removeInvoiceNoteReq = (id: string, note: Note) => api.post<Invoice>('/v1/invoices/invoice/remove-note', { objectId: id, note: note });

export const getInvoiceItemTemplatesReq = () => api.get<{
    templates: InvoiceItem[]
}>('/v1/invoices/invoice-item-template');
export const createInvoiceItemTemplateReq = (template: InvoiceItem) => api.post<InvoiceItem>('/v1/invoices/invoice-item-template', { template: template });
export const updateInvoiceItemTemplateReq = (template: InvoiceItem) => api.put<InvoiceItem>('/v1/invoices/invoice-item-template', { template: template });
export const removeInvoiceItemTemplateReq = (template: InvoiceItem) => api.post('/v1/invoices/invoice-item-template/remove', { template: template });

export const getInvoiceTagsReq = () => api.get<InvoiceTags>('/v1/invoices/invoice-tag');
export const createInvoiceTagsReq = (tag: InvoiceTag) => api.post<InvoiceTags>('/v1/invoices/invoice-tag', { tag: tag });
export const removeInvoiceTagsReq = (tag: InvoiceTag) => api.post<InvoiceTags>('/v1/invoices/invoice-tag/delete', { tag: tag });



// customer / addressbook requests
interface CustomersResponse {
    customers: Customer[];
}
export const createCustomerReq = (customer: Customer) => api.post<Customer>('/v1/addressbook/customers/customer', customer);
export const updateCustomerReq = (customer: Customer) => api.put<Customer>('/v1/addressbook/customers/customer', customer);
export const deleteCustomerReq = (req: { customerId: string }) => api.post('/v1/addressbook/customers/customer/delete', req);
export const fetchAllCustomersReq = () => api.get<CustomersResponse>('/v1/addressbook/customers');
export const fetchCustomerByIDReq = (id: string) => api.get<Customer>('/v1/addressbook/customers/id/' + id);
export const startEditCustomerReq = (id: string) => api.post<Customer>('/v1/addressbook/customers/customer/start-edit', { customerId: id });
export const finishEditCustomerReq = (id: string) => api.post('/v1/addressbook/customers/customer/finish-edit', { customerId: id });
export const addCustomerNoteReq = (id: string, note: Note) => api.post<Invoice>('/v1/addressbook/customers/customer/add-note', { objectId: id, note: note })
export const removeCustomerNoteReq = (id: string, note: Note) => api.post<Invoice>('/v1/addressbook/customers/customer/remove-note', { objectId: id, note: note })

// Password change:
export const changePassword = (pwChange: { userId: string, oldPassword: string, newPassword: string }) => api.post('/v1/user/change-password', pwChange)
