import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { LinkRef } from '../../../components/common/Link/Link';
import Button from '@material-ui/core/Button';

const PrivacyPolicy: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <Paper>
                <Box textAlign="left" p={2} m={3}>
                    <h1>Datenschutzerklärung</h1>
                    <h2>Datenschutz</h2>
                    <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p> <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.</p> <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p><p> </p>
                    <h2>Server-Log-Files</h2>
                    <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p> <ul> <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li> <li>Referrer URL</li> <li>Hostname des zugreifenden Rechners</li> <li>Uhrzeit der Serveranfrage</li> </ul> <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p><p> </p>
                    <h2>Kontaktformular</h2>
                    <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p><p> </p>
                    <h2>Google Web Fonts</h2>
                    <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.</p> <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p><p> </p>
                    <h2>SSL-Verschlüsselung</h2>
                    <p>Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p> <p>Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p><p> </p>
                    <h2>Recht auf Auskunft, Löschung, Sperrung</h2>
                    <p>Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p><p> </p>
                    <h2>Widerspruch Werbe-Mails</h2>
                    <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p><p> </p>
                    <h1>Privacy Policy</h1>
                    <h2>Data Protection</h2>
                    <p>The operators of this website take the protection of your personal data very seriously. We treat your personal data confidentially and according to the legal data protection regulations and this Privacy Policy.</p> <p>The use of our website is generally possible without providing personal data. Personal data, such as names, addresses or email addresses, are always provided on a voluntary basis when possible. Those data will not be passed on to third parties without your explicit permission.</p> <p>However, we would like to point out that data transmissions via internet (e.g email communication) cannot be entirely secure and may have security vulnerabilities. A complete protection of personal details against unauthorised access by third parties is not possible.</p><p> </p>
                    <h2>Server-log-files</h2>
                    <p>The provider of these websites automatically collects and stores information in so-called server log files which your browser automatically transmits to us, such as:</p> <ul> <li>browser type/version</li> <li>used operating system</li> <li>referrer URL</li> <li>host name of the accessing computer</li> <li>time of query</li> </ul> <p>Those data cannot be associated with individual persons. We do not merge this data with other sources. We reserve the right to retrospectively check the data if there is any indication of illegal use.</p><p> </p>
                    <h2>Contact form</h2>
                    <p>If you send enquiries to us via the contact form, your data entered into the contact form, including the stated contact information, are stored for the purpose of dealing with your enquiry and in case of additional enquiries. Those data will not be passed on without your permission.</p><p> </p>
                    <h2>Google Web Fonts </h2>
                    <p>For the homogenous display of fonts, this website uses so-called web fonts that are provided by Google. On retrieving a website your browser downloads the necessary web fonts to your browser cache in order to correctly display texts and fonts. If your browser does not support web fonts, your computer uses a standard font.</p> <p>For further information, please see <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> as well as Google's Privacy Policy: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p><p> </p>
                    <h2>SSL Encryption</h2>
                    <p>For security reasons, and to protect the transmission of confidential data, such as enquiries you send to us as the provider, this website uses an SSL encryption. You can recognise an encrypted connection by the change in the address line of the browser from "http://" to "https://" and by the lock-symbol in your browser address line.</p> <p>When the SSL encryption is activated, the data you have transmitted to us cannot be accessed by third parties.</p><p> </p>
                    <h2>Right to Information, Deletion, Blocking</h2>
                    <p>You have the right to be informed about your personal data, their origin and recipients, and the purpose of data processing, as well as the right to correct, block or delete those data at any time and free of charge. For further questions, and questions concerning personal data, feel free to contact us under the address published in the impressum/legal notice.</p><p> </p>
                    <h2>Objection to commercial mails</h2>
                    <p>Providers are obligated to publish contact information in the Legal Notice/Impressum. The use of such contact information by third parties for the purpose of distributing unsolicited advertisements or other commercial information is prohibited. The operators of this website reserve the right to take legal measures against senders of unsolicited commercial information, e.g. spam emails etc.</p><p> </p>

                    <p>Quelle: eRecht24 https://www.e-recht24.de/impressum-generator.html</p>

                    <Button component={LinkRef} to="/start" variant="contained" color="primary"> Zurück zur Startseite </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default PrivacyPolicy;
