import Person from "./person";
import Address from "./address";
import ObjectInfos from "./objectInfos";
import Note from "./note";

export interface ICustomer {
    id: string;
    companyName: string;
    department: string;
    contactPerson: Person;
    postAddress: Address;
    invoiceAddress: Address;
    VAT: string;
    references: {
        customerNumber: string;
        internalReference: string;
    };
    contactPreferences: ContactPreferences;
    paymentPreferences: PaymentPreferences;
    objectInfos: ObjectInfos;
    notes: Note[];
    isEditedByUser: string;
    editingStartedAt: number;
}

export default class Customer implements ICustomer {
    id: string = '';
    companyName: string = '';
    department: string = '';
    contactPerson: Person = { firstName: '', lastName: '', gender: '', title: '' };
    postAddress: Address = { city: '', country: '', street: '', streetExtra: '', postalCode: '' };
    invoiceAddress: Address = { city: '', country: '', street: '', streetExtra: '', postalCode: '' };
    VAT: string = '';
    references = { customerNumber: '', internalReference: '' };
    contactPreferences: ContactPreferences = {
        useEmailForInvoice: false,
        emailForInvoice: '',
        phone: '',
        otherEmails: [],
        websites: []
    };
    paymentPreferences: PaymentPreferences = {
        paymentGoal: 30,
        useDiscount: false,
        discount: { days: 0, value: 0 },
        zeroTaxRateByDefault: false,
    };
    objectInfos: ObjectInfos = { updatedAt: 0, updatedBy: '', createdAt: 0, createdBy: '' };
    isEditedByUser = '';
    editingStartedAt = 0;
    notes = [];

    constructor(obj?: object) {
        if (obj) {
            Object.assign(this, obj);
            if (typeof (this.editingStartedAt) === 'string') {
                this.editingStartedAt = parseInt(this.editingStartedAt);
            }
            if (typeof (this.objectInfos.createdAt) === 'string') {
                this.objectInfos.createdAt = parseInt(this.objectInfos.createdAt);
            }
            if (typeof (this.objectInfos.updatedAt) === 'string') {
                this.objectInfos.updatedAt = parseInt(this.objectInfos.updatedAt);
            }
            return;
        }
    }
}

interface Discount {
    days: number;
    value: number;
}

interface ContactPreferences {
    useEmailForInvoice: boolean;
    emailForInvoice: string;
    phone: string;
    otherEmails: Array<string>;
    websites: Array<string>;
}

interface PaymentPreferences {
    paymentGoal: number;
    useDiscount: boolean;
    discount: Discount;
    zeroTaxRateByDefault: boolean;
}

