
type InvoiceItemUnit = 'all-inclusive' | 'time' | 'percentage' | 'piece';

export default interface InvoiceItem {
    id: string;
    title: string;
    description: string;
    quantity: number;
    unit: InvoiceItemUnit;
    unitPrice: number;
    taxRate: number;
    surchargeReference: string;
}

export const getNettoPriceOfItem = (item: InvoiceItem): number => {
    let q = item.quantity;
    if (item.unit === 'percentage') {
        q = q / 100;
    }
    return item.unitPrice * q;
}

export const getBruttoPriceOfItem = (item: InvoiceItem): number => {
    return getNettoPriceOfItem(item) * (1 + item.taxRate);
}
