import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { initNewEditor } from '../../../../store/invoiceEditor/actions';
// import { InvoiceState } from '../../../../store/invoices/types';

import DraftInvoiceView from '../../../../components/ui/Invoice/InvoiceView/DraftInvoiceView/DraftInvoiceView';
import { useHistory } from 'react-router-dom';
import { startEditInvoice, fetchInvoiceByID, addInvoiceTag, removeInvoiceTag, deleteInvoice, finalizeInvoice } from '../../../../store/invoices/actionsAsync';
import { useReduxDispatch } from '../../../../store/utils';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';

import { LinkRef } from '../../../../components/common/Link/Link';
import { DraftInvoiceState } from '../../../../store/invoices/drafts/types';

import { FinalInvoiceState } from '../../../../store/invoices/finals/types';
import FinalInvoiceView from '../../../../components/ui/Invoice/InvoiceView/FinalInvoiceView/FinalInvoiceView';
import { InvoiceConfigsState } from '../../../../store/invoices/configs/types';

const InvoicePage: React.FC = () => {
    const dispatch = useReduxDispatch();
    const history = useHistory();

    const selectedDraftInvoice = useSelector((state: { invoices: { drafts: DraftInvoiceState } }) => state.invoices.drafts.selected);
    const selectedFinalInvoice = useSelector((state: { invoices: { finals: FinalInvoiceState } }) => state.invoices.finals.selected);
    const isDraftLoading = useSelector((state: { invoices: { drafts: DraftInvoiceState } }) => state.invoices.drafts.loading);
    const isFinalLoading = useSelector((state: { invoices: { finals: FinalInvoiceState } }) => state.invoices.finals.loading);

    const documentTemplates = useSelector((state: { invoices: { configs: InvoiceConfigsState } }) => state.invoices.configs.templates);
    const invoiceTags = useSelector((state: { invoices: { configs: InvoiceConfigsState } }) => state.invoices.configs.tags);


    useEffect(() => {
        if (selectedDraftInvoice) {
            console.log('fetch invoice again');
            dispatch(fetchInvoiceByID(selectedDraftInvoice.id));
        }
        if (selectedFinalInvoice) {
            console.log('fetch invoice again');
            dispatch(fetchInvoiceByID(selectedFinalInvoice.id, true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let content = (<p>Wählen Sie zuerst eine Rechnung aus, um Vorschau zu erhalten.</p>);
    const selectedInvoice = selectedDraftInvoice ? selectedDraftInvoice : selectedFinalInvoice;
    const isLoading = isDraftLoading || isFinalLoading;
    if (selectedInvoice) {
        let templateDef = documentTemplates.find(t => t.id === selectedInvoice.templateReference.id);
        if (!templateDef) {
            templateDef = documentTemplates[0];
        }

        if (selectedInvoice.status === 'draft') {
            content = (<div>
                <DraftInvoiceView
                    invoice={selectedInvoice}
                    isLoading={isLoading}
                    templates={documentTemplates}
                    invoiceTags={invoiceTags}
                    // isLoading - deactivate buttons
                    onOpenEdit={() => {
                        dispatch(startEditInvoice(selectedInvoice.id)).then((success) => {
                            console.log('start editing')
                            if (success) {
                                history.push('/home/invoice-editor');
                            }
                        });

                    }}
                    onFinalize={() => {
                        if (selectedInvoice) {
                            dispatch(finalizeInvoice(selectedInvoice.id)).then(success => {
                                if (success) {
                                    // history.replace('/home');
                                    // dispatch(selectFinalInvoice(selectedInvoice));
                                    // dispatch(selectDraftInvoice(undefined));
                                }
                            })
                        }
                        console.log('todo: finalize invoice');
                    }}
                    onRefresh={() => {
                        if (selectedInvoice) {
                            console.log('fetch invoice again');
                            dispatch(fetchInvoiceByID(selectedInvoice.id));
                        }
                    }}
                    onClone={() => {
                        dispatch(initNewEditor('create', { ...selectedInvoice }));
                        history.push('/home/invoice-editor');
                    }}
                    onDelete={() => {
                        dispatch(deleteInvoice(selectedInvoice.id)).then(success => {
                            if (success) {
                                history.replace('/home');
                            }
                        })
                    }
                    }
                    onAddTag={(tag: string) => {
                        dispatch(addInvoiceTag(selectedInvoice.id, tag)); //.then(success => {})
                    }}
                    onRemoveTag={(tag: string) => {
                        dispatch(removeInvoiceTag(selectedInvoice.id, tag)); // .then(success => {})
                    }}
                />
            </div>)
        } else {
            // final invoice view:
            content = (<div>
                <FinalInvoiceView
                    invoice={selectedInvoice}
                    isLoading={isLoading}
                    templates={documentTemplates}
                    invoiceTags={invoiceTags}
                    // isLoading - deactivate buttons
                    onStorno={() => {
                        console.warn('todo: storno');
                    }}
                    onOpenEdit={() => {
                        dispatch(startEditInvoice(selectedInvoice.id)).then((success) => {
                            console.log('start editing')
                            if (success) {
                                history.push('/home/invoice-editor');
                            }
                        });
                    }}
                    onRefresh={() => {
                        if (selectedInvoice) {
                            console.log('fetch invoice again');
                            dispatch(fetchInvoiceByID(selectedInvoice.id, true));
                        }
                    }}
                    onClone={() => {
                        dispatch(initNewEditor('create', { ...selectedInvoice }));
                        history.push('/home/invoice-editor');
                    }}
                    onAddTag={(tag: string) => {
                        dispatch(addInvoiceTag(selectedInvoice.id, tag, true)); //.then(success => {})
                    }}
                    onRemoveTag={(tag: string) => {
                        dispatch(removeInvoiceTag(selectedInvoice.id, tag, true)); // .then(success => {})
                    }}
                />
            </div>)
        }
    }

    return (
        <div>
            {isLoading ? <LinearProgress /> : null}
            <Box p={2}>
                <Box display="flex" alignItems="center" mb={2}>
                    <Button
                        component={LinkRef}
                        to="/home"
                    >
                        <ArrowBackIcon /> <span> </span> Zurück
                    </Button>
                </Box>
                {content}
            </Box>
        </div>
    );
};

export default InvoicePage;
