import React, { useState } from 'react';
import Customer from '../../../../models/customer';
import Box from '@material-ui/core/Box';
import SearchInput from '../../../common/SearchInput/SearchInput';
import { personToString } from '../../../../utils/modelsToString';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import Fuse from 'fuse.js';

import { getCompanyAndDepartmentString, getAddressStringForTable, getCreatedTime } from '../utils';

interface ContactTableProps {
    customers: Customer[];
    selectedId: string | undefined;
    onSelect: (customer: Customer | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 500,
            //margin: theme.spacing(1),

        },
        notSelectedRow: {
            '&:hover, &:focus': {
                backgroundColor: '#f5f5f5',
            }
        },
        selectedRow: {
            backgroundColor: '#ededed',
        }
    })
);

const rowsPerPageOptions = [10, 25, 50];

const ContactTable: React.FC<ContactTableProps> = (props) => {
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(props.selectedId ? Math.floor(props.customers.findIndex(cust => cust.id === props.selectedId) / rowsPerPage) : 0);

    const options: Fuse.FuseOptions<Customer> = {
        keys: [
            'companyName',
            'department',
            'VAT',
            'references.internalReference',
            'references.customerNumber',
            'contactPerson.firstName',
            'contactPerson.lastName',
            'contactPerson.gender',
            'invoiceAddress.street',
            'invoiceAddress.streetExtra',
            'invoiceAddress.city',
            'invoiceAddress.postalCode',
            'invoiceAddress.country',
            'postAddress.street',
            'postAddress.streetExtra',
            'postAddress.city',
            'postAddress.postalCode',
            'postAddress.country',
        ],
    };
    const fuse = new Fuse(props.customers, options);

    //console.log();
    const filteredCustomers = searchTerm.length > 0 ? fuse.search(searchTerm) as Customer[] :
        props.customers.sort((a, b) => (a.companyName + a.contactPerson.lastName).localeCompare(b.companyName + b.contactPerson.lastName));
    // props.customers; //props.customers.filter(customer => searchCustomer(customer, searchTerm));

    if (filteredCustomers.length > 0 && filteredCustomers.length <= (rowsPerPage * (page))) {
        setPage(0);
    }
    console.log(filteredCustomers);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box textAlign="right">
            <SearchInput
                triggerOnChange={true}
                onSearch={(search) => {
                    setSearchTerm(search);
                }}
            />
            <TableContainer component={"div"}>
                <Table className={classes.table} aria-label="draft invoices" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Firma</TableCell>
                            <TableCell>Kontaktperson</TableCell>
                            <TableCell>Rechnungsadresse</TableCell>
                            <TableCell>Erstellt am</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredCustomers.length < 1 ?
                            <TableRow>
                                <TableCell colSpan={6} align="center">Keine Kontakte gefunden</TableCell>
                            </TableRow> :
                            null
                        }
                        {filteredCustomers
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(customer => (
                                <TableRow key={customer.id} onClick={() => props.onSelect(customer)}
                                    className={customer.id === props.selectedId ? classes.selectedRow : classes.notSelectedRow}
                                >
                                    <TableCell component="th" scope="row">
                                        {getCompanyAndDepartmentString(customer)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {personToString(customer.contactPerson, undefined, true)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {getAddressStringForTable(customer)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {getCreatedTime(customer)}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={filteredCustomers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default ContactTable;
