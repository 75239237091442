import { AppThunk } from "../utils";
import { setError } from "../system/actions";

import API from '../../api';
import { TokenInfos } from '../../models/auth';

import {
    loginStart, loginSuccess, loginFailed, logout as logoutSync
} from './actions';
import { appActions } from "../appSlice";

export interface LoginProps {
    instanceId: string;
    username: string;
    password: string;
}

export function login(
    credentials: LoginProps,
    rememberMe?: boolean
): AppThunk<void> {
    return async dispatch => {
        dispatch(loginStart());
        try {
            // Alter defaults after instance has been created
            const response = await API.post<TokenInfos>('/v1/user/login', credentials);
            const tokenInfos = response.data;

            localStorage.setItem('orgID', credentials.instanceId);
            localStorage.setItem('username', credentials.username);

            applyNewToken(tokenInfos, rememberMe);

            setTimeout(() => {
                dispatch(renewToken(credentials.instanceId, tokenInfos.refreshToken, rememberMe));
            }, (tokenInfos.expiresIn * 0.9) * 1000);

            return dispatch(loginSuccess(
                credentials.instanceId,
                credentials.username
            ));

        } catch (error) {
            console.error(error.response);
            dispatch(loginFailed());
            return dispatch(setError('Anmeldung fehlgeschlagen. Bitte Eingaben überprüfen.'))
        }
    };
}

export function logout(): AppThunk<void> {
    return dispatch => {
        API.defaults.headers.common['Authorization'] = "";

        // clean up local storage:
        localStorage.removeItem('accessToken');
        localStorage.removeItem('username');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('renewTokenAfter');

        // console.warn('todo: clean up local storage and other states')
        dispatch(logoutSync())
        dispatch({ type: 'RESET' });
        return;
    }
}

export const autoLogin = (): AppThunk<void> => {
    return dispatch => {
        const apiURL = localStorage.getItem('apiURL');
        const accessToken = localStorage.getItem('accessToken');
        const orgID = localStorage.getItem('orgID');
        const username = localStorage.getItem('username');
        const refreshToken = localStorage.getItem('refreshToken');
        const renewTokenAfter = localStorage.getItem('renewTokenAfter');
        if (apiURL) {
            dispatch(appActions.setApiURL(apiURL));
        } else {
            dispatch(appActions.setApiURL(process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''));
        }

        if (orgID) {
            dispatch(appActions.setOrganisation(orgID));
        }

        if (!accessToken || !orgID || !username || !refreshToken || !renewTokenAfter) {
            return;
        }

        applyNewToken({
            accessToken: accessToken,
            refreshToken: refreshToken,
            expiresIn: 0, // can be ignored here
        });

        const renewTokenAt = (new Date(renewTokenAfter).getTime() - new Date().getTime());
        if (renewTokenAt <= 0) {
            dispatch(renewToken(orgID, refreshToken, true));
        } else {
            dispatch(loginSuccess(orgID, username));
            setTimeout(() => {
                dispatch(renewToken(orgID, refreshToken, true));
            }, renewTokenAt);
        }
    }
}

const applyNewToken = (tokenInfos: TokenInfos, rememberMe?: boolean) => {
    API.defaults.headers.common['Authorization'] = "Bearer " + tokenInfos.accessToken;
    if (rememberMe) {

        localStorage.setItem('accessToken', tokenInfos.accessToken);
        localStorage.setItem('refreshToken', tokenInfos.refreshToken);
        localStorage.setItem('renewTokenAfter',
            new Date(new Date().getTime() + (tokenInfos.expiresIn * 0.9) * 1000).toISOString()
        );
    }
}

const renewToken = (
    orgID: string,
    refreshToken: string,
    rememberMe?: boolean,
): AppThunk<void> => {
    return async dispatch => {
        try {
            // Alter defaults after instance has been created
            const response = await API.post<TokenInfos>('/v1/user/token/renew', {
                refreshToken: refreshToken,
                orgId: orgID,
            });
            const tokenInfos = response.data;
            applyNewToken(tokenInfos, rememberMe);

            setTimeout(() => {
                dispatch(renewToken(orgID, tokenInfos.refreshToken, rememberMe));
            }, (tokenInfos.expiresIn * 0.9) * 1000);
        } catch (error) {
            console.error(error.response);
            dispatch(logout());
            return dispatch(setError('Sitzung ist abgelaufen. Bitte erneut anmelden.'))
        }
    }
}
