import {
    SystemState,
    SystemActionTypes,
    START_LOADING,
    FINISH_LOADING,
    OPEN_NAVIGATION_DRAWER,
    CLOSE_NAVIGATION_DRAWER,
    SET_ERROR
} from './types'
import { updateObject } from '../utils'


const initialState: SystemState = {
    drawerOpen: false,
    loading: false,
    error: '',
}

export function navigationReducer(
    state = initialState,
    action: SystemActionTypes
): SystemState {
    switch (action.type) {
        case OPEN_NAVIGATION_DRAWER:
            return updateObject(state, { drawerOpen: true })
        case CLOSE_NAVIGATION_DRAWER:
            return updateObject(state, { drawerOpen: false })
        case START_LOADING:
            return updateObject(state, { loading: true })
        case FINISH_LOADING:
            return updateObject(state, { loading: false })
        case SET_ERROR:
            return updateObject(state, { error: action.message })
        default:
            return state
    }
}