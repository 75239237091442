import React, { useState, useEffect, useCallback } from 'react';
import { Invoice, InvoiceTag } from '../../../../../models/invoice';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MenuIcon from '@material-ui/icons/MoreVert';
import PrintIcon from '@material-ui/icons/Print';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppIcon from '@material-ui/icons/GetApp';
import Typography from '@material-ui/core/Typography';
import FlexGrow from '../../../../common/FlexGrow/FlexGrow';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import moment from 'moment';
import { getPDFPreview, downloadPDF, printPDF } from '../../../../../utils/invoiceToPdf';
import Divider from '@material-ui/core/Divider';
import { DocumentTemplate } from '../../../../../models/invoiceTemplates';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TagManager from '../TagManager/TagManager';

interface FinalInvoiceViewProps {
    invoice: Invoice;
    templates: DocumentTemplate[];
    invoiceTags: InvoiceTag[];
    isLoading: boolean;
    onRefresh: () => void;
    onOpenEdit?: () => void;
    onClone: () => void;
    onStorno: () => void;
    onAddTag: (tag: string) => void;
    onRemoveTag: (tag: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(2),
            minWidth: 150,
            textAlign: 'left',
        },
    }),
);

const FinalInvoiceView: React.FC<FinalInvoiceViewProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const classes = useStyles();

    // Document template
    const initSelectedTemplate = props.templates.find(temp => temp.id === props.invoice.templateReference.id);
    const [selectedTemplate, setSelectedTemplate] = useState<DocumentTemplate>(
        initSelectedTemplate ? initSelectedTemplate : props.templates[0]
    )

    const handleTemplateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newId = event.target.value as string;
        const documentTemplate = props.templates.find(t => t.id === newId);
        setSelectedTemplate(prev => {
            if (!documentTemplate) {
                return prev;
            }
            return documentTemplate;
        });
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action: string) => () => {
        setAnchorEl(null);
        switch (action) {
            case 'refresh':
                props.onRefresh();
                break;
            case 'fix':
                if (window.confirm('Sie möchten eine bereits finalisierte Rechnung bearbeiten. Wollen Sie trotzdem fortfahren?')) {
                    if (props.onOpenEdit) {
                        props.onOpenEdit();
                    }
                }
                break;
            case 'clone':
                props.onClone();
                break;
            case 'storno':
                if (window.confirm('Wollen Sie wirklich eine Stornorechnung erstellen?')) {
                    props.onStorno();
                }
                break;
            default:
                break;
        }
    };


    const previewHeader = (
        <Box display="flex">
            <Box flexGrow={1} textAlign="left">
                <Typography variant="h6">
                    Rechnung
                </Typography>
                <Typography variant="caption">
                    Erstellt: {moment.unix(props.invoice.objectInfos.createdAt).format('DD.MM.YYYY HH:mm')} von {props.invoice.objectInfos.createdBy}
                    {props.invoice.objectInfos.updatedAt ?
                        <span>
                            , Zuletzt modifiziert: {moment.unix(props.invoice.objectInfos.updatedAt).format(' DD.MM.YYYY HH:mm')} von {props.invoice.objectInfos.updatedBy}
                        </span> : ''}
                </Typography>
            </Box>
            <Box>
                <IconButton aria-controls="action-menu" aria-haspopup="true" onClick={handleClick}>
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="action-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose('')}
                >
                    <MenuItem onClick={handleClose('refresh')}>Aktualisieren</MenuItem>
                    <MenuItem onClick={handleClose('clone')}>Duplizieren</MenuItem>
                    <MenuItem onClick={handleClose('fix')}>Korrektur</MenuItem>
                    <MenuItem onClick={handleClose('storno')} disabled>
                        <Typography color="error">
                            Stornorechnung erstellen
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    );

    const my = 1;
    const previewHeight = 900;

    const [invoicePDF, setInvoicePDF] = useState('');
    const [mounted, setMounted] = useState(true);

    const updatePDFURL = useCallback((url: string) => {
        if (!mounted) {
            return;
        }
        setInvoicePDF('data:application/pdf;base64,' + url);
    }, [mounted]);

    useEffect(() => {
        return () => setMounted(false);
    }, [])

    useEffect(() => {
        if (!props.invoice || !selectedTemplate.template) { return; }
        getPDFPreview(props.invoice, selectedTemplate.template, updatePDFURL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.invoice, selectedTemplate])

    return (
        <React.Fragment>
            <Box my={2}>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={8} container direction="column">
                        <Paper>
                            <Box p={2} >
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6" align="left">
                                        Vorschau mit Vorlage:
                                    </Typography>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            labelId="select-template-label"
                                            id="select-template"
                                            value={selectedTemplate.id}
                                            onChange={handleTemplateChange}
                                        >
                                            {props.templates.map(
                                                temp => <MenuItem key={temp.id} value={temp.id}>{temp.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <FlexGrow />
                                    <Tooltip title="Drucken">
                                        <IconButton onClick={
                                            () => {
                                                printPDF(props.invoice, selectedTemplate.template);
                                            }
                                        }>
                                            <PrintIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="PDF Herunterladen">
                                        <IconButton onClick={
                                            () => {
                                                downloadPDF(props.invoice, selectedTemplate.template);
                                            }
                                        }>
                                            <GetAppIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Box height={previewHeight}>
                                    {
                                        invoicePDF.length > 0 ? <object aria-label="rechnung" data={invoicePDF}
                                            type="application/pdf" width="100%" height="100%"></object> : <p>loading ...</p>
                                    }
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} container direction="column">
                        <Box flexGrow={1} flexDirection="column">
                            <Box>
                                <Paper>
                                    <Box p={2} >
                                        {previewHeader}
                                        <Divider />
                                        <Box textAlign="left" my={my}>
                                            <Typography variant="subtitle2">
                                                Zustellart:
                                            </Typography>
                                            <Typography variant="body1">
                                                {props.invoice.content.customer.useEmailForInvoice ?
                                                    <Link href={"mailto:" + props.invoice.content.customer.emailForInvoice} color="inherit">
                                                        {props.invoice.content.customer.emailForInvoice}
                                                    </Link>
                                                    : 'per Post'
                                                }
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <TagManager
                                                invoiceTags={props.invoiceTags}
                                                currentTags={props.invoice.tags}
                                                onAddTag={props.onAddTag}
                                                onRemoveTag={props.onRemoveTag}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>
                            <Box mt={1} flexGrow={1}>
                                <Paper>
                                    <Box p={2} >
                                        <Typography variant="h6" align="left">
                                            Notizen
                                        </Typography>
                                        <Divider />
                                        <p>Keine Notizen</p>

                                        <Button disabled>Notiz Hinzufügen</Button>
                                    </Box>
                                </Paper>
                            </Box>
                        </Box>

                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};

export default FinalInvoiceView;
