import {
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    AuthActionTypes
} from "./types";

export function loginStart(): AuthActionTypes {
    console.log('login start called')
    return {
        type: LOGIN_START,
    }
}

export function loginSuccess(
    orgID: string,
    username: string
): AuthActionTypes {

    console.log('login success called')
    return {
        type: LOGIN_SUCCESS,
        orgID: orgID,
        username: username
    }
}

export function loginFailed(): AuthActionTypes {
    console.log('login failed called')
    return {
        type: LOGIN_FAILED,
    }
}

export function logout(): AuthActionTypes {
    console.log('logout called');
    //TODO: remove token
    return {
        type: LOGOUT
    };
}
