import {
    FETCH_CUSTOMERS_START,
    FETCH_CUSTOMERS_FAILED,
    RECEIVE_CUSTOMERS,
    ADD_CUSTOMER,
    UPDATE_CUSTOMER,
    REMOVE_CUSTOMER,
    SELECT_CUSTOMER,
    INIT_CUSTOMER_EDITOR,
    CustomerActionTypes,
    editorMode
} from "./types";
import Customer from "../../models/customer";


export function fetchCustomersStart(): CustomerActionTypes {
    return {
        type: FETCH_CUSTOMERS_START,
    }
}

export function fetchCustomersFailed(): CustomerActionTypes {
    return {
        type: FETCH_CUSTOMERS_FAILED,
    }
}

export function receiveCustomers(customers: Customer[]): CustomerActionTypes {
    return {
        type: RECEIVE_CUSTOMERS,
        customers: customers,
    }
}

export function addCustomer(customer: Customer): CustomerActionTypes {
    return {
        type: ADD_CUSTOMER,
        customer: customer,
    }
}

export function updateCustomer(customer: Customer): CustomerActionTypes {
    return {
        type: UPDATE_CUSTOMER,
        customer: customer,
    }
}

export function removeCustomer(customerID: string): CustomerActionTypes {
    return {
        type: REMOVE_CUSTOMER,
        customerID: customerID,
    }
}

export function selectCustomer(customer: Customer | undefined): CustomerActionTypes {
    return {
        type: SELECT_CUSTOMER,
        customer: customer,
    }
}

export function initCustomerEditor(editMode: editorMode, customer?: Customer): CustomerActionTypes {
    return {
        type: INIT_CUSTOMER_EDITOR,
        mode: editMode,
        customer: customer,
    }
}
