import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Surcharge, { getNettoPriceOfSurchargeItem } from '../../../../../../models/invoice/surcharge';
import DangerBtn from '../../../../../common/DangerBtn/DangerBtn';
import InvoiceItem from '../../../../../../models/invoice/invoiceItem';
import ItemTemplateSelector from '../InvoiceItemEditor/ItemTemplateSelector/ItemTemplateSelector';

interface DiscountItemEditorProps {
    invoiceItems: InvoiceItem[];
    discountItem?: Surcharge;
    currency: string;
    defaultTaxRate?: number;
    templates?: InvoiceItem[];
    onSave: (discountItem: Surcharge) => void;
    onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        saveBtn: {
            marginRight: theme.spacing(1),
        },
        formControl: {
            minWidth: 100,
        },
    })
);

const emptyDiscountItem: Surcharge = {
    id: '',
    title: '',
    description: '',
    itemReferences: [],
    type: 'netto',
    amount: 0.0,
    taxRate: 0.0,
};



const supportedTaxRates = [0.0, 0.05, 0.07, 0.16, 0.19];

const DiscountItemEditor: React.FC<DiscountItemEditorProps> = (props) => {
    const classes = useStyles();
    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'

    const isNewItem = props.discountItem === undefined;

    const [discountItem, setDiscountItem] = useState<Surcharge>(
        props.discountItem ? props.discountItem : {
            ...emptyDiscountItem,
            taxRate: props.defaultTaxRate === undefined ? supportedTaxRates[supportedTaxRates.length - 1] : props.defaultTaxRate
        }
    );

    const isNewDiscountItem = props.discountItem === undefined;

    useEffect(() => {
        if (!props.discountItem) {
            setDiscountItem({
                ...emptyDiscountItem,
                taxRate: props.defaultTaxRate === undefined ? supportedTaxRates[supportedTaxRates.length - 1] : props.defaultTaxRate
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultTaxRate])


    const handleChange = (key: string, dtype?: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
        let value: string | number | Array<string> = event.target.value as string;
        switch (dtype) {
            case 'float':
                value = parseFloat(value);
                value = Number.isNaN(value) ? 0 : value;
        }
        setDiscountItem(prev => {
            return {
                ...prev,
                [key]: value
            };
        })
    };

    const inputLabelType = React.useRef<HTMLLabelElement>(null);
    const inputLabelTax = React.useRef<HTMLLabelElement>(null);

    const [labelWidthType, setLabelWidthType] = React.useState(0);
    const [labelWidthTax, setLabelWidthTax] = React.useState(0);

    React.useEffect(() => {
        setLabelWidthType(inputLabelType.current!.offsetWidth);
        setLabelWidthTax(inputLabelTax.current!.offsetWidth);
    }, []);

    return (
        <Box border="dashed #f59342 2px" borderRadius={5} p={1}>
            <Grid container spacing={1}>
                {
                    isNewItem ? <Grid item xs={12} sm={7}>
                        <ItemTemplateSelector
                            loading={false}
                            currency={props.currency}
                            onRemoveTemplate={() => { }}
                            templates={props.templates ? props.templates : []}
                            onClose={(template) => {
                                if (template) {
                                    template.id = "";
                                    setDiscountItem({
                                        ...emptyDiscountItem,
                                        title: template.title,
                                        description: template.description,
                                        amount: template.quantity,
                                        taxRate: template.taxRate,
                                    })
                                    //setDiscountItem(template);
                                }
                            }}
                        />
                    </Grid> : null}
                <Grid item xs={12} sm={7}>
                    <TextField
                        fullWidth
                        label="Bezeichnung"
                        value={discountItem.title}
                        variant={variant} margin={margin}
                        onChange={handleChange('title')}
                    />
                    <TextField
                        fullWidth
                        label="Detailierte Beschreibung"
                        value={discountItem.description}
                        multiline
                        rows={3}
                        variant={variant} margin={margin}
                        onChange={handleChange('description')}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box display="flex" flexWrap="wrap">
                        <Box width="50%" px={1}>
                            <FormControl
                                variant="outlined"
                                margin={margin}
                                className={classes.formControl}
                                fullWidth
                            >
                                <InputLabel id="item-selector-label">
                                    Posten
                            </InputLabel>
                                <Select
                                    labelId="item-selector-label"
                                    id="item-selector"
                                    multiple
                                    value={discountItem.itemReferences}
                                    labelWidth={labelWidthType}
                                    onChange={handleChange('itemReferences')}
                                >
                                    {props.invoiceItems.map((invoiceItem, index) =>
                                        <MenuItem key={invoiceItem.id} value={invoiceItem.id}>
                                            {(index + 1) + " - " + invoiceItem.title}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box width="50%">
                            <FormControl
                                variant="outlined"
                                margin={margin}
                                className={classes.formControl}
                                fullWidth
                            >
                                <InputLabel ref={inputLabelType} id="type-selector-label">
                                    Referenzgröße
                                </InputLabel>
                                <Select
                                    labelId="type-selector-label"
                                    id="type-selector"
                                    value={discountItem.type}
                                    labelWidth={labelWidthType}
                                    onChange={handleChange('type')}
                                >
                                    <MenuItem value={"netto"}>Netto-Preis</MenuItem>
                                    <MenuItem value={"brutto"}>Brutto-Preis</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box width="50%" px={1}>
                            <TextField
                                fullWidth
                                label="Prozentsatz"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                value={discountItem.amount.toString()}
                                variant={variant} margin={margin}
                                onChange={handleChange('amount', 'float')}
                            />
                        </Box>
                        <Box width="50%">
                            <FormControl
                                variant="outlined"
                                margin={margin}
                                className={classes.formControl}
                                fullWidth
                            >
                                <InputLabel ref={inputLabelTax} id="tax-selector-label">
                                    USt.
                                </InputLabel>
                                <Select
                                    labelId="tax-selector-label"
                                    id="tax-selector"
                                    value={discountItem.taxRate}
                                    labelWidth={labelWidthTax}
                                    onChange={handleChange('taxRate')}
                                >
                                    {
                                        supportedTaxRates.map(tr =>
                                            <MenuItem value={tr} key={tr}>
                                                {tr.toLocaleString('de-DE', { style: 'percent' })}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box minWidth="100%" width="100%" textAlign="right">
                            <Typography variant="h6">
                                Netto: {getNettoPriceOfSurchargeItem(discountItem, props.invoiceItems).toLocaleString('de-DE', { style: 'currency', currency: props.currency })}
                            </Typography>
                        </Box>
                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <Box textAlign="right" display="flex" justifyContent="flex-end">
                        <Box>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.saveBtn}
                                onClick={
                                    () => {
                                        props.onSave(discountItem);
                                        setDiscountItem({
                                            ...emptyDiscountItem,
                                            taxRate: props.defaultTaxRate === undefined ? supportedTaxRates[supportedTaxRates.length - 1] : props.defaultTaxRate
                                        });
                                    }
                                }>
                                <span>{!isNewDiscountItem ? "Speichern" : "Hinzufügen"}</span>
                            </Button>
                            <DangerBtn
                                onClick={
                                    () => props.onCancel()
                                }
                            >
                                <span>Abbrechen</span>
                            </DangerBtn>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DiscountItemEditor;
