import { Invoice } from "../../../models/invoice";

export type InvoiceQueryType = 'last' | 'range';

export interface InvoiceQuery {
    type: InvoiceQueryType;
    lastDays: number;
    start: number;
    end: number;
}

export interface FinalInvoiceState {
    selected?: Invoice;
    loading: boolean;
    items: Invoice[];
    query: InvoiceQuery;
}

// Action Types:
export const FETCH_FINAL_INVOICES_START = "FETCH_FINAL_INVOICES_START";
export const FETCH_FINAL_INVOICES_FAILED = "FETCH_FINAL_INVOICES_FAILED";
export const RECEIVE_FINAL_INVOICES = "RECEIVE_FINAL_INVOICES";
export const ADD_FINAL_INVOICE = 'ADD_FINAL_INVOICE';
export const UPDATE_FINAL_INVOICE = 'UPDATE_FINAL_INVOICE';
export const SELECT_FINAL_INVOICE = 'SELECT_FINAL_INVOICE';

export const SET_FINAL_INVOICE_QUERY = 'SET_FINAL_INVOICE_QUERY';


interface FetchFinalInvoicesStartAction {
    type: typeof FETCH_FINAL_INVOICES_START;
}

interface FetchFinalInvoicesFailedAction {
    type: typeof FETCH_FINAL_INVOICES_FAILED;
}

interface ReceiveFinalInvoicesAction {
    type: typeof RECEIVE_FINAL_INVOICES;
    invoices: Invoice[];
}

interface AddFinalInvoiceAction {
    type: typeof ADD_FINAL_INVOICE;
    newInvoice: Invoice;
}

interface UpdateFinalInvoiceAction {
    type: typeof UPDATE_FINAL_INVOICE;
    updatedInvoice: Invoice;
}


interface SelectFinalInvoiceAction {
    type: typeof SELECT_FINAL_INVOICE;
    invoice: Invoice | undefined;
}

interface SetFinalInvoiceQueryAction {
    type: typeof SET_FINAL_INVOICE_QUERY;
    query: InvoiceQuery;
}


export type FinalInvoiceActionTypes =
    FetchFinalInvoicesStartAction | FetchFinalInvoicesFailedAction | ReceiveFinalInvoicesAction |
    AddFinalInvoiceAction | UpdateFinalInvoiceAction | SelectFinalInvoiceAction | SetFinalInvoiceQueryAction;
