import {
    fetchingDraftInvoicesStart,
    fetchingDraftInvoicesFailed,
    addDraftInvoice,
    receiveDraftInvoices,
    updateDraftInvoice as updateDraftInvoiceSync,
    selectDraftInvoice,
    removeDraftInvoice,
} from './drafts/actions';

import {
    updateFinalInvoice as updateFinalInvoiceSync,
    fetchingFinalInvoicesFailed,
    fetchingFinalInvoicesStart,
    receiveFinalInvoices,
    addFinalInvoice,
    selectFinalInvoice,
} from './finals/actions';

import { AppThunk } from "../utils";
import { setError } from "../system/actions";

import { createInvoiceReq, updateInvoiceReq, deleteInvoiceReq, fetchAllInvoicesReq, fetchInvoiceByIDReq, fetchInvoicesByStatusReq, fetchFinalInvoicesByDateReq, startEditInvoiceReq, finishEditInvoiceReq, addInvoiceTagReq, removeInvoiceTagReq, setInvoiceStatusReq, getInvoiceTagsReq } from '../../api';
import { Invoice } from '../../models/invoice';
import { initNewEditor } from '../invoiceEditor/actions';
import { fetchInvoiceTagsStart, receiveInvoiceTags, fetchInvoiceTagsFailed } from './configs/actions';


export const createInvoice = (invoice: Invoice): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchingDraftInvoicesStart());
        try {
            const response = await createInvoiceReq(invoice);
            const data = response.data;
            // console.log(data);
            dispatch(addDraftInvoice(data));
            dispatch(selectDraftInvoice(data));
            dispatch(selectFinalInvoice(undefined));
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchingFinalInvoicesFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false);
        }
    }
}

export const updateDraftInvoice = (invoice: Invoice): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchingDraftInvoicesStart());
        try {
            const response = await updateInvoiceReq(invoice);
            const data = response.data;
            // console.log(data);
            dispatch(updateDraftInvoiceSync(data));
            // console.warn('todo: dispatch(updateInvoice(data));');
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchingFinalInvoicesFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false);
        }
    }
}

export const deleteInvoice = (id: string): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchingDraftInvoicesStart());
        try {
            const response = await deleteInvoiceReq({ invoiceId: id });
            const data = response.data;
            console.log(data);
            dispatch(removeDraftInvoice(id));
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchingDraftInvoicesFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false);
        }
    }
}

export const fetchAllInvoices = (): AppThunk<void> => {
    return async dispatch => {
        dispatch(fetchingDraftInvoicesStart());
        dispatch(fetchingFinalInvoicesStart());
        try {
            const response = await fetchAllInvoicesReq();
            const data = response.data.invoices.map(inv => new Invoice(inv));
            console.log(data);
            const drafts = data.filter(item => item.status === 'draft');
            const finals = data.filter(item => item.status === 'final');
            console.log(drafts);
            dispatch(receiveDraftInvoices(drafts));
            dispatch(receiveFinalInvoices(finals));
        } catch (error) {
            console.error(error.response);
            dispatch(fetchingDraftInvoicesFailed());
            dispatch(fetchingFinalInvoicesFailed());
            return dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
        }
    }
}

export const fetchInvoiceByID = (id: string, isFinal?: boolean): AppThunk<void> => {
    return async dispatch => {
        dispatch(
            isFinal ? fetchingFinalInvoicesStart() : fetchingDraftInvoicesStart()
        );
        try {
            const response = await fetchInvoiceByIDReq(id);
            const invoice = new Invoice(response.data);
            if (invoice.status === 'final')
                isFinal = true;
            dispatch(
                isFinal ? updateFinalInvoiceSync(invoice) : updateDraftInvoiceSync(invoice)
            );
        } catch (error) {
            console.error(error.response);
            dispatch(
                isFinal ? fetchingFinalInvoicesFailed() : fetchingDraftInvoicesFailed()
            );
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
        }
    }
}

export const fetchDraftInvoices = (): AppThunk<void> => {
    return async dispatch => {
        dispatch(fetchingDraftInvoicesStart());
        try {
            const response = await fetchInvoicesByStatusReq('draft');
            const data = response.data.invoices.map(inv => new Invoice(inv));
            console.log(data);
            dispatch(receiveDraftInvoices(data));
        } catch (error) {
            console.error(error.response);
            dispatch(fetchingDraftInvoicesFailed());
            return dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
        }
    }
}

export const fetchFinalInvoices = (start: number, end: number): AppThunk<void> => {
    return async dispatch => {
        dispatch(fetchingFinalInvoicesStart());
        try {
            const response = await fetchFinalInvoicesByDateReq(start, end);
            if (!response || !response.data || !response.data.invoices) {
                dispatch(receiveFinalInvoices([]));
            } else {
                const data = response.data.invoices.map(inv => new Invoice(inv));
                dispatch(receiveFinalInvoices(data));
            }
        } catch (error) {
            console.error(error);
            console.error(error.response);
            dispatch(fetchingFinalInvoicesFailed());
            return dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
        }
    }
}

/*
function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
*/

export const startEditInvoice = (id: string): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchingDraftInvoicesStart());
        try {
            const response = await startEditInvoiceReq(id);
            const invoice = new Invoice(response.data);
            console.log(invoice);
            dispatch(updateDraftInvoiceSync(invoice));
            dispatch(initNewEditor('edit', invoice));
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchingDraftInvoicesFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false)
        }
    }
}

export const finishEditInvoice = (id: string): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchingDraftInvoicesStart());
        try {
            await finishEditInvoiceReq(id);
            dispatch(fetchingDraftInvoicesFailed());
            console.log('set free after edit');
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchingDraftInvoicesFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false)
        }
    }
}

export const addInvoiceTag = (id: string, tag: string, isFinal?: boolean): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(
            isFinal ? fetchingFinalInvoicesStart() : fetchingDraftInvoicesStart()
        );
        try {
            const response = await addInvoiceTagReq(id, tag);
            const invoice = new Invoice(response.data);
            dispatch(
                isFinal ? updateFinalInvoiceSync(invoice) : updateDraftInvoiceSync(invoice)
            );
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(
                isFinal ? fetchingFinalInvoicesFailed() : fetchingDraftInvoicesFailed()
            );
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false)
        }
    }
}

export const removeInvoiceTag = (id: string, tag: string, isFinal?: boolean): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(
            isFinal ? fetchingFinalInvoicesStart() : fetchingDraftInvoicesStart()
        );
        try {
            const response = await removeInvoiceTagReq(id, tag);
            const invoice = new Invoice(response.data);
            dispatch(
                isFinal ? updateFinalInvoiceSync(invoice) : updateDraftInvoiceSync(invoice)
            );
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(
                isFinal ? fetchingFinalInvoicesFailed() : fetchingDraftInvoicesFailed()
            );
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false)
        }
    }
}


export const finalizeInvoice = (id: string): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchingDraftInvoicesStart());
        dispatch(fetchingFinalInvoicesStart());
        try {
            const resp = await setInvoiceStatusReq(id, 'final');
            const invoice = new Invoice(resp.data);

            dispatch(addFinalInvoice(invoice));
            dispatch(removeDraftInvoice(invoice.id));
            dispatch(selectFinalInvoice(invoice));
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchingDraftInvoicesFailed());
            dispatch(fetchingFinalInvoicesFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false)
        }
    }
}

export const getInvoiceTags = (): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchInvoiceTagsStart());
        try {
            const response = await getInvoiceTagsReq();
            dispatch(receiveInvoiceTags(response.data.tags));
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchInvoiceTagsFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false)
        }
    }
}


// TODO: add note to invoices (drafts and finals)
