import React, { useState } from 'react';
import InvoiceItem, { getNettoPriceOfItem } from '../../../../../../../models/invoice/invoiceItem';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, IconButton, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import DangerBtn from '../../../../../../common/DangerBtn/DangerBtn';
import SearchInput from '../../../../../../common/SearchInput/SearchInput';

import Fuse from 'fuse.js';
import { timeDisplay, unitDisplay } from '../../../../InvoiceTables/utils';

interface ItemTemplateSelectorProps {
    templates: InvoiceItem[];
    loading: boolean;
    currency: string;
    onRemoveTemplate: (item: InvoiceItem) => void;
    onClose: (item?: InvoiceItem) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notSelectedRow: {
            '&:hover, &:focus': {
                backgroundColor: '#f5f5f5',
            }
        },
        selectedRow: {
            backgroundColor: '#ededed',
        }
    })
);

const rowsPerPageOptions = [10, 25, 50];

const ItemTemplateSelector: React.FC<ItemTemplateSelectorProps> = (props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<InvoiceItem | undefined>(undefined);

    const [searchTerm, setSearchTerm] = useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);


    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const options: Fuse.FuseOptions<InvoiceItem> = {
        keys: [
            'title',
            'description',
        ],
        threshold: 0.1,
    };
    const fuse = new Fuse(props.templates, options);

    const filteredTemplates = searchTerm.length > 0 ? fuse.search(searchTerm) as InvoiceItem[] :
        props.templates.sort((a, b) => (a.title).localeCompare(b.title));

    if (filteredTemplates.length > 0 && filteredTemplates.length <= (rowsPerPage * (page))) {
        setPage(0);
    }

    const templateTable = <Box textAlign="right">
        <SearchInput
            triggerOnChange={true}
            onSearch={(search) => {
                setSearchTerm(search);
            }}
        />
        <TableContainer component={"div"}>
            <Table aria-label="template table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Bezeichnung</TableCell>
                        <TableCell>Beschreibung</TableCell>
                        <TableCell>Menge</TableCell>
                        <TableCell>Einzelpreis</TableCell>
                        <TableCell>USt. </TableCell>
                        <TableCell>Netto</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredTemplates.length < 1 ?
                        <TableRow>
                            <TableCell colSpan={7} align="center">Keine Vorlagen gefunden</TableCell>
                        </TableRow> :
                        null
                    }
                    {filteredTemplates
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(template => (
                            <TableRow
                                key={template.id} onClick={() => setSelectedTemplate({ ...template })}
                                className={selectedTemplate && template.id === selectedTemplate.id ? classes.selectedRow : classes.notSelectedRow}
                            >
                                <TableCell>{template.title}</TableCell>
                                <TableCell>{template.description}</TableCell>
                                <TableCell>{template.unit === 'time' ?
                                    timeDisplay(template.quantity) :
                                    template.unit === 'all-inclusive' ? unitDisplay(template.unit) :
                                        template.quantity.toLocaleString('de-DE') + ' ' + unitDisplay(template.unit)
                                }
                                </TableCell>
                                <TableCell>{template.unitPrice.toLocaleString('de-DE', { style: 'currency', currency: props.currency })}</TableCell>
                                <TableCell>{template.taxRate.toLocaleString('de-DE', { style: 'percent' })}</TableCell>
                                <TableCell>{getNettoPriceOfItem(template).toLocaleString('de-DE', { style: 'currency', currency: props.currency })}</TableCell>
                                <TableCell>
                                    <Tooltip title="Vorlage löschen">
                                        <IconButton size="small"
                                            onClick={() => {
                                                if (window.confirm('Wollen Sie die Vorlage wirklich unwiederruflich löschen?')) {
                                                    props.onRemoveTemplate(template);
                                                }
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredTemplates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </Box>;

    const selectorDialog = (
        <Dialog onClose={handleClose} aria-labelledby="item-template-dialog" open={open}
            fullWidth
            maxWidth="lg">
            <DialogTitle id="item-template-dialog-title">Wählen Sie eine Vorlage aus</DialogTitle>
            <DialogContent dividers>
                {templateTable}
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                    disabled={!selectedTemplate}
                    onClick={
                        () => {
                            setOpen(false);
                            props.onClose(selectedTemplate);
                        }
                    }
                >Übernehmen</Button>
                <DangerBtn
                    onClick={handleClose}>Abbrechen</DangerBtn>
            </DialogActions>
        </Dialog>
    );

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={() => setOpen(!open)}
            >
                Vorlage auswählen
        </Button>
            {
                open ? selectorDialog : null
            }
        </React.Fragment>
    );
};

export default ItemTemplateSelector;
