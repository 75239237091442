import { AppThunk } from "../../utils";
import { setError } from "../../system/actions";
import {
    fetchInvoiceItemTemplatesStart,
    fetchInvoiceItemTemplatesFailed,
    receiveInvoiceItemTemplates,
    addInvoiceItemTemplate,
    removeInvoiceItemTemplate as removeInvoiceItemTemplateSync
} from './actions';
import { getInvoiceItemTemplatesReq, createInvoiceItemTemplateReq, removeInvoiceItemTemplateReq } from '../../../api';
import InvoiceItem from "../../../models/invoice/invoiceItem";


export const getInvoiceItemTemplates = (): AppThunk<void> => {
    return async dispatch => {
        dispatch(fetchInvoiceItemTemplatesStart());
        try {
            const response = await getInvoiceItemTemplatesReq();
            // console.log(response);
            const data = response.data.templates;
            dispatch(receiveInvoiceItemTemplates(data));
        } catch (error) {
            console.error(error.response);
            dispatch(fetchInvoiceItemTemplatesFailed());
            return dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
        }
    }
}

export const createInvoiceItemTemplate = (item: InvoiceItem): AppThunk<void> => {
    return async dispatch => {
        dispatch(fetchInvoiceItemTemplatesStart());
        try {
            const response = await createInvoiceItemTemplateReq(item);
            // console.log(response);
            const data = response.data;
            dispatch(addInvoiceItemTemplate(data));
        } catch (error) {
            console.error(error.response);
            dispatch(fetchInvoiceItemTemplatesFailed());
            return dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
        }
    }
}

export const removeInvoiceItemTemplate = (item: InvoiceItem): AppThunk<void> => {
    return async dispatch => {
        dispatch(fetchInvoiceItemTemplatesStart());
        try {
            await removeInvoiceItemTemplateReq(item);
            dispatch(removeInvoiceItemTemplateSync(item));
        } catch (error) {
            console.error(error.response);
            dispatch(fetchInvoiceItemTemplatesFailed());
            return dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
        }
    }
}
