import Customer from "../../models/customer";

export type editorMode = "create" | "edit";

export interface CustomerEditorState {
    mode: editorMode;
    customer?: Customer;
}

// State:
export interface CustomerState {
    loading: boolean;
    customers: Customer[];
    selectedCustomer?: Customer;
    editor: CustomerEditorState;
}

// Action Types:
export const FETCH_CUSTOMERS_START = "FETCH_CUSTOMERS_START";
export const FETCH_CUSTOMERS_FAILED = "FETCH_CUSTOMERS_FAILED";
export const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS";
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const INIT_CUSTOMER_EDITOR = 'INIT_CUSTOMER_EDITOR';

interface FetchCustomersStartAction {
    type: typeof FETCH_CUSTOMERS_START;
}

interface FetchCustomersFailedAction {
    type: typeof FETCH_CUSTOMERS_FAILED;
}

interface ReceiveCustomersAction {
    type: typeof RECEIVE_CUSTOMERS;
    customers: Customer[];
}

interface AddCustomerAction {
    type: typeof ADD_CUSTOMER;
    customer: Customer;
}

interface UpdateCustomerAction {
    type: typeof UPDATE_CUSTOMER;
    customer: Customer;
}

interface RemoveCustomerAction {
    type: typeof REMOVE_CUSTOMER;
    customerID: string;
}

interface SelectCustomerAction {
    type: typeof SELECT_CUSTOMER;
    customer: Customer | undefined;
}

interface InitEditorAction {
    type: typeof INIT_CUSTOMER_EDITOR;
    mode: editorMode;
    customer?: Customer;
}

export type CustomerActionTypes =
    FetchCustomersStartAction | FetchCustomersFailedAction | ReceiveCustomersAction |
    AddCustomerAction | UpdateCustomerAction | RemoveCustomerAction | SelectCustomerAction | InitEditorAction;
