import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { authReducer } from './auth/reducers';
import { navigationReducer } from './system/reducers';
import { invoiceReducers } from './invoices';
import { invoiceEditorReducer } from './invoiceEditor/reducers';
import { customerReducer } from './addressbook/reducers';
import appSlice from './appSlice';
import { combineReducers } from '@reduxjs/toolkit';


const rootReducer = combineReducers({
    app: appSlice,
    auth: authReducer,
    navigation: navigationReducer,
    invoices: invoiceReducers,
    invoiceEditor: invoiceEditorReducer,
    customers: customerReducer,
});


export type RootState = ReturnType<typeof rootReducer>;

export default createStore(rootReducer, applyMiddleware(thunk));
