import { Invoice, InvoiceCustomerInfo, DeliveryDate, PaymentInfos } from "../models/invoice";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { personToString } from './modelsToString';
import moment from 'moment';
import InvoiceItem, { getNettoPriceOfItem, getBruttoPriceOfItem } from "../models/invoice/invoiceItem";
import Person from "../models/person";
import { getBruttoPriceOfSurchageItem, getNettoPriceOfSurchargeItem, getReferencedInvoiceItemIndicesOfSurchargeItem, getReferenceSumOfDiscountItem } from "../models/invoice/surcharge";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const getPDFPreview = (invoice: Invoice, tempDef: any, callback: (pdf: string) => void) => {
    const definitions = invoiceToPdfDefinition(invoice, tempDef);
    pdfMake.createPdf(definitions).getBase64(callback);
}

export const downloadPDF = (invoice: Invoice, tempDef: any, withoutBackgroundLayer?: boolean) => {
    const definitions = invoiceToPdfDefinition(invoice, tempDef, withoutBackgroundLayer);

    let filename = moment.unix(invoice.content.dates.invoiceDate).format('YYYY-MM-DD') +
        (invoice.status === 'final' ? ('_' + invoice.invoiceNumber) : ('_DRAFT_' + invoice.templateReference.name));

    const pdf = pdfMake.createPdf(definitions);
    pdf.download(filename);
}

export const printPDF = (invoice: Invoice, tempDef: any, withoutBackgroundLayer?: boolean) => {
    const definitions = invoiceToPdfDefinition(invoice, tempDef, withoutBackgroundLayer);

    const pdf = pdfMake.createPdf(definitions);
    pdf.print();
}

const resolveReference = (obj: any, keys: string[]): any => {
    const currentKey = keys.shift();
    if (!currentKey) {
        return undefined;
    }

    const currentValue = Array.isArray(obj) ? obj.find((item: any) => item.key === currentKey) : obj[currentKey];
    if (!currentValue) {
        return undefined;
    }

    if (keys.length > 0) {
        return resolveReference(currentValue, keys);
    }

    // TODO: make conversion when different data types
    return currentValue;
}

interface LocalDef {
    code: string;
    dateFormat: string;
}

interface Mapping {
    key: string;
    value: string;
    valueAlt?: string; // alternative form, e.g. in case of plural
}

interface InvoiceDataReference {
    ref: string,
    showRef?: string, // if show single item
    dtype: string,
    mappings: Array<Mapping>;
}

interface InvoiceDataTemplateDef {
    type: string;
    prefix: string;
    suffix: string;
    separator: string;
    style: any;
    showRef?: string; // if show item at all
    items: Array<InvoiceDataReference>;
}

const timeDisplay = (quantity: number): string => {
    const hours = Math.floor(quantity);
    let timeStr = '';
    if (hours > 0) {
        timeStr += hours.toFixed(0) + 'h ';
    }
    timeStr += Math.round((60 * quantity) % 60).toFixed(0) + 'm';
    return timeStr;
}

const getQuantityString = (item: InvoiceItem, mappings: { key: string, value: string, valuePl?: string }[], localeCode: string): string => {
    const valueMap = mappings.find(m => m.key === item.unit);
    if (item.unit === 'all-inclusive') {
        return valueMap ? valueMap.value : '';
    } else if (item.unit === 'time') {
        return timeDisplay(item.quantity);
    }
    if (!valueMap) {
        return item.quantity.toLocaleString(localeCode);
    }
    if (item.quantity > 1 && valueMap.valuePl) {
        return item.quantity.toLocaleString(localeCode) + ' ' + valueMap.valuePl;
    }
    return item.quantity.toLocaleString(localeCode) + ' ' + valueMap.value;
}

const renderStack = (invoice: Invoice, itemDef: { type: string; items: any[], style?: any }, localeDef: LocalDef): any => {
    return {
        stack: itemDef.items.map(col => renderPDFItem(invoice, col, localeDef)),
        ...itemDef.style
    }
}

const renderColumns = (invoice: Invoice, itemDef: { type: string; columns: any[], style?: any }, localeDef: LocalDef): any => {
    return {
        columns: itemDef.columns.map(col => renderPDFItem(invoice, col, localeDef)),
        ...itemDef.style
    }
}

const renderTable = (invoice: Invoice, itemDef: { type: string; table: any, style?: any }, localeDef: LocalDef): any => {
    let body: any[] = [];
    itemDef.table.body.forEach((row: any) => {
        if (row.type === 'singleTableRow') {
            body = [...body, renderPDFItem(invoice, row, localeDef)]
        } else {
            body = [...body, ...renderPDFItem(invoice, row, localeDef)]
        }
    })
    return {
        table: {
            ...itemDef.table,
            body: body,
        },
        ...itemDef.style
    }
}

const renderSingleTableRow = (invoice: Invoice, itemDef: { type: string; items: any[] }, localeDef: LocalDef): any => {
    return itemDef.items.map(item => renderPDFItem(invoice, item, localeDef));
}

const renderArrayToTableRows = (invoice: Invoice, itemDef: { type: string; arrayRef: string; cells: any[] }, localeDef: LocalDef): any => {
    const values = resolveReference(invoice, itemDef.arrayRef.split('.'));
    // console.log(values);

    return values.map((arrItem: any, index: number) => {
        return itemDef.cells.map(cell => {
            return {
                stack: cell.items.map((cellItem: any) => {
                    switch (cellItem.name) {
                        case 'index':
                            return { text: (index + 1).toString(), ...cellItem.style };
                        case 'surchargeIndex':
                            return { text: (index + (invoice.content.items ? invoice.content.items?.length : 0) + 1).toString(), ...cellItem.style };
                        case 'surchargeAmount':
                            return {
                                text: (arrItem.amount / 100.0).toLocaleString(localeDef.code, { style: 'percent' }),
                                ...cellItem.style
                            }
                        case 'surchargeBasePrice':
                            return {
                                text: getReferenceSumOfDiscountItem(arrItem, invoice.content.items ? invoice.content.items : []).toLocaleString(localeDef.code, { style: 'currency', currency: invoice.content.payment.currency }),
                                ...cellItem.style
                            }
                        case 'surchargeItemRefs':
                            return {
                                text: 'Pos. ' + getReferencedInvoiceItemIndicesOfSurchargeItem(arrItem, invoice.content.items ? invoice.content.items : []).toString(),
                                ...cellItem.style
                            }
                        case 'nettoSurchargePrice':
                            return {
                                text: getNettoPriceOfSurchargeItem(arrItem, invoice.content.items ? invoice.content.items : []).toLocaleString(localeDef.code, { style: 'currency', currency: invoice.content.payment.currency }),
                                ...cellItem.style
                            }
                        case 'bruttoSurchargePrice':
                            return {
                                text: getBruttoPriceOfSurchageItem(arrItem, invoice.content.items ? invoice.content.items : []).toLocaleString(localeDef.code, { style: 'currency', currency: invoice.content.payment.currency }),
                                ...cellItem.style
                            }
                        case 'itemQuantity':
                            return {
                                text: getQuantityString(arrItem, cellItem.mappings, localeDef.code),
                                ...cellItem.style
                            }
                        case 'nettoItemPrice':
                            return {
                                text: getNettoPriceOfItem(arrItem).toLocaleString(localeDef.code, { style: 'currency', currency: invoice.content.payment.currency }),
                                ...cellItem.style
                            }
                        case 'bruttoItemPrice':
                            return {
                                text: getBruttoPriceOfItem(arrItem).toLocaleString(localeDef.code, { style: 'currency', currency: invoice.content.payment.currency }),
                                ...cellItem.style
                            }
                        case 'taxRateSum':
                            return {
                                text: getBruttoPriceOfItem(arrItem).toLocaleString(localeDef.code, { style: 'currency', currency: invoice.content.payment.currency }),
                                ...cellItem.style
                            }
                        default:
                            let value: any = arrItem[cellItem.name];
                            if (cellItem.dtype === 'number') {
                                value = value.toLocaleString(localeDef.code);
                            } else if (cellItem.dtype === 'percent') {
                                value = value.toLocaleString(localeDef.code, { style: 'percent' });
                            } else if (cellItem.dtype === 'currency') {
                                value = value.toLocaleString(localeDef.code, { style: 'currency', currency: invoice.content.payment.currency })
                            }

                            return {
                                text: (cellItem.prefix ? cellItem.prefix : '') + value + (cellItem.suffix ? cellItem.suffix : ''),
                                ...cellItem.style
                            }

                    }
                }),
            }

        })
    });
}


const renderInvoiceData = (invoice: Invoice, itemDef: InvoiceDataTemplateDef, localeDef: LocalDef): any => {
    if (itemDef.showRef) {
        const show = resolveReference(invoice, itemDef.showRef.split('.'));
        if (!show) {
            return null;
        }
    }
    let value = itemDef.prefix;
    let first = true;
    itemDef.items.forEach((item: InvoiceDataReference) => {
        const cv = invoiceDataToString(invoice, item, localeDef);
        if (cv) {
            value += (!first ? itemDef.separator : '') + cv;
            first = false;
        }
    })

    value += itemDef.suffix;
    return {
        text: value,
        ...itemDef.style
    }
}

const invoiceDataToString = (invoice: Invoice, reference: InvoiceDataReference, localeDef: LocalDef): string | null => {
    const show = !reference.showRef ? true : resolveReference(invoice, reference.showRef.split('.'));
    const value = resolveReference(invoice, reference.ref.split('.'));
    if (!value || value === undefined) {
        return null;
    }
    switch (reference.dtype) {
        case 'string':
            return show ? value : null;
        case 'date':
            return show ? moment.unix(value).format(localeDef.dateFormat) : null;
        case 'currency':
            return show ? value.toLocaleString(localeDef.code, { style: 'currency', currency: invoice.content.payment.currency }) : null;
        case 'address':
            return show ? generateInvoiceAddressV1(value, reference.mappings) : null;
        case 'name':
            return show ? generateName(value, reference.mappings) : null;
        case 'deliveryDate':
            return show ? generateDeliveryDate(value, reference.mappings, localeDef) : null;
        case 'paymentGoal':
            return show ? generatePaymentGoal(value, reference.mappings, localeDef) : null;
        default:
            console.warn('unknown invoice data reference type: ' + reference.dtype)
            return show ? value : null;
    }
}

const renderPDFItem = (invoice: Invoice, itemDef: any, localeDef: LocalDef, withoutBackgroundLayer?: boolean): any => {
    if (withoutBackgroundLayer && itemDef.layer === 'background') {
        return null;
    }
    switch (itemDef.type) {
        case 'columns':
            return renderColumns(invoice, itemDef, localeDef);
        case 'stack':
            return renderStack(invoice, itemDef, localeDef);
        case 'table':
            return renderTable(invoice, itemDef, localeDef);
        case 'singleTableRow':
            return renderSingleTableRow(invoice, itemDef, localeDef);
        case 'arrayToTableRows':
            return renderArrayToTableRows(invoice, itemDef, localeDef);
        case 'invoiceData':
            return renderInvoiceData(invoice, itemDef, localeDef);
        case 'staticText':
            return itemDef.item;
        case 'image':
            return itemDef.item;
        default:
            console.warn('template item type unknown: ' + itemDef.type);
            return null;
    }
}

export const invoiceToPdfDefinition = (
    invoice: Invoice,
    templateDef: any,
    withoutBackgroundLayer?: boolean,
): any => {

    const bodyItems = templateDef.body.content.map(
        (item: any) => renderPDFItem(invoice, item, templateDef.localisation, withoutBackgroundLayer)
    );

    const backgroundItems = templateDef.background ? templateDef.background.map(
        (item: any) => renderPDFItem(invoice, item, templateDef.localisation, withoutBackgroundLayer)
    ) : undefined;

    const footeritems = templateDef.footer.content.map(
        (item: any) => renderPDFItem(invoice, item, templateDef.localisation, withoutBackgroundLayer)
    )

    const docDefinition = {
        watermark: invoice.status === 'draft' ? { text: 'ENTWURF', opacity: 0.3 } : null,
        pageMargins: templateDef.page.pageMargins,
        pageSize: templateDef.page.pageSize,
        background: backgroundItems,
        content: [
            ...bodyItems,
        ],
        images: { ...templateDef.images },
        styles: {
            ...templateDef.page.styles
        },
        footer: (currentPage: number, pageCount: number) => {
            return [
                {
                    text: templateDef.footer.pageNumber.prefix + currentPage.toString() + templateDef.footer.pageNumber.separator + pageCount.toString() + templateDef.footer.pageNumber.suffix,
                    ...templateDef.footer.pageNumber.style
                },
                ...footeritems
            ];
        },
        pageBreakBefore: function (currentNode: any, followingNodesOnPage: any, nodesOnNextPage: any, previousNodesOnPage: any) {
            if (currentNode.id === 'NoBreak' && currentNode.startPosition.top > 700) {
                return true;
            }
            return false;
        },
    };
    // pdfMake.createPdf(docDefinition).open();
    return docDefinition;
}

const generateName = (person: Person, mappings: Array<Mapping>): string => {
    const genderMap = mappings.map(item => {
        return {
            gender: item.key,
            prefix: item.value,
        }
    })
    return personToString(person, genderMap, false);
}

const generateInvoiceAddressV1 = (customerData: InvoiceCustomerInfo, mappings: Array<Mapping>): string => {
    let addressLines = '';

    if (customerData.companyName && customerData.companyName.length > 0) {
        addressLines += customerData.companyName + '\n';
    }
    if (customerData.department && customerData.department.length > 0) {
        addressLines += customerData.department + '\n';
    }

    const genderMap = mappings.map(item => {
        return {
            gender: item.key,
            prefix: item.value,
        }
    })

    const personLine = personToString(customerData.contactPerson, genderMap, true);
    if (personLine.length > 0) {
        addressLines += personLine + '\n';
    }

    if (customerData.address.street && customerData.address.street.length > 0) {
        addressLines += customerData.address.street + '\n';
    }

    if (customerData.address.streetExtra && customerData.address.streetExtra.length > 0) {
        addressLines += customerData.address.streetExtra + '\n';
    }

    addressLines += customerData.address.postalCode + ' ' + customerData.address.city;
    if (customerData.address.country && customerData.address.country.length > 0 && customerData.address.country !== 'Deutschland') {
        addressLines += '\n' + customerData.address.country;
    }

    return addressLines;
}


const generateDeliveryDate = (value: DeliveryDate, mappings: Array<Mapping>, localeDef: LocalDef): string | null => {
    if (value.type === 'type1' || value.type === 'type2') {
        const v = mappings.find((m: any) => m.key === value.type);
        return v ? v.value : null;
    } else if (value.type === 'type3' || value.type === 'type5') {
        const v = mappings.find((m: any) => m.key === value.type);
        return v ? v.value + ' ' + moment.unix(value.startDate).format(localeDef.dateFormat) : null;
    } else {
        const v = mappings.find((m: any) => m.key === value.type);
        return v ?
            v.value + ' ' + moment.unix(value.startDate).format(localeDef.dateFormat) + ' - ' + moment.unix(value.endDate).format(localeDef.dateFormat)
            : null;
    }
}

const generatePaymentGoal = (value: PaymentInfos, mappings: Array<Mapping>, localeDef: LocalDef): string | null => {
    if (value.cashDiscount.enabled) {
        const t1 = mappings.find(m => m.key === 'cash-dicount-days');
        const t2 = mappings.find(m => m.key === 'cash-dicount-value');
        const t3 = mappings.find(m => m.key === 'cash-dicount-suffix');
        return value.cashDiscount.targetDays.toLocaleString(localeDef.code) + (t1 ? t1.value : '') +
            value.cashDiscount.amount.toLocaleString(localeDef.code, { style: 'percent' }) + (t2 ? t2.value : '') +
            value.paymentGoal.toLocaleString(localeDef.code) + (t3 ? t3.value : '');
    } else {
        const prefix = mappings.find(m => m.key === 'no-cash-discount-prefix');
        const suffix = mappings.find(m => m.key === 'no-cash-discount-suffix');
        return (prefix ? prefix.value : '') + value.paymentGoal.toLocaleString(localeDef.code) + (suffix ? suffix.value : '');
    }
}
