import ObjectInfos from "../objectInfos";
import Note from "../note";
import InvoiceContent from './invoiceContent';
import { DocumentTemplate } from "../invoiceTemplates";


export interface IInvoice {
    id: string;
    invoiceNumber: string;
    status: string;
    content: InvoiceContent;
    templateReference: DocumentTemplate;
    objectInfos: ObjectInfos;
    notes: Array<Note>;
    isEditedByUser: string;
    editingStartedAt: number;
    tags: Array<string>;
}

export class Invoice implements IInvoice {
    id: string = '';
    invoiceNumber: string = '';
    status: string = 'draft';
    content: InvoiceContent = new InvoiceContent();
    templateReference: { id: string; name: string; } = { id: '', name: '' };
    objectInfos: ObjectInfos = { updatedAt: 0, updatedBy: '', createdAt: 0, createdBy: '' };
    notes: Note[] = [];
    isEditedByUser: string = '';
    editingStartedAt: number = 0;
    tags: string[] = [];

    constructor(obj?: object) {
        if (obj) {
            Object.assign(this, obj);
            if (typeof (this.editingStartedAt) === 'string') {
                this.editingStartedAt = parseInt(this.editingStartedAt);
            }
            if (typeof (this.objectInfos.createdAt) === 'string') {
                this.objectInfos.createdAt = parseInt(this.objectInfos.createdAt);
            }
            if (typeof (this.objectInfos.updatedAt) === 'string') {
                this.objectInfos.updatedAt = parseInt(this.objectInfos.updatedAt);
            }
            if (typeof (this.content.dates.invoiceDate) === 'string') {
                this.content.dates.invoiceDate = parseInt(this.content.dates.invoiceDate);
            }
            if (typeof (this.content.dates.deliveryDate.startDate) === 'string') {
                this.content.dates.deliveryDate.startDate = parseInt(this.content.dates.deliveryDate.startDate);
            }
            if (typeof (this.content.dates.deliveryDate.endDate) === 'string') {
                this.content.dates.deliveryDate.endDate = parseInt(this.content.dates.deliveryDate.endDate);
            }

            return;
        }
    }
}


