import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ContactViewer from '../../../components/ui/Addressbook/ContactViewer/ContactViewer';
import ContactTable from '../../../components/ui/Addressbook/ContactTable/ContactTable';
import FlexGrow from '../../../components/common/FlexGrow/FlexGrow';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';

import { useHistory } from 'react-router-dom';
import { CustomerState } from '../../../store/addressbook/types';
import { fetchAllCustomers, startEditCustomer, deleteCustomer } from '../../../store/addressbook/actionAsync';
import { initCustomerEditor, selectCustomer } from '../../../store/addressbook/actions';
import { useReduxDispatch } from '../../../store/utils';
import Customer from '../../../models/customer';

const AddressbookDashboard: React.FC = () => {
    const isLoading = useSelector((state: { customers: CustomerState }) => state.customers.loading);
    const customers = useSelector((state: { customers: CustomerState }) => state.customers.customers);
    const selectedCustomer = useSelector((state: { customers: CustomerState }) => state.customers.selectedCustomer);

    const history = useHistory();
    const dispatch = useReduxDispatch();

    useEffect(() => {
        dispatch(fetchAllCustomers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listAndPreview = (
        <Grid container spacing={1}>
            <Grid item xs={12} md={7}>
                <Paper>
                    <Box p={2}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6">
                                Kontakte
                            </Typography>
                            <FlexGrow />
                            <Tooltip title="Liste aktualisieren">
                                <IconButton aria-label="refresh"
                                    onClick={() => {
                                        dispatch(fetchAllCustomers());
                                    }}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Neuer Kontakt">
                                <IconButton color="primary" aria-label="add"
                                    onClick={() => {
                                        dispatch(initCustomerEditor('create'));
                                        history.push('/home/customer-editor');
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Box>
                            <ContactTable
                                customers={customers}
                                selectedId={selectedCustomer ? selectedCustomer.id : undefined}
                                onSelect={(customer) =>
                                    dispatch(selectCustomer(customer))
                                } />
                        </Box>

                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Paper>
                    <Box p={2}>
                        <ContactViewer
                            customer={selectedCustomer ? selectedCustomer : undefined}
                            onOpenEdit={() => {
                                if (!selectedCustomer) {
                                    console.warn('no customer selected yet - this button should be disabled');
                                    return;
                                }
                                dispatch(startEditCustomer(selectedCustomer.id)).then((success) => {
                                    if (success) {
                                        history.push('/home/customer-editor');
                                    }
                                });
                            }}
                            onDelete={(c: Customer) => {
                                dispatch(deleteCustomer(c.id));
                            }}
                            onClone={(c: Customer) => {
                                console.warn('todo: on clone customer')
                            }}
                            onAddInvoiceForCustomer={(c: Customer) => {
                                console.warn('todo: on add invoice for customer')
                            }}
                            onShowInvoices={(c: Customer) => {
                                console.warn('todo: on show invoices for customer')
                            }}
                        />
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );

    return (
        <div>
            {isLoading ? <LinearProgress /> : null}
            <Box p={2}>
                <Box display="flex" alignItems="center" mb={2}>
                    <Typography variant="h4">
                        Adressbuch
                    </Typography>
                </Box>
                {listAndPreview}
            </Box>
        </div>
    );
};

export default AddressbookDashboard;
