import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SystemState } from '../../../store/system/types';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MuiDrawer from '@material-ui/core/Drawer';

import { closeNavigationDrawer } from '../../../store/system/actions';
import { Box, Divider, IconButton, Tooltip } from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { logout } from '../../../store/auth/actionsAsync';
import ColumnHeader from '../../common/ColumnHeader';

type DrawerSide = 'top' | 'left' | 'bottom' | 'right';

interface DrawerProps {
    side: DrawerSide;
}

interface RouteProps {
    path: string;
    isExact: string;
}

const drawerWidth = 85;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },

        drawerContent: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
        },
        topMenu: {
            flexGrow: 1,
            padding: theme.spacing(1)
        },
        bottomMenu: {
            padding: theme.spacing(1)
        },
        currentRoute: {
            backgroundColor: theme.palette.action.hover,
        }
    }),
);

const Drawer: React.FC<DrawerProps> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const drawerOpen = useSelector((state: { navigation: SystemState }) => state.navigation.drawerOpen)

    const history = useHistory();

    let matchHomeRoute = useRouteMatch<RouteProps>("/home");
    let matchAddressbookRoute = useRouteMatch<RouteProps>("/home/addressbook");
    let matchSettingsRoute = useRouteMatch<RouteProps>("/home/settings");

    const checkRouteMatch = (match: any | null, exact: boolean): boolean => {
        if (!match || (exact && !match.isExact)) {
            return false;
        }
        return true;
    }


    const closeDrawer = () => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        console.log('call close drawer')
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        console.log('close drawer - call action');
        dispatch(closeNavigationDrawer());
    };

    return (
        <MuiDrawer
            classes={{ paper: classes.drawerPaper }}
            className={classes.drawer}
            open={drawerOpen}
            variant="permanent"
            anchor={props.side}
            onClose={closeDrawer()}
        >
            <div className={classes.drawerContent}>
                <ColumnHeader px={3} borderBottom={true}>
                    <img src={process.env.PUBLIC_URL + '/logo192.png'} width="100%" alt="Logo" />
                </ColumnHeader>

                <div className={classes.topMenu}>
                    <Tooltip title="Rechnungen">
                        <IconButton
                            color="primary"
                            className={checkRouteMatch(matchHomeRoute, true) ? classes.currentRoute : ''}
                            onClick={() => {
                                history.replace('/home')
                            }}
                        >
                            <ReceiptIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Kontakte">
                        <IconButton
                            color="primary"
                            className={checkRouteMatch(matchAddressbookRoute, false) ? classes.currentRoute : ''}
                            onClick={() => {
                                history.replace('/home/addressbook')
                            }}
                        >
                            <ContactMailIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.bottomMenu}>
                    <Tooltip title="Settings">
                        <IconButton
                            color="primary"
                            className={checkRouteMatch(matchSettingsRoute, false) ? classes.currentRoute : ''}
                            onClick={() => {
                                history.replace('/home/settings')
                            }}
                        >
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Logout">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                if (window.confirm('Ungespeicherte Änderungen können verloren gehen. Wollen Sie trotzdem ausloggen?')) {
                                    dispatch(logout())
                                }
                            }
                            }
                        >
                            <ExitToApp />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </MuiDrawer >
    );
};
export default Drawer;
