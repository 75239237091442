import {
    InvoiceConfigActionTypes,
    FETCH_INVOICE_CONFIGS_START,
    FETCH_INVOICE_TAGS_START,
    FETCH_INVOICE_TAGS_FAILED,
    RECEIVE_INVOICE_TAGS,
} from "./types";
import { InvoiceTag } from "../../../models/invoice";

export function fetchInvoiceConfigsStart(): InvoiceConfigActionTypes {
    return {
        type: FETCH_INVOICE_CONFIGS_START,
    }
}

export function fetchInvoiceTagsStart(): InvoiceConfigActionTypes {
    return {
        type: FETCH_INVOICE_TAGS_START,
    }
}

export function fetchInvoiceTagsFailed(): InvoiceConfigActionTypes {
    return {
        type: FETCH_INVOICE_TAGS_FAILED,
    }
}

export function receiveInvoiceTags(tags: InvoiceTag[]): InvoiceConfigActionTypes {
    return {
        type: RECEIVE_INVOICE_TAGS,
        tags: tags,
    }
}
