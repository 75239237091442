import React, { useState } from 'react';
import {
    InvoiceComponent
} from '../../../../../../models/invoice';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


interface InvoiceComponentEditorProps {
    component: InvoiceComponent;
    onChange: (changed: InvoiceComponent) => void;
}

const InvoiceComponentEditor: React.FC<InvoiceComponentEditorProps> = (props) => {
    const [inputTouched, setInputTouched] = useState(false);
    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'


    const handleShowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newComp = {
            ...props.component,
            show: event.target.checked,
        }
        props.onChange(newComp);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newComp = {
            ...props.component,
            value: event.target.value,
        }
        props.onChange(newComp);
    };

    const hasError = (): boolean => {
        if (!inputTouched || !props.component.required) {
            return false;
        }
        return props.component.value.length < 1;
    }

    return (
        <React.Fragment>
            <Box display="flex">
                <Tooltip title="Anzeigen auf der Rechnung">
                    <Checkbox
                        checked={props.component.show}
                        onChange={handleShowChange}
                        value="show"
                        inputProps={{ 'aria-label': 'show checkbox' }}
                    />
                </Tooltip>
                <Box flexGrow={1}>
                    <TextField
                        fullWidth
                        variant={variant} margin={margin}
                        label={props.component.label}
                        value={props.component.value}
                        required={props.component.required}
                        onChange={handleInputChange}
                        onBlur={() => setInputTouched(true)}
                        error={hasError()}
                    />
                </Box>
                {
                    props.component.description.length > 0 ?
                        <Box alignSelf="center" ml={1}>
                            <Tooltip title={props.component.description}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        </Box>
                        : null
                }
            </Box>

        </React.Fragment>
    );
};

export default InvoiceComponentEditor;
