import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AddressForm from '../../../CommonForms/AddressForm/AddressForm';
import { InvoiceCustomerInfo } from '../../../../../models/invoice';
import CustomerSearchDialog from './CustomerSearchDialog/CustomerSearchDialog';
import Button from '@material-ui/core/Button';
import PersonForm from '../../../CommonForms/PersonForm/PersonForm';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Address from '../../../../../models/address';
import Person from '../../../../../models/person';
import Customer from '../../../../../models/customer';
import { invoiceCustomerToCustomer, customerToInvoiceCustomer } from '../../../../../models/invoice/customerInvoiceConvert';

interface InvoiceCustomerEditorProps {
    customer: InvoiceCustomerInfo;
    customerList?: Array<Customer>;
    onChange: (customer: InvoiceCustomerInfo, newPaymentInfos: PaymentInfosFromCustomer | undefined) => void;
    onCreateNewCustomer: (customer: Customer) => Promise<string>; // receive new customer id
}

interface PaymentInfosFromCustomer {
    paymentGoal: number;
    cashDiscount: { enabled: boolean; targetDays: number; amount: number };
    useReverseCharge: boolean;
}

const InvoiceCustomerEditor: React.FC<InvoiceCustomerEditorProps> = (props) => {
    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'

    const [customer, setCustomer] = useState<InvoiceCustomerInfo>(props.customer);
    const [newPaymentInfos, setNewPaymentInfos] = useState<PaymentInfosFromCustomer | undefined>(undefined);
    const [defaultAddress, setDefaultAddress] = useState<Address>(props.customer.address);

    useEffect(() => {
        setCustomer(prev => {
            return {
                ...prev,
                ...props.customer
            }
        });
        setDefaultAddress(props.customer.address);
    }, [props.customer]);

    useEffect(() => {
        const timer = setTimeout(() => {
            props.onChange(customer, newPaymentInfos);
            if (newPaymentInfos) {
                setNewPaymentInfos(undefined); // to use if new selection is made to update properties like payment goal
            }
        }, 500);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    const [searchDialogOpen, setSearchDialogOpen] = useState(false);

    const updateCustomer = (key: string, value: string | boolean | number | Address | Person) => {
        setCustomer(prev => {
            const newCustomer = {
                ...prev,
                [key]: value,
            }
            // props.onChange(newCustomer);
            return newCustomer;
        });
    }

    const handleChange = (key: string, checkbox?: boolean, asBoolean?: boolean) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = asBoolean ? event.target.value === 'true' : event.target.value;
        const checked = event.target.checked;
        updateCustomer(key, checkbox ? checked : value);
    }

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <CustomerSearchDialog
                    open={searchDialogOpen}
                    customers={props.customerList ? props.customerList : []}
                    onClose={(customer) => {
                        console.log(customer);
                        if (customer) {
                            const iC = customerToInvoiceCustomer(customer);

                            const newPaymentInfos: PaymentInfosFromCustomer = {
                                paymentGoal: customer.paymentPreferences.paymentGoal,
                                cashDiscount: { enabled: customer.paymentPreferences.useDiscount, targetDays: customer.paymentPreferences.discount.days, amount: customer.paymentPreferences.discount.value },
                                useReverseCharge: customer.paymentPreferences.zeroTaxRateByDefault,
                            }
                            setCustomer(iC);
                            setDefaultAddress(iC.address);
                            setNewPaymentInfos(newPaymentInfos);
                        }
                        setSearchDialogOpen(false);
                    }}
                />
                <Button
                    onClick={() => setSearchDialogOpen(true)}
                    variant="contained"
                >Kunde Suchen</Button>
                <Button
                    onClick={() => {
                        console.log(customer);
                        const newCustomer = invoiceCustomerToCustomer(customer);
                        console.log(newCustomer);
                        props.onCreateNewCustomer(newCustomer).then(id => {
                            setCustomer(prev => {
                                return {
                                    ...prev,
                                    customerId: id,
                                }
                            });
                        });
                    }}
                >Als neuer Kunde Speichern</Button>
            </Grid>
            <Grid item sm={12} md={6}>
                <Typography variant="subtitle2">
                    Kunde
                </Typography>

                <TextField
                    fullWidth
                    variant={variant} margin={margin}
                    label="Firmenname"
                    value={customer.companyName}
                    onChange={handleChange('companyName')}
                ></TextField>
                <TextField
                    fullWidth
                    variant={variant} margin={margin}
                    label="Abteilung"
                    value={customer.department}
                    onChange={handleChange('department')}
                ></TextField>
            </Grid>

            <Grid item sm={12} md={6}>
                <Typography variant="subtitle2">
                    Kontaktperson
                </Typography>

                <PersonForm
                    withoutPaper
                    person={customer.contactPerson}
                    onPersonChange={(p, v) => {
                        updateCustomer('contactPerson', p)
                    }}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <Typography variant="subtitle2">
                    Adresse
                </Typography>
                <AddressForm defaultValue={defaultAddress} withoutPaper onAddressChange={
                    (a, v) => {
                        updateCustomer('address', a);
                    }
                } />

            </Grid>
            <Grid item sm={12} md={6}>
                <Typography variant="subtitle2">
                    Weitere Kundenangaben
                </Typography>
                <Box display="flex">
                    <Tooltip title="Anzeigen auf der Rechnung">
                        <Checkbox
                            checked={customer.showCustomerNumber}
                            onChange={handleChange('showCustomerNumber', true)}
                            value="show"
                            inputProps={{ 'aria-label': 'show checkbox' }}
                        />
                    </Tooltip>
                    <Box flexGrow={1}>
                        <TextField
                            fullWidth
                            variant={variant} margin={margin}
                            label="Kundennummer"
                            value={customer.customerNumber}
                            onChange={handleChange('customerNumber')}
                        />
                    </Box>
                </Box><Box display="flex">
                    <Tooltip title="Anzeigen auf der Rechnung">
                        <Checkbox
                            checked={customer.showInternalReference}
                            onChange={handleChange('showInternalReference', true)}
                            value="show"
                            inputProps={{ 'aria-label': 'show checkbox' }}
                        />
                    </Tooltip>
                    <Box flexGrow={1}>
                        <TextField
                            fullWidth
                            variant={variant} margin={margin}
                            label="Interne Referenz"
                            value={customer.internalReference}
                            onChange={handleChange('internalReference')}
                        />
                    </Box>
                </Box><Box display="flex">
                    <Tooltip title="Anzeigen auf der Rechnung">
                        <Checkbox
                            checked={customer.showVAT}
                            onChange={handleChange('showVAT', true)}
                            value="show"
                            inputProps={{ 'aria-label': 'show checkbox' }}
                        />
                    </Tooltip>
                    <Box flexGrow={1}>
                        <TextField
                            fullWidth
                            variant={variant} margin={margin}
                            label="USt. ID"
                            value={customer.VAT}
                            onChange={handleChange('VAT')}
                        />
                    </Box>
                </Box>

                <Box mt={2}>
                    <Box>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Zustellart der Rechnung</FormLabel>
                            <RadioGroup aria-label="useEmailForInvoice"
                                value={customer.useEmailForInvoice}
                                onChange={handleChange('useEmailForInvoice', false, true)}
                                row>
                                <FormControlLabel value={false} control={<Radio />} label="per Post" />
                                <FormControlLabel value={true} control={<Radio />} label="elektronisch" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {customer.useEmailForInvoice ? <Box flexGrow={1}>
                        <TextField
                            label="Emailadresse für Rechnungen"
                            variant={variant} margin={margin} fullWidth
                            value={customer.emailForInvoice}
                            onChange={handleChange('emailForInvoice')}
                        />
                    </Box> : null}

                </Box>
            </Grid>
        </Grid >
    );
};

export default InvoiceCustomerEditor;
