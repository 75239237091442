import React, { useState, useEffect } from 'react';
import { InvoiceComponent } from '../../../../../models/invoice';
import InvoiceComponentEditor from './InvoiceComponentEditor/InvoiceComponentEditor';

interface InvoiceComponentsEditorProps {
    initialComponents: InvoiceComponent[];
    onChange: (items: InvoiceComponent[]) => void;
}

const InvoiceComponentsEditor: React.FC<InvoiceComponentsEditorProps> = (props) => {
    const [components, setComponents] = useState<InvoiceComponent[]>(props.initialComponents);

    useEffect(() => {
        setComponents([...props.initialComponents]);
    }, [props.initialComponents])

    useEffect(() => {
        const timer = setTimeout(() => {
            props.onChange(components);
        }, 500);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [components]);


    const updateComps = (newComp: InvoiceComponent) => {
        setComponents(
            prev => {
                const ind = prev.findIndex(item => item.key === newComp.key);
                if (ind < 0) {
                    return prev;
                }
                prev[ind] = newComp;
                return [...prev];
            }
        )
    }

    return (
        <React.Fragment>
            {
                components.map((comp) =>
                    <InvoiceComponentEditor key={comp.key}
                        component={comp}
                        onChange={(comp) => {
                            updateComps(comp)
                        }}
                    />
                )
            }
        </React.Fragment>
    );
};

export default InvoiceComponentsEditor;
