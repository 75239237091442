import React, { useState } from 'react';
import {
    Invoice,
    InvoiceComponent,
    DeliveryDate,
    InvoiceCustomerInfo,
    InvoiceTotals,
    PaymentInfos
} from '../../../../models/invoice';
import Customer from '../../../../models/customer';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InvoiceDatesEditor from './InvoiceDatesEditor/InvoiceDatesEditor';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InvoiceCustomerEditor from './InvoiceCustomerEditor/InvoiceCustomerEditor';
import InvoiceItemsEditor from './InvoiceItemsEditor/InvoiceItemsEditor';
import InvoiceComponentsEditor from './InvoiceComponentsEditor/InvoiceComponentsEditor';
import InvoiceItem from '../../../../models/invoice/invoiceItem';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import DangerBtn from '../../../common/DangerBtn/DangerBtn';
import moment from 'moment';
import Surcharge from '../../../../models/invoice/surcharge';


interface InvoiceEditorProps {
    invoice?: Invoice;
    templates: { id: string, name: string }[];
    customers?: Array<Customer>;
    invoiceItemTemplates: InvoiceItem[];
    loadingTemplate: boolean;
    onSave: (invoice: Invoice) => void;
    onCancel: (invoice: Invoice) => void;
    onModified?: () => void;
    onCreateNewCustomer: (customer: Customer) => Promise<string>; // receive new customer id
    onCreateNewInvoiceItemTemplate: (item: InvoiceItem) => void;
    onRemoveInvoiceItemTemplate: (item: InvoiceItem) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            textAlign: 'start',
        },
        titleRow: {
            paddingBottom: theme.spacing(1),
        },
        inputContainer: {
            '& > *': {
                margin: theme.spacing(1),
                minWidth: 100,
            }
        },
    })
);

const emptyInvoice = new Invoice();

const InvoiceEditor: React.FC<InvoiceEditorProps> = (props) => {
    const classes = useStyles();

    const [invoice, setInvoice] = useState<Invoice>(
        props.invoice ? props.invoice : new Invoice(
            {
                ...emptyInvoice,
                content: {
                    ...emptyInvoice.content,
                    dates: {
                        ...emptyInvoice.content.dates,
                        invoiceDate: moment().unix(),
                        deliveryDate: {
                            type: "type2",
                        }
                    },
                    customer: {
                        ...emptyInvoice.content.customer,
                        address: {
                            ...emptyInvoice.content.customer.address,
                            country: 'Deutschland'
                        },
                    }
                },
                templateReference: props.templates.length ? props.templates[0] : { id: '', name: '' },
            }
        )
    )

    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'

    const [initialCustomer, setInitialCustomer] = useState<InvoiceCustomerInfo>(invoice.content.customer);

    const [invoiceStandardComps, setInvoiceStandardComps] = useState<Array<InvoiceComponent>>(
        props.invoice ? [
            ...props.invoice.content.standardInfos
        ] :
            // todo: use defaults from the server
            [
                { id: '1', key: 'TITLE', label: 'Titel', value: 'RECHNUNG', description: 'Titelzeile der Rechnung', required: true, show: true },
                { id: '2', key: 'INTRO', label: 'Einführung', value: 'für unsere Leistungen stellen wir Ihnen in Rechnung', description: 'erste Zeile direkt nach der Anrede', required: false, show: true },
                { id: '3', key: 'EUINV', label: 'Zeile für EU und/oder Drittlandrechnungen', value: '', description: '', required: false, show: false },
                { id: '4', key: 'REGARDS', label: 'Verabschiedung', value: 'Vielen Dank für Ihren Auftrag!', description: 'Als letztes Element auf der Rechnung', required: false, show: true },
            ])

    const [invoiceCustomComps, setInvoiceCustomComps] = useState<Array<InvoiceComponent>>(props.invoice ? [
        ...props.invoice.content.customInfos
    ] :
        // todo: use defaults from the server
        [
            { id: '2', key: 'PROJECTNR', label: 'Projektnummer', value: '', description: 'Als Bestandteil der Rechnungsnummer', required: true, show: true },
            { id: '1', key: 'PROJECT', label: 'Projektbezeichnung', value: '', description: 'Referenz zum Projekt', required: true, show: true },
            // { id: '3', key: 'VENDOR', label: 'Vendor-Nummer', value: '', description: 'Optionales Feld für Infos wie Vendor ID', required: false, show: true },
            { id: '4', key: 'EXTRA_REFERENCE', label: 'Zusatzinfo', value: '', description: 'Optionale Referenzen und Infos', required: false, show: true },
        ])

    const [initialItems, setInitialItems] = useState<InvoiceItem[]>(
        invoice.content.items ? invoice.content.items : []);
    const [initialDiscounts, setInitialDiscounts] = useState<Surcharge[]>(invoice.content.surcharges ? invoice.content.surcharges : []);
    const [initialTotals, setInitialTotals] = useState<InvoiceTotals>(invoice.content.totals);


    const updateInvoiceContent = (key: string, value: any) => {
        if (props.onModified) { props.onModified(); }
        setInvoice(prev => {
            return {
                ...prev,
                content: {
                    ...prev.content,
                    [key]: value
                }
            }
        })
    }

    const updateDates = (newDate: { invoiceDate: number; deliveryDate: DeliveryDate }) => {
        updateInvoiceContent('dates', newDate);
    }

    const updateCustomer = (customer: InvoiceCustomerInfo) => {
        updateInvoiceContent('customer', customer);
    }

    const updateStandardInfos = (components: InvoiceComponent[]) => {
        updateInvoiceContent('standardInfos', components);
    }

    const updateCustomInfos = (components: InvoiceComponent[]) => {
        updateInvoiceContent('customInfos', components);
    }

    const updateTotals = (totals: InvoiceTotals) => {
        updateInvoiceContent('totals', totals);
    }

    const updateItems = (items: InvoiceItem[]) => {
        updateInvoiceContent('items', items);
    }

    const updateSurcharges = (surcharges: Surcharge[]) => {
        updateInvoiceContent('surcharges', surcharges);
    }

    const updatePaymentInfos = (paymentInfos: PaymentInfos) => {
        updateInvoiceContent('payment', paymentInfos);
    }

    const invoiceGeneralForm = () => <Paper className={classes.root}>
        <div className={classes.titleRow}>
            <Typography variant="h6">
                Rechnungsdaten
                </Typography>
        </div>
        <Grid container>
            <Grid item xs={12} md={6} lg={3}>
                <Box display="flex" flexWrap="nowrap" mb={1}>
                    <Box>
                        <Typography variant="subtitle2">
                            Vorlage
                             </Typography>
                        <Box>
                            <FormControl variant="outlined"
                                margin={margin}
                            >
                                <Select
                                    id="select-template"
                                    value={invoice.templateReference.id}
                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                        const value = event.target.value as string;
                                        if (props.onModified) { props.onModified(); }
                                        setInvoice(prev => {
                                            const newTempRef = props.templates.find(temp => temp.id === value);
                                            if (!newTempRef) {
                                                return prev;
                                            }
                                            return {
                                                ...prev,
                                                templateReference: newTempRef,
                                            }
                                        })
                                    }
                                    }
                                >
                                    {props.templates.map(temp => <MenuItem key={temp.id} value={temp.id}>{temp.name}</MenuItem>)}

                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box ml={2}>
                        <Typography variant="subtitle2">
                            Währung
                            </Typography>
                        <Box>
                            <FormControl variant="outlined"
                                margin={margin}
                            >
                                <Select
                                    // labelId="select-type-label"
                                    id="select-currency"
                                    value={invoice.content.payment.currency}
                                    onChange={
                                        (event: React.ChangeEvent<{ value: unknown }>) => {
                                            const value = event.target.value as string;
                                            if (props.onModified) { props.onModified(); }
                                            updatePaymentInfos({
                                                ...invoice.content.payment,
                                                currency: value
                                            })
                                        }
                                    }
                                >
                                    <MenuItem value={"EUR"}>EUR</MenuItem>
                                    <MenuItem value={"USD"}>USD</MenuItem>
                                    <MenuItem value={"GBP"}>GBP</MenuItem>

                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>

            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Typography variant="subtitle2">
                    Rechnungsnummer
                    </Typography>
                <TextField
                    variant={variant} margin={margin}
                    value={invoice.invoiceNumber.length > 0 ? invoice.invoiceNumber : 'Automatisch'}
                    disabled
                ></TextField>

            </Grid>
            <Grid item xs={12} lg={6}>

                <Typography variant="subtitle2">
                    Datum
                    </Typography>
                <InvoiceDatesEditor
                    value={invoice.content.dates}
                    // disableInvoiceDate={invoice.status === 'final'}
                    onChange={
                        (newDate) => { updateDates({ ...newDate }); }
                    }
                />
            </Grid>
        </Grid>
    </Paper>;

    const invoiceCustomerForm = (
        <Paper className={classes.root}>
            <div className={classes.titleRow}>
                <Typography variant="h6">
                    Kundendaten
                </Typography>
            </div>
            <InvoiceCustomerEditor
                customer={initialCustomer}
                customerList={props.customers}
                onChange={(customer, newPaymentInfos) => {
                    updateCustomer(customer)
                    if (newPaymentInfos) {
                        updatePaymentInfos({
                            ...invoice.content.payment,
                            ...newPaymentInfos
                        });
                    }
                }}
                onCreateNewCustomer={props.onCreateNewCustomer}
            />
        </Paper>
    );

    const invoiceStandardForm = (
        <Paper className={classes.root}>
            <div className={classes.titleRow}>
                <Typography variant="h6">
                    Allgemeine Angaben
                </Typography>
            </div>
            <InvoiceComponentsEditor
                initialComponents={invoiceStandardComps}
                onChange={(comps) => {
                    updateStandardInfos(comps);
                }}
            />
        </Paper>
    );

    const invoiceCustomForm = (
        <Paper className={classes.root}>
            <div className={classes.titleRow}>
                <Typography variant="h6">
                    Spezifische Angaben
                </Typography>
            </div>
            <InvoiceComponentsEditor
                initialComponents={invoiceCustomComps}
                onChange={(comps) => {
                    updateCustomInfos(comps);
                }}
            />
        </Paper>
    );

    const itemsEditor = (
        <Paper className={classes.root}>
            <div className={classes.titleRow}>
                <Typography variant="h6">
                    Rechnungsposten
                </Typography>
            </div>
            <InvoiceItemsEditor
                items={initialItems}
                discounts={initialDiscounts}
                totals={initialTotals}
                currency={invoice.content.payment.currency}
                defaultTaxRate={invoice.content.customer.defaultTaxRate}
                templates={props.invoiceItemTemplates}
                loadingTemplate={props.loadingTemplate}
                onCreateTemplate={props.onCreateNewInvoiceItemTemplate}
                onRemoveTemplate={props.onRemoveInvoiceItemTemplate}
                onChange={(items: InvoiceItem[], surcharges: Surcharge[], totals: InvoiceTotals) => {
                    updateItems(items);
                    updateSurcharges(surcharges);
                    updateTotals(totals);
                }}
            />
        </Paper>
    )

    const paymentEditor = (
        <Paper className={classes.root}>
            <div className={classes.titleRow}>
                <Typography variant="h6">
                    Zahlungsziel
                </Typography>
            </div>
            <PaymentDetails
                payment={invoice.content.payment}
                onChange={value => {
                    updatePaymentInfos(value);
                }}
            />

        </Paper>
    )

    const formMargin = 2;
    return (
        <div>
            <Box m={formMargin}>
                {invoiceGeneralForm()}
            </Box>
            <Box m={formMargin}>
                {invoiceCustomerForm}
            </Box>
            <Box m={formMargin}>
                {invoiceStandardForm}
            </Box>
            <Box m={formMargin}>
                {invoiceCustomForm}
            </Box>
            <Box m={formMargin}>
                {itemsEditor}
            </Box>
            <Box m={formMargin}>
                {paymentEditor}
            </Box>

            <Box m={formMargin} textAlign="right" className={classes.inputContainer}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={
                        () => {
                            console.warn('todo: allow only valid forms to be submitted');
                            props.onSave(invoice);
                        }
                    }
                >Speichern und zur Vorschau</Button>
                <DangerBtn
                    onClick={() => {
                        console.warn('todo: check if changed once - to warn against losing changes');
                        props.onCancel(invoice);
                    }}
                >
                    Abbrechen
                </DangerBtn>
            </Box>

        </div >

    );
};

export default InvoiceEditor;
