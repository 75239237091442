import Customer from '../../../models/customer';
import moment from 'moment';
import Address from '../../../models/address';

export const getCompanyAndDepartmentString = (c: Customer): string => {
    return c.companyName + (c.department.length > 0 ? (' - ' + c.department) : '');
}

export const getAddressStringForTable = (c: Customer): string => {
    let address = c.invoiceAddress.postalCode + ' ' + c.invoiceAddress.city;
    address += ', ' + c.invoiceAddress.street;
    address += c.invoiceAddress.streetExtra.length > 0 ? ' ' + c.invoiceAddress.streetExtra : '';
    address += ', ' + c.invoiceAddress.country;
    return address;
}

export const getAddressString = (addr: Address): string => {
    let address = addr.street;
    address += addr.streetExtra.length > 0 ? ' ' + addr.streetExtra : '';
    address += ', ' + addr.postalCode + ' ' + addr.city;
    address += ', ' + addr.country;
    return address;
}

export const getCreatedTime = (c: Customer): string => {
    return moment.unix(c.objectInfos.createdAt).format('DD.MM.YYYY HH:mm');
}
