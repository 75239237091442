import React from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { PaymentInfos } from '../../../../../models/invoice';
import TextField from '@material-ui/core/TextField';

interface PaymentDetailsProps {
    payment: PaymentInfos;
    onChange: (newValue: PaymentInfos) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputContainer: {
            '& > *': {
                margin: theme.spacing(1),
            }
        },
    })
);

const PaymentDetails: React.FC<PaymentDetailsProps> = (props) => {
    const classes = useStyles();
    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'

    const handleInputChange = (key: string, dtype?: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: number | string | boolean;
        value = event.target.value;

        switch (dtype) {
            case 'int':
                value = parseInt(event.target.value);
                value = Number.isNaN(value) ? 0 : value;
                break;
            case 'float':
                value = parseFloat(event.target.value);
                value = Number.isNaN(value) ? 0 : value;
                break;
            case 'boolean':
                value = event.target.value === 'true';
                break;
            default:
                value = event.target.value;
                break;
        }

        console.log(key + value);
        const newPayment = {
            ...props.payment,
            [key]: value
        }
        props.onChange(newPayment);
    }

    const handleCheckboxChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;

        const newPayment = {
            ...props.payment,
            [key]: value
        }
        props.onChange(newPayment);
    }

    const changeDiscount = (key: string, value: number | boolean) => {
        const newPayment = {
            ...props.payment,
            cashDiscount: {
                ...props.payment.cashDiscount,
                [key]: value
            }
        }
        props.onChange(newPayment);
    }

    return (
        <React.Fragment>
            <Box display="flex" p={1} width="100%" flexWrap="wrap">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.payment.showPaymentGoal}
                            onChange={
                                handleCheckboxChange('showPaymentGoal')
                            }
                            value="showPaymentGoal" />
                    }
                    label="Zahlungsziel auf der Rechnung anzeigen"
                />
            </Box>
            <Box display="flex" className={classes.inputContainer} width="100%" flexWrap="wrap">
                <FormControl component="fieldset">
                    <FormLabel component="legend">Zahlungsmodalität</FormLabel>
                    <RadioGroup aria-label="useCashDiscount" name="useCashDiscount"
                        value={props.payment.cashDiscount.enabled}
                        onChange={
                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value === 'true';
                                changeDiscount('enabled', value);
                            }
                        }
                        row>
                        <FormControlLabel value={false} control={<Radio />} label="ohne Abzug" />
                        <FormControlLabel value={true} control={<Radio />} label="mit Skonto" />
                    </RadioGroup>
                </FormControl>

                {
                    props.payment.cashDiscount.enabled ?
                        <React.Fragment>
                            <Box width={200}>
                                <TextField
                                    label="Skonto Tage"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    variant={variant} margin={margin} fullWidth
                                    value={props.payment.cashDiscount.targetDays.toString()}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = parseInt(event.target.value);
                                            changeDiscount('targetDays', Number.isNaN(value) ? 0 : value);
                                        }
                                    }
                                />
                            </Box>
                            <Box width={200}>
                                <TextField
                                    label="Skonto %"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    variant={variant} margin={margin} fullWidth
                                    value={(props.payment.cashDiscount.amount * 100).toString()}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = parseFloat(event.target.value) / 100;
                                            changeDiscount('amount', Number.isNaN(value) ? 0 : value);
                                        }
                                    }
                                />
                            </Box>
                        </React.Fragment>
                        : null
                }
                <Box width={200}>
                    <TextField
                        label="Zahlungsziel (Tage)"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        variant={variant} margin={margin} fullWidth
                        value={props.payment.paymentGoal.toString()}
                        onChange={handleInputChange('paymentGoal', 'int')}
                    />
                </Box>
            </Box>

            <Box display="flex" p={1} width="100%" flexWrap="wrap">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.payment.useReverseCharge}
                            onChange={
                                handleCheckboxChange('useReverseCharge')
                            }
                            value="reverseCharge" />
                    }
                    label="Reverse Charge auf der Rechnung anzeigen"
                />
            </Box>
        </React.Fragment>
    );
};

export default PaymentDetails;
