import React, { useEffect } from 'react';
import {
    HashRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import {
    createMuiTheme,
    StylesProvider,
    MuiThemeProvider,
} from '@material-ui/core';
import {
    deDE
} from '@material-ui/core/locale'
import { blue, brown } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';

import Start from './pages/start/Start';
import Home from './pages/home/Home';
import ErrorBar from './components/ui/ErrorBar/ErrorBar';

import './App.css';

import { useSelector, useDispatch } from 'react-redux';
import { AuthState } from './store/auth/types';
import { SystemState } from './store/system/types';
import { setError } from './store/system/actions';
import { autoLogin } from './store/auth/actionsAsync';

const App: React.FC = () => {
    const isAuth = useSelector((state: { auth: AuthState }) => state.auth.isAuth)
    const errorMsg = useSelector((state: { navigation: SystemState }) => state.navigation.error)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(autoLogin());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log(' auth:' + isAuth);

    const unAuthRoutes = (
        <Switch>
            <Route path="/start" component={Start} />
            <Redirect to="/start" />
        </Switch>
    );
    const authRoutes = (
        <Switch>
            <Route path="/start" component={Start} />
            <Route path="/home" component={Home} />
            <Redirect to="/home"></Redirect>
        </Switch>
    );

    const theme = createMuiTheme({
        palette: {
            primary: blue,
            secondary: brown,
            background: {
                default: '#f2f2f2',
            },
        },
        overrides: {

        }
    }, deDE);

    return (
        <MuiThemeProvider theme={theme}>
            <StylesProvider injectFirst>
                <div className="App">
                    <CssBaseline />
                    <Router basename="/">
                        {isAuth ? authRoutes : unAuthRoutes}
                    </Router>
                    <ErrorBar
                        message={errorMsg}
                        open={errorMsg.length > 0}
                        close={() => { dispatch(setError('')) }}
                    ></ErrorBar>
                </div>
            </StylesProvider>
        </MuiThemeProvider>
    );
}

export default App;
