import Customer from "../../models/customer";
import { AppThunk } from "../utils";
import { fetchCustomersStart, addCustomer, selectCustomer, fetchCustomersFailed, updateCustomer as updateCustomerSync, receiveCustomers, removeCustomer, initCustomerEditor } from "./actions";
import { createCustomerReq, updateCustomerReq, fetchAllCustomersReq, fetchCustomerByIDReq, deleteCustomerReq, addCustomerNoteReq, removeCustomerNoteReq, startEditCustomerReq, finishEditCustomerReq } from "../../api";
import { setError } from "../system/actions";
import Note from "../../models/note";


export const fetchAllCustomers = (): AppThunk<void> => {
    return async dispatch => {
        dispatch(fetchCustomersStart());
        try {
            const response = await fetchAllCustomersReq();
            const data = response.data.customers.map(item => new Customer(item));
            dispatch(receiveCustomers(data));
        } catch (error) {
            console.error(error.response);
            dispatch(fetchCustomersFailed());
            return dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
        }
    }
}

export const fetchInvoiceByID = (id: string): AppThunk<void> => {
    return async dispatch => {
        dispatch(fetchCustomersFailed());
        try {
            const response = await fetchCustomerByIDReq(id);
            const customer = new Customer(response.data);
            dispatch(updateCustomerSync(customer));
        } catch (error) {
            console.error(error.response);
            dispatch(fetchCustomersFailed);
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
        }
    }
}

export const createCustomer = (customer: Customer): AppThunk<Promise<string>> => {
    return async dispatch => {
        dispatch(fetchCustomersStart());
        try {
            const response = await createCustomerReq(customer);
            const data = new Customer(response.data);
            console.log(data);
            dispatch(addCustomer(data));
            dispatch(selectCustomer(data));
            return Promise.resolve(data.id);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchCustomersFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve('');
        }
    }
}

export const updateCustomer = (customer: Customer): AppThunk<Promise<string>> => {
    return async dispatch => {
        dispatch(fetchCustomersStart());
        try {
            const response = await updateCustomerReq(customer);
            const data = new Customer(response.data);
            // console.log(data);
            dispatch(updateCustomerSync(data));
            dispatch(selectCustomer(data));
            return Promise.resolve(data.id);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchCustomersFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve('');
        }
    }
}

export const deleteCustomer = (id: string): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchCustomersStart());
        try {
            await deleteCustomerReq({ customerId: id });
            dispatch(removeCustomer(id));
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchCustomersFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false);
        }
    }
}

export const startEditCustomer = (id: string): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchCustomersStart());
        try {
            const response = await startEditCustomerReq(id);
            const data = new Customer(response.data);
            // console.log(data);
            dispatch(updateCustomerSync(data));
            dispatch(initCustomerEditor('edit', data));
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchCustomersFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false)
        }
    }
}

export const finishEditCustomer = (id: string): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchCustomersStart());
        try {
            await finishEditCustomerReq(id);
            dispatch(fetchCustomersFailed());
            // console.log('set free after edit');
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchCustomersFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false)
        }
    }
}

export const addNote = (id: string, note: Note): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchCustomersStart());
        try {
            const response = await addCustomerNoteReq(id, note);
            const data = new Customer(response.data);
            // console.log(data);
            dispatch(updateCustomerSync(data));
            dispatch(selectCustomer(data));
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchCustomersFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false);
        }
    }
}

export const removeNote = (id: string, note: Note): AppThunk<Promise<boolean>> => {
    return async dispatch => {
        dispatch(fetchCustomersStart());
        try {
            const response = await removeCustomerNoteReq(id, note);
            const data = new Customer(response.data);
            // console.log(data);
            dispatch(updateCustomerSync(data));
            dispatch(selectCustomer(data));
            return Promise.resolve(true);
        } catch (error) {
            console.error(error.response);
            dispatch(fetchCustomersFailed());
            dispatch(setError('Fehler bei der Anfrage - bitte versuchen Sie es erneut.'))
            return Promise.resolve(false);
        }
    }
}


