import React from 'react';
import { Invoice, InvoiceTag } from '../../../../../models/invoice';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getInvoiceElementForTable } from '../utils';
import InvoiceTagSymbol from '../InvoiceTagSymbol/InvoiceTagSymbol';

interface DraftInvoicesTableProps {
    invoices: Invoice[];
    selectedID?: string;
    invoiceTags: InvoiceTag[];
    onSelectInvoice: (invoice: Invoice) => void;
    onOpenInvoice: (invoice: Invoice) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 500,
            //margin: theme.spacing(1),

        },
        notSelectedRow: {
            '&:hover, &:focus': {
                backgroundColor: '#f5f5f5',
            }
        },
        selectedRow: {
            backgroundColor: '#ededed',
        },
    })
);


const DraftInvoicesTable: React.FC<DraftInvoicesTableProps> = (props) => {
    const classes = useStyles();

    const getTagColor = (tagName: string): string => {
        const tagProt = props.invoiceTags.find(tag => tag.tag === tagName);
        if (!tagProt || !tagProt.color) {
            return 'grey';
        }
        return tagProt.color;
    }


    const tableColumns = [
        { valueRef: 'invoiceDate', label: 'Datum', align: 'left' },
        { valueRef: 'customInfos.PROJECT', label: 'Projekt', align: 'left' },
        { valueRef: 'customer', label: 'Kunde', align: 'left' },
        { valueRef: 'nrInvoiceItems', label: 'Posten', align: 'right' },
        { valueRef: 'totals.netto', label: 'Netto', align: 'right' },
        { valueRef: 'updatedAt', label: 'Zuletzt modifiziert', align: 'left' },
        { valueRef: 'tags', label: 'Tags', align: 'left', style: { minWidth: "120px" } },
    ]
    return (
        <React.Fragment>
            <TableContainer component={"div"}>
                <Table className={classes.table} aria-label="draft invoices" size="small">
                    <TableHead>
                        <TableRow>
                            {tableColumns.map((col, index) =>
                                <TableCell key={index.toString()}
                                    style={col.style}
                                >{col.label} </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.invoices.length < 1 ?
                            <TableRow>
                                <TableCell colSpan={tableColumns.length} align="center">Keine Entwürfe gefunden</TableCell>
                            </TableRow> :
                            null
                        }
                        {props.invoices.map(row => (
                            <TableRow key={row.id} onClick={() => props.onSelectInvoice(row)}
                                onDoubleClick={() => props.onOpenInvoice(row)}
                                className={row.id === props.selectedID ? classes.selectedRow : classes.notSelectedRow}
                                style={{ cursor: "pointer" }}
                            >
                                {tableColumns.map((col, index) => {
                                    return <TableCell key={index.toString()}
                                        style={col.style}
                                    >
                                        {col.valueRef === 'tags' ?
                                            <React.Fragment>
                                                {
                                                    row.tags.sort((a, b) => a.localeCompare(b)).map(data => {
                                                        return (
                                                            <InvoiceTagSymbol tag={data} color={getTagColor(data)} key={data} />
                                                        );
                                                    })
                                                }
                                            </React.Fragment>
                                            : getInvoiceElementForTable(row, col.valueRef)}

                                    </TableCell>
                                }
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>

    );
};

export default DraftInvoicesTable;
