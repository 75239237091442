import React, { useState } from 'react';
import {
    Popover, Box, InputLabel, Select, FormControl, MenuItem, Button,
    Typography, IconButton, Chip
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { InvoiceTag } from '../../../../../models/invoice';

interface TagManagerProps {
    invoiceTags: InvoiceTag[];
    currentTags: string[];
    onAddTag: (tag: string) => void;
    onRemoveTag: (tag: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 150,
            textAlign: 'left',
        },
    }),
);

const TagManager: React.FC<TagManagerProps> = (props) => {
    const classes = useStyles();
    const [anchorElTagEdit, setAnchorElTagEdit] = useState<null | HTMLElement>(null);
    const [newTag, setNewTag] = useState('');

    const getChipColor = (tagName: string): string => {
        const tagProt = props.invoiceTags.find(tag => tag.tag === tagName);
        if (!tagProt || !tagProt.color) {
            return 'grey';
        }
        return tagProt.color;
    }

    const onInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value as string;
        setNewTag(value);
    }

    const open = Boolean(anchorElTagEdit);
    const tageditid = open ? 'tagedit-popover' : undefined;

    const tagEditPopover = (
        <Popover
            id={tageditid}
            open={open}
            anchorEl={anchorElTagEdit}
            onClose={() => {
                setNewTag('');
                setAnchorElTagEdit(null);
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Box p={1} display="flex" alignItems="center">
                <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    margin="dense"
                >
                    <InputLabel id="tag-select-label">Bezeichnung</InputLabel>
                    <Select
                        labelId="tag-select-label"
                        id="tag-select"
                        value={newTag}
                        onChange={onInputChange}
                        labelWidth={95}
                    >
                        {
                            props.invoiceTags.map(tag =>
                                <MenuItem key={tag.tag} value={tag.tag}>{tag.tag}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>

                <Button onClick={
                    () => {
                        props.onAddTag(newTag);
                        setNewTag('');
                        setAnchorElTagEdit(null);
                    }
                }>Hinzufügen</Button>
            </Box>
        </Popover>
    )

    return (
        <Box textAlign="left">
            <Typography variant="subtitle2">
                Tags:
            </Typography>
            {props.currentTags && props.currentTags.length > 0 ?
                <React.Fragment>
                    {
                        props.currentTags.map(data => {
                            return (
                                <Chip
                                    key={data}
                                    label={data}
                                    onDelete={() => props.onRemoveTag(data)}
                                    className={classes.chip}
                                    variant="outlined"
                                    style={{ borderColor: getChipColor(data), color: getChipColor(data) }}
                                />
                            );
                        })
                    }
                </React.Fragment>
                : 'keine Tags'
            }
            <IconButton aria-describedby={tageditid}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorElTagEdit(event.currentTarget);
                }}>
                <AddIcon />
            </IconButton>
            {tagEditPopover}
        </Box>
    );
};

export default TagManager;
