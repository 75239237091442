import React, { useState, useEffect } from 'react';

import { Prompt, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { InvoiceEditorState } from '../../../../store/invoiceEditor/types';
import InvoiceEditor from '../../../../components/ui/Invoice/InvoiceEditor/InvoiceEditor';
import { createInvoice, updateDraftInvoice, finishEditInvoice } from '../../../../store/invoices/actionsAsync';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DraftInvoiceState } from '../../../../store/invoices/drafts/types';
import Container from '@material-ui/core/Container';
import { useReduxDispatch } from '../../../../store/utils';
import Customer from '../../../../models/customer';
import { createCustomer } from '../../../../store/addressbook/actionAsync';
import { CustomerState } from '../../../../store/addressbook/types';
import { InvoiceConfigsState } from '../../../../store/invoices/configs/types';
import moment from 'moment';
import { InvoiceItemTemplatesState } from '../../../../store/invoices/itemTemplates/types';
import { createInvoiceItemTemplate, removeInvoiceItemTemplate } from '../../../../store/invoices/itemTemplates/actionsAsync';
import { ObjectID } from 'bson';



const InvoiceEditorPage: React.FC = () => {
    const isLoading = useSelector((state: { invoices: { drafts: DraftInvoiceState } }) => state.invoices.drafts.loading);
    const editorState = useSelector((state: { invoiceEditor: InvoiceEditorState }) => state.invoiceEditor);
    const customers = useSelector((state: { customers: CustomerState }) => state.customers.customers);
    const documentTemplates = useSelector((state: { invoices: { configs: InvoiceConfigsState } }) => state.invoices.configs.templates);
    const invoiceItemTemplateState = useSelector((state: { invoices: { itemTemplates: InvoiceItemTemplatesState } }) => state.invoices.itemTemplates);

    const dispatch = useReduxDispatch();
    const history = useHistory();

    const [protect, setProtect] = useState(false);

    console.log(editorState);
    const invoice = editorState.invoice;
    if (invoice && editorState.mode === 'create') {
        // when cloning old invoice
        invoice.id = '';
        invoice.invoiceNumber = '';
        invoice.status = 'draft';
        invoice.content.dates.invoiceDate = moment().unix();

        invoice.tags = [];
        invoice.notes = [];

        if (invoice.content.customer.defaultTaxRate === 0.16) {
            invoice.content.customer.defaultTaxRate = 0.19
        } else if (invoice.content.customer.defaultTaxRate === 0.05) {
            invoice.content.customer.defaultTaxRate = 0.07
        }

        invoice.content.items = invoice.content.items?.map((item) => {
            const id = new ObjectID();
            item.id = id.toHexString();

            let taxRate = item.taxRate;
            if (taxRate === 0.16) {
                taxRate = 0.19
            } else if (taxRate === 0.05) {
                taxRate = 0.07
            }
            return {
                ...item,
                taxRate: taxRate
            }
        })
    }

    // To protect navigating away when changes happen - but ignore initial change
    useEffect(() => {
        setTimeout(() => {
            setProtect(false);
        }, 2000);
    }, []);

    console.log('todo: handle customer');

    console.log(editorState);
    return (
        <div>
            {isLoading ? <LinearProgress /> : null}
            <Prompt when={protect} message="Nicht gespeicherte Änderungen gehen verloren. Wollen Sie trotzdem fortfahren?" />
            <Container maxWidth="lg" disableGutters={true}>
                <Box p={2} pb={0} mt={3}>
                    <Box display="flex" alignItems="center" mb={2}>
                        <Typography variant="h5">
                            {editorState.mode === 'create' ? 'Rechnung erstellen' : 'Rechnung bearbeiten'}
                        </Typography>
                    </Box>
                </Box>
                <InvoiceEditor
                    invoice={invoice}
                    templates={documentTemplates.map(t => {
                        return {
                            id: t.id,
                            name: t.name,
                        }
                    })}
                    customers={customers}
                    invoiceItemTemplates={invoiceItemTemplateState.templates}
                    loadingTemplate={invoiceItemTemplateState.loading}
                    onCreateNewInvoiceItemTemplate={(item) =>
                        dispatch(createInvoiceItemTemplate(item))
                    }
                    onRemoveInvoiceItemTemplate={(item) => dispatch(removeInvoiceItemTemplate(item))}
                    onModified={() => { setProtect(true) }}
                    onSave={(invoice) => {
                        console.log(invoice);
                        let dpm = null;
                        switch (editorState.mode) {
                            case 'create':
                                dpm = createInvoice(invoice);
                                break;
                            case 'edit':
                                dpm = updateDraftInvoice(invoice);
                                break;
                            default:
                                return;
                        }
                        dispatch(dpm).then(async success => {
                            console.log(success);
                            if (success) {
                                if (editorState.mode === 'edit') {
                                    const finished = await dispatch(finishEditInvoice(invoice.id))
                                    if (!finished) {
                                        return;
                                    }
                                }
                                setProtect(false);
                                history.replace('/home/invoice');
                            }
                        });
                    }}
                    onCancel={(invoice) => {
                        if (editorState.mode === 'edit') {
                            dispatch(finishEditInvoice(invoice.id))
                        }
                        history.goBack();
                    }}
                    onCreateNewCustomer={async (customer: Customer) => {
                        return await dispatch(createCustomer(customer)).then();
                    }}
                />
            </Container>
            {isLoading ? <LinearProgress /> : null}
        </div>

    );
};

export default InvoiceEditorPage;
