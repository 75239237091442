import React, { useState } from 'react';
import Customer from '../../../../models/customer';
import AddressForm from '../../CommonForms/AddressForm/AddressForm';
import PersonForm from '../../CommonForms/PersonForm/PersonForm';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ManageEmailList from './ManageEmailList/ManageEmailList';
import ManageWebsiteList from './ManageWebsiteList/ManageWebsiteList';
import DangerBtn from '../../../common/DangerBtn/DangerBtn';
import Address from '../../../../models/address';

interface ContactEditorProps {
    customer?: Customer;
    onModified?: () => void;
    onSave: (customer: Customer) => void;
    onCancel: (customer: Customer) => void;
}

type inputDTypes = 'string' | 'float' | 'int' | 'boolean';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            textAlign: 'start',
        },
        titleRow: {
            paddingBottom: theme.spacing(1),
        },
        inputContainer: {
            '& > *': {
                margin: theme.spacing(1),
                minWidth: 100,
            }
        },
        formControl: {
            minWidth: 100,
            //margin: theme.spacing(1),
        },
    })
);

const ContactEditor: React.FC<ContactEditorProps> = (props) => {
    const classes = useStyles();

    const [defaultPostAddress,] = useState<Address>(props.customer ? props.customer.postAddress : {
        street: '', streetExtra: '', postalCode: '', city: '', country: 'Deutschland'
    });
    const [defaultInvoiceAddress,] = useState<Address>(props.customer ? props.customer.invoiceAddress : {
        street: '', streetExtra: '', postalCode: '', city: '', country: 'Deutschland'
    });

    const [customer, setCustomer] = useState<Customer>(
        props.customer ? props.customer : new Customer({
            postAddress: defaultPostAddress,
            invoiceAddress: defaultInvoiceAddress,
        })
    )

    const [edited, setEdited] = useState(false);
    const [sameInvoiceAddress, setSameInvoiceAddress] = useState(true);


    if (edited) {
        if (props.onModified) {
            props.onModified();
        }
    }


    const handleInputChange = (key: string, dtype?: inputDTypes) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: number | string | boolean;
        switch (dtype) {
            case 'int':
                value = parseInt(event.target.value);
                value = Number.isNaN(value) ? 0 : value;
                break;
            case 'float':
                value = parseFloat(event.target.value);
                value = Number.isNaN(value) ? 0 : value;
                break;
            case 'boolean':
                value = event.target.value === 'true';
                break;
            default:
                value = event.target.value;
                break;
        }

        setCustomer((prev: any) => {
            setEdited(true);
            const keys = key.split('.');
            if (keys.length === 2) {
                const newProp = {
                    ...prev[keys[0]],
                }
                newProp[keys[1]] = value;
                return {
                    ...prev,
                    [keys[0]]: newProp,
                };
            }
            return {
                ...prev,
                [key]: value,
            };
        });
    }

    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'

    const customerDataForm = (
        <Paper className={classes.root}>
            <div className={classes.titleRow}>
                <Typography variant="h6">
                    Kunde
                </Typography>
            </div>
            <Typography variant="subtitle2">
                Firma
            </Typography>
            <Box className={classes.inputContainer} width="100%" display="flex" flexWrap="wrap">
                <Box flexGrow={1}>
                    <TextField
                        label="Firmenname"
                        variant={variant} margin={margin} fullWidth
                        value={customer.companyName}
                        onChange={handleInputChange('companyName')}
                    />
                </Box>
                <Box flexGrow={1}>
                    <TextField
                        label="Abteilung"
                        variant={variant} margin={margin} fullWidth
                        value={customer.department}
                        onChange={handleInputChange('department')}
                    />
                </Box>
            </Box>

            <Typography variant="subtitle2">
                Kontaktperson
            </Typography>
            <Box width="100%" display="flex" flexWrap="wrap">

                <PersonForm
                    withoutPaper
                    person={customer.contactPerson}
                    onPersonChange={(p, v) => {
                        setCustomer(prev => {
                            setEdited(true);
                            return {
                                ...prev,
                                contactPerson: { ...prev.contactPerson, ...p },
                            }
                        });
                    }}
                />
            </Box>

            <Typography variant="subtitle2">
                Infos
            </Typography>
            <Box className={classes.inputContainer} width="100%" display="flex" flexWrap="wrap">
                <Box flexGrow={1}>
                    <TextField
                        label="USt.-ID"
                        variant={variant} margin={margin} fullWidth
                        value={customer.VAT}
                        onChange={handleInputChange('VAT')}
                    />
                </Box>
                <Box flexGrow={1}>
                    <TextField
                        label="Kundennummer"
                        variant={variant} margin={margin} fullWidth
                        value={customer.references.customerNumber}
                        onChange={handleInputChange('references.customerNumber')}
                    />
                </Box>
                <Box flexGrow={1}>
                    <TextField
                        label="Interne Referenz"
                        variant={variant} margin={margin} fullWidth
                        value={customer.references.internalReference}
                        onChange={handleInputChange('references.internalReference')}
                    />
                </Box>
            </Box>
        </Paper>
    );

    const contactDataForm = (
        <Paper className={classes.root}>
            <div className={classes.titleRow}>
                <Typography variant="h6">
                    Kontaktdaten
                </Typography>
            </div>
            <Box p={2} pt={0}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sameInvoiceAddress}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setSameInvoiceAddress(event.target.checked);
                                        if (event.target.checked) {
                                            setCustomer(prev => {
                                                setEdited(true);
                                                return {
                                                    ...prev,
                                                    invoiceAddress: { ...prev.postAddress },
                                                }
                                            });
                                        }
                                    }}
                                    value="sameInvoiceAddress" />
                            }
                            label="Lieferadresse ist gleich Rechnungsadresse"
                        />
                        {sameInvoiceAddress ?
                            <AddressForm defaultValue={defaultPostAddress} title="Adresse" withoutPaper onAddressChange={
                                (a, v) => {
                                    setCustomer(prev => {
                                        setEdited(true);
                                        return {
                                            ...prev,
                                            invoiceAddress: { ...prev.invoiceAddress, ...a },
                                            postAddress: { ...prev.postAddress, ...a },
                                        }
                                    });

                                }
                            } /> :
                            <React.Fragment>
                                <AddressForm defaultValue={defaultPostAddress} title="Lieferadresse" withoutPaper onAddressChange={
                                    (a, v) => {
                                        setCustomer(prev => {
                                            setEdited(true);
                                            return {
                                                ...prev,
                                                postAddress: { ...prev.postAddress, ...a },
                                            }
                                        });

                                    }
                                } />
                                <Box p={2}></Box>
                                <AddressForm defaultValue={defaultInvoiceAddress} title="Rechnungsadresse" withoutPaper onAddressChange={
                                    (a, v) => {
                                        setCustomer(prev => {
                                            setEdited(true);
                                            return {
                                                ...prev,
                                                invoiceAddress: { ...prev.invoiceAddress, ...a },
                                            }
                                        });

                                    }
                                } />
                            </React.Fragment>
                        }

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Zustellart der Rechnung</FormLabel>
                                <RadioGroup aria-label="useEmailForInvoice"
                                    value={customer.contactPreferences.useEmailForInvoice}
                                    onChange={handleInputChange("contactPreferences.useEmailForInvoice", "boolean")}
                                    row>
                                    <FormControlLabel value={false} control={<Radio />} label="per Post" />
                                    <FormControlLabel value={true} control={<Radio />} label="elektronisch" />
                                </RadioGroup>
                            </FormControl>
                        </Box>

                        <Box flexGrow={1}>
                            <TextField
                                label="Emailadresse für Rechnungen"
                                variant={variant} margin={margin} fullWidth
                                value={customer.contactPreferences.emailForInvoice}
                                onChange={handleInputChange("contactPreferences.emailForInvoice")}
                                disabled={!customer.contactPreferences.useEmailForInvoice}
                            />
                        </Box>

                        <Box p={2}></Box>
                        <Typography variant="subtitle2">
                            Weitere Kontaktdaten
                        </Typography>
                        <Box flexGrow={1}>
                            <TextField
                                label="Telefon"
                                variant={variant} margin={margin} fullWidth
                                value={customer.contactPreferences.phone}
                                onChange={handleInputChange("contactPreferences.phone")}
                            />
                        </Box>

                        <Box p={1}></Box>
                        <Typography variant="caption">
                            Emailadressen
                        </Typography>
                        <ManageEmailList
                            emails={customer.contactPreferences.otherEmails}
                            onChange={(emails) => {
                                setEdited(true);
                                setCustomer(prev => {
                                    return {
                                        ...prev,
                                        contactPreferences: {
                                            ...prev.contactPreferences,
                                            otherEmails: emails.slice(),
                                        }
                                    }
                                })
                            }}
                        />

                        <Box p={1}></Box>
                        <Typography variant="caption">
                            Webseiten
                        </Typography>
                        <ManageWebsiteList
                            urls={customer.contactPreferences.websites}
                            onChange={(urls) => {
                                setEdited(true);
                                setCustomer(prev => {
                                    return {
                                        ...prev,
                                        contactPreferences: {
                                            ...prev.contactPreferences,
                                            websites: urls.slice(),
                                        }
                                    }
                                })
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Paper>

    );

    const paymentDefaultsForm = (
        <Paper className={classes.root}>
            <div className={classes.titleRow}>
                <Typography variant="h6">
                    Standard-Einstellungen
                </Typography>
            </div>


            <Box display="flex" className={classes.inputContainer} width="100%" flexWrap="wrap">

                <FormControl component="fieldset">
                    <FormLabel component="legend">Zahlungsmodalität</FormLabel>
                    <RadioGroup aria-label="useCashDiscount" name="useCashDiscount" value={customer.paymentPreferences.useDiscount}
                        onChange={handleInputChange("paymentPreferences.useDiscount", "boolean")}
                        row>
                        <FormControlLabel value={false} control={<Radio />} label="ohne Abzug" />
                        <FormControlLabel value={true} control={<Radio />} label="mit Skonto" />
                    </RadioGroup>
                </FormControl>
                {
                    customer.paymentPreferences.useDiscount ?
                        <React.Fragment>
                            <Box width={200}>
                                <TextField
                                    label="Skonto Tage"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    variant={variant} margin={margin} fullWidth
                                    value={customer.paymentPreferences.discount.days.toString()}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = parseInt(event.target.value);
                                            setCustomer((prev: any) => {
                                                setEdited(true);
                                                return {
                                                    ...prev,
                                                    paymentPreferences: {
                                                        ...prev.paymentPreferences,
                                                        discount: {
                                                            ...prev.paymentPreferences.discount,
                                                            days: Number.isNaN(value) ? 0 : value,
                                                        },
                                                    },
                                                };
                                            });
                                        }
                                    }
                                />
                            </Box>
                            <Box width={200}>
                                <TextField
                                    label="Skonto %"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    variant={variant} margin={margin} fullWidth
                                    value={(customer.paymentPreferences.discount.value * 100).toString()}
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = parseFloat(event.target.value) / 100;
                                            setCustomer((prev: any) => {
                                                setEdited(true);
                                                return {
                                                    ...prev,
                                                    paymentPreferences: {
                                                        ...prev.paymentPreferences,
                                                        discount: {
                                                            ...prev.paymentPreferences.discount,
                                                            value: Number.isNaN(value) ? 0 : value,
                                                        },
                                                    },
                                                };
                                            });
                                        }
                                    }
                                />
                            </Box>
                        </React.Fragment>
                        : null
                }
                <Box width={200}>
                    <TextField
                        label="Zahlungsziel (Tage)"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        variant={variant} margin={margin} fullWidth
                        value={(customer.paymentPreferences.paymentGoal).toString()}
                        onChange={handleInputChange('paymentPreferences.paymentGoal', 'int')}
                    />
                </Box>
            </Box>
            <Box display="flex" p={1} width="100%" flexWrap="wrap">
                <Tooltip title="z.B. bei Rechnungen ins Ausland">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={customer.paymentPreferences.zeroTaxRateByDefault}
                                onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = event.target.checked;
                                        setCustomer((prev: any) => {
                                            setEdited(true);
                                            return {
                                                ...prev,
                                                paymentPreferences: {
                                                    ...prev.paymentPreferences,
                                                    zeroTaxRateByDefault: value,
                                                },
                                            };
                                        });
                                    }
                                }
                                value="zeroTaxRate" />
                        }
                        label="Standardmäßig keine Mehrwertsteuer für diesen Kunden berechnen"
                    />
                </Tooltip>
            </Box>
        </Paper>
    );

    const formMargin = 2;
    return (
        <div>
            <Box m={formMargin}>
                {customerDataForm}
            </Box>
            <Box m={formMargin}>
                {contactDataForm}
            </Box>
            <Box m={formMargin}>
                {paymentDefaultsForm}
            </Box>

            <Box m={formMargin} textAlign="right" className={classes.inputContainer}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={
                        () => {
                            setEdited(false);
                            props.onSave(customer);
                        }
                    }
                >Speichern</Button>
                <DangerBtn
                    onClick={() => {
                        props.onCancel(customer);
                    }}
                >
                    Abbrechen
                </DangerBtn>
            </Box>
        </div>
    );
};

export default ContactEditor;
