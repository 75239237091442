import { Box } from '@material-ui/core';
import React from 'react';

interface ColumnHeaderProps {
    px?: number;
    py?: number;
    borderTop?: boolean;
    borderBottom?: boolean;
    borderRight?: boolean;
    borderLeft?: boolean;
}

const ColumnHeader: React.FC<ColumnHeaderProps> = (props) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            height={75}
            bgcolor="white"
            borderBottom={props.borderBottom ? "solid 1px rgba(0, 0, 0, 0.12)" : undefined}
            borderTop={props.borderTop ? "solid 1px rgba(0, 0, 0, 0.12)" : undefined}
            borderLeft={props.borderLeft ? "solid 1px rgba(0, 0, 0, 0.12)" : undefined}
            borderRight={props.borderRight ? "solid 1px rgba(0, 0, 0, 0.12)" : undefined}
            px={props.px !== undefined ? props.px : 2}
            py={props.py !== undefined ? props.py : 2}
        >
            {props.children}
        </Box>
    );
};

export default ColumnHeader;
