import React, { useState, useEffect } from 'react';
import Address from '../../../../models/address';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


interface AddressFormProps {
    defaultValue?: Address;
    title?: string;
    withoutPaper?: boolean;
    showErrors?: boolean;
    onAddressChange?: (address: Address, valid: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3),
        },
        titleRow: {
            paddingBottom: theme.spacing(2),
            textAlign: 'start',
        }
    })
);

const AddressForm: React.FC<AddressFormProps> = (props) => {
    const classes = useStyles();

    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'

    const [address, setAddress] = useState<Address>(
        props.defaultValue ? props.defaultValue : {
            street: '',
            streetExtra: '',
            postalCode: '',
            city: '',
            country: ''
        }
    )

    const [touched, setTouched] = useState({
        street: false,
        streetExtra: false,
        postalCode: false,
        city: false,
        country: false,
    });

    const [errors, setErrors] = useState<{
        street?: string;
        streetExtra?: string;
        postalCode?: string;
        city?: string;
        country?: string;
    }>({});

    useEffect(() => {
        if (props.defaultValue) {
            setAddress(props.defaultValue);
        }
    }, [props.defaultValue])


    const handleInputChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        validateRequiredFields(key, value);
        setAddress(prev => {
            const newAdress: Address = {
                ...prev,
                [key]: value,
            }
            if (props.onAddressChange) {
                props.onAddressChange(newAdress, !hasErrors());
            }
            return newAdress;
        })
    }

    const validateRequiredFields = (key: string, value: string) => {
        if (key === 'street' || key === 'city' || key === 'postalCode' || key === 'country') {
            const currentError = value.length < 1 ? 'empty field' : '';
            setErrors(prev => {
                if (currentError === '') {
                    delete prev[key];
                    return { ...prev }
                }
                return { ...prev, [key]: currentError }
            })
        }
    }

    const hasErrors = (): boolean => {
        return Object.keys(errors).length > 0;
    }

    const checkError = (name: string): boolean => {
        if (name !== "street" && name !== "streetExtra" && name !== "city" && name !== "postalCode" && name !== "country") {
            return false;
        }
        if (errors[name] && (touched[name] || props.showErrors)) {
            return true;
        }
        return false;
    }

    const handleBlur = (name: string) => (event: React.FocusEvent<HTMLInputElement>) => {
        setTouched(prev => { return { ...prev, [name]: true } });
        const value = event.target.value;
        validateRequiredFields(name, value);
    }

    const content = (
        <React.Fragment>
            {props.title ? <div className={classes.titleRow}>
                <Typography variant="h6">
                    {props.title}
                </Typography> </div> : null
            }
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        label="Adresszeile 1"
                        required
                        variant={variant} fullWidth margin={margin}
                        value={address.street}
                        error={checkError("street")}
                        onChange={handleInputChange('street')}
                        onBlur={handleBlur('street')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Adresszeile 2"
                        variant={variant} fullWidth margin={margin}
                        value={address.streetExtra}
                        error={checkError("streetExtra")}
                        onChange={handleInputChange('streetExtra')}
                        onBlur={handleBlur('streetExtra')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="PLZ"
                        required
                        variant={variant} fullWidth margin={margin}
                        value={address.postalCode}
                        error={checkError("postalCode")}
                        onChange={handleInputChange('postalCode')}
                        onBlur={handleBlur('postalCode')}
                    />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        label="Stadt"
                        required
                        variant={variant} fullWidth margin={margin}
                        value={address.city}
                        error={checkError("city")}
                        onChange={handleInputChange('city')}
                        onBlur={handleBlur('city')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Land"
                        required
                        variant={variant} fullWidth margin={margin}
                        value={address.country}
                        error={checkError("country")}
                        onChange={handleInputChange('country')}
                        onBlur={handleBlur('country')}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );

    if (props.withoutPaper) {
        return (
            <div>
                {content}
            </div>
        );
    }

    return (
        <Paper className={classes.root}>
            {content}
        </Paper>
    );
};

export default AddressForm;
