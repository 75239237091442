// State:
export interface SystemState {
    drawerOpen: boolean;
    loading: boolean;
    error: string;
}

// Action Types:
export const OPEN_NAVIGATION_DRAWER = 'OPEN_NAVIGATION_DRAWER';
export const CLOSE_NAVIGATION_DRAWER = 'CLOSE_NAVIGATION_DRAWER';
export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';
export const SET_ERROR = 'SET_ERROR';

interface OpenNavigationDrawerAction {
    type: typeof OPEN_NAVIGATION_DRAWER
}
interface CloseNavigationDrawerAction {
    type: typeof CLOSE_NAVIGATION_DRAWER
}

interface StartLoadingAction {
    type: typeof START_LOADING
}

interface FinishLoadingAction {
    type: typeof FINISH_LOADING
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    message: string;
}


export type SystemActionTypes =
    OpenNavigationDrawerAction |
    CloseNavigationDrawerAction |
    StartLoadingAction |
    FinishLoadingAction |
    SetErrorAction;
