import React, { useState, useEffect } from 'react';
import InvoiceItem, { getNettoPriceOfItem } from '../../../../../../models/invoice/invoiceItem';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DangerBtn from '../../../../../common/DangerBtn/DangerBtn';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import DurationEditor from '../../../../CommonForms/DurationEditor/DurationEditor';
import { CircularProgress } from '@material-ui/core';
import ItemTemplateSelector from './ItemTemplateSelector/ItemTemplateSelector';
import { ObjectID } from 'bson';

interface InvoiceItemEditorProps {
    item?: InvoiceItem;
    templates?: InvoiceItem[];
    currency: string;
    defaultTaxRate?: number;
    loading: boolean;
    onSave: (item: InvoiceItem) => void;
    onRemoveTemplate?: (item: InvoiceItem) => void;
    onSaveAsTemplate: (item: InvoiceItem) => void;
    onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        saveBtn: {
            marginRight: theme.spacing(1),
        },
        formControl: {
            minWidth: 100,
        },
    })
);

const emptyItem: InvoiceItem = {
    id: '',
    title: '',
    description: '',
    quantity: 1,
    unit: 'all-inclusive',
    unitPrice: 0.0,
    taxRate: 0.0,
    surchargeReference: '',
};

const supportedTaxRates = [0.0, 0.05, 0.07, 0.16, 0.19];

const InvoiceItemEditor: React.FC<InvoiceItemEditorProps> = (props) => {
    const classes = useStyles();
    const variant = 'outlined';
    const margin = 'dense'; // 'dense' | 'normal'

    const [item, setItem] = useState<InvoiceItem>(
        props.item ? props.item : {
            ...emptyItem,
            taxRate: props.defaultTaxRate === undefined ? supportedTaxRates[supportedTaxRates.length - 1] : props.defaultTaxRate
        }
    );

    const isNewItem = props.item === undefined;

    useEffect(() => {
        if (!props.item) {
            setItem({
                ...emptyItem,
                taxRate: props.defaultTaxRate === undefined ? supportedTaxRates[supportedTaxRates.length - 1] : props.defaultTaxRate
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultTaxRate])


    const handleChange = (key: string, dtype?: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
        let value: string | number = event.target.value as string;
        switch (dtype) {
            case 'float':
                console.log(value);
                if (value === '') {
                    break;
                }
                value = parseFloat(value);
                value = Number.isNaN(value) ? 0 : value;
                break;
        }
        setItem(prev => {
            if (key === "unit") {
                if (value === "all-inclusive") {
                    return {
                        ...prev,
                        [key]: value,
                        quantity: 1,
                    };
                } else if (value === "time") {
                    return {
                        ...prev,
                        [key]: value,
                        quantity: 0,
                    };
                }

            }
            return {
                ...prev,
                [key]: value
            };
        })
    };

    const updateWithTimeValue = (newValue: number) => {
        setItem(prev => {
            return {
                ...prev,
                quantity: newValue,
            };
        })
    }

    const inputLabelUnit = React.useRef<HTMLLabelElement>(null);
    const inputLabelTax = React.useRef<HTMLLabelElement>(null);

    const [labelWidthUnit, setLabelWidthUnit] = React.useState(0);
    const [labelWidthTax, setLabelWidthTax] = React.useState(0);

    React.useEffect(() => {
        setLabelWidthUnit(inputLabelUnit.current!.offsetWidth);
        setLabelWidthTax(inputLabelTax.current!.offsetWidth);
    }, []);

    return (
        <Box border="dashed #f59342 2px" borderRadius={5} p={1}>
            <Grid container spacing={1}>
                {
                    isNewItem ? <Grid item xs={12} sm={7}>
                        <ItemTemplateSelector
                            loading={props.loading}
                            currency={props.currency}
                            onRemoveTemplate={props.onRemoveTemplate ? props.onRemoveTemplate : () => { }}
                            templates={props.templates ? props.templates : []}
                            onClose={(template) => {
                                if (template) {
                                    template.id = "";
                                    setItem(template);
                                }
                            }}
                        />
                    </Grid> : null}
                <Grid item xs={12} sm={7}>
                    <TextField
                        fullWidth
                        label="Bezeichnung"
                        value={item.title}
                        variant={variant} margin={margin}
                        onChange={handleChange('title')}
                    />
                    <TextField
                        fullWidth
                        label="Detailierte Beschreibung"
                        value={item.description}
                        multiline
                        rows={3}
                        variant={variant} margin={margin}
                        onChange={handleChange('description')}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box display="flex" flexWrap="wrap">
                        <Box width="50%" px={1}>
                            {
                                item.unit === 'time' ?
                                    <DurationEditor
                                        duration={item.quantity}
                                        onChange={updateWithTimeValue}
                                    /> :
                                    <TextField
                                        fullWidth
                                        label="Menge"
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={item.quantity.toString()}
                                        variant={variant} margin={margin}
                                        onChange={handleChange('quantity', 'float')}
                                        disabled={item.unit === "all-inclusive"}
                                    />
                            }

                        </Box>
                        <Box width="50%">
                            <FormControl
                                variant="outlined"
                                margin={margin}
                                className={classes.formControl}
                                fullWidth
                            >
                                <InputLabel ref={inputLabelUnit} id="unit-selector-label">
                                    Einheit
                                </InputLabel>
                                <Select
                                    labelId="unit-selector-label"
                                    id="unit-selector"
                                    value={item.unit}
                                    labelWidth={labelWidthUnit}
                                    onChange={handleChange('unit')}
                                >
                                    <MenuItem value={"piece"}>Stück</MenuItem>
                                    <MenuItem value={"percentage"}>Prozent</MenuItem>
                                    <MenuItem value={"all-inclusive"}>pauschal</MenuItem>
                                    <MenuItem value={"time"}>Zeit</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box width="50%" px={1}>
                            <TextField
                                fullWidth
                                label={"Einzelpreis (" + props.currency + ")"}
                                type="number"
                                value={item.unitPrice}
                                onFocus={(event) => event.target.select()}
                                variant={variant} margin={margin}
                                onChange={handleChange('unitPrice', 'float')}
                            />
                        </Box>
                        <Box width="50%">
                            <FormControl
                                variant="outlined"
                                margin={margin}
                                className={classes.formControl}
                                fullWidth
                            >
                                <InputLabel ref={inputLabelTax} id="tax-selector-label">
                                    USt.
                                </InputLabel>
                                <Select
                                    labelId="tax-selector-label"
                                    id="tax-selector"
                                    value={item.taxRate}
                                    labelWidth={labelWidthTax}
                                    onChange={handleChange('taxRate')}
                                >
                                    {
                                        supportedTaxRates.map(tr =>
                                            <MenuItem value={tr} key={tr}>
                                                {tr.toLocaleString('de-DE', { style: 'percent' })}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box minWidth="100%" width="100%" textAlign="right">
                            <Typography variant="h6">
                                Netto: {getNettoPriceOfItem(item).toLocaleString('de-DE', { style: 'currency', currency: props.currency })}
                            </Typography>
                        </Box>
                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <Box textAlign="right" display="flex" justifyContent="flex-end">
                        {
                            props.loading ?
                                <Box textAlign="center" width="300px" pt={1}>
                                    <CircularProgress className={classes.saveBtn} size="20px" />
                                </Box>
                                : <Button
                                    className={classes.saveBtn}
                                    onClick={
                                        () => {
                                            props.onSaveAsTemplate({
                                                ...item,
                                                id: ''
                                            });
                                        }
                                    }
                                    disabled={item.title.length < 1}
                                >
                                    Als neue Vorlage speichern
                                </Button>
                        }
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.saveBtn}
                                onClick={
                                    () => {
                                        if (item.id.length === 0) {
                                            const id = new ObjectID();
                                            item.id = id.toHexString();
                                        }
                                        props.onSave(item);
                                        setItem({
                                            ...emptyItem,
                                            taxRate: props.defaultTaxRate === undefined ? supportedTaxRates[supportedTaxRates.length - 1] : props.defaultTaxRate
                                        });
                                    }
                                }>
                                <span>{!isNewItem ? "Speichern" : "Hinzufügen"}</span>
                            </Button>
                            <DangerBtn
                                onClick={
                                    () => props.onCancel()
                                }
                            >
                                <span>Abbrechen</span>
                            </DangerBtn>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default InvoiceItemEditor;
