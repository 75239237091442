import Person from "../models/person";

const defaultGenderPrefixes = [
    { gender: 'male', prefix: 'Herr' },
    { gender: 'female', prefix: 'Frau' },
];

export const personToString = (person: Person, genderPerfixes: Array<{
    gender: string; prefix: string;
}> = defaultGenderPrefixes, withFirstName?: boolean): string => {
    let p = '';
    const prefix = genderPerfixes.find(gp => gp.gender === person.gender);
    if (prefix) { p += prefix.prefix; }
    if (person.title && person.title.length > 0) { p += ' ' + person.title; }
    if (withFirstName && person.firstName && person.firstName.length > 0) { p += ' ' + person.firstName; }
    if (person.lastName && person.lastName.length > 0) { p += ' ' + person.lastName; }
    return p;
}
