import { Invoice } from "../../../models/invoice";

export interface DraftInvoiceState {
    selected?: Invoice;
    loading: boolean;
    items: Invoice[];
}

// Action Types:
export const FETCH_DRAFT_INVOICES_START = "FETCH_DRAFT_INVOICES_START";
export const FETCH_DRAFT_INVOICES_FAILED = "FETCH_DRAFT_INVOICES_FAILED";
export const RECEIVE_DRAFT_INVOICES = "RECEIVE_DRAFT_INVOICES";
export const ADD_DRAFT_INVOICE = 'ADD_DRAFT_INVOICE';
export const UPDATE_DRAFT_INVOICE = 'UPDATE_DRAFT_INVOICE';
export const REMOVE_DRAFT_INVOICE = 'REMOVE_DRAFT_INVOICE';
export const SELECT_DRAFT_INVOICE = 'SELECT_DRAFT_INVOICE';


interface FetchDraftInvoicesStartAction {
    type: typeof FETCH_DRAFT_INVOICES_START;
}

interface FetchDraftInvoicesFailedAction {
    type: typeof FETCH_DRAFT_INVOICES_FAILED;
}

interface ReceiveDraftInvoicesAction {
    type: typeof RECEIVE_DRAFT_INVOICES;
    invoices: Invoice[];
}

interface AddDraftInvoiceAction {
    type: typeof ADD_DRAFT_INVOICE;
    newInvoice: Invoice;
}

interface UpdateDraftInvoiceAction {
    type: typeof UPDATE_DRAFT_INVOICE;
    updatedInvoice: Invoice;
}

interface RemoveDraftInvoiceAction {
    type: typeof REMOVE_DRAFT_INVOICE;
    id: string;
}

interface SelectDraftInvoiceAction {
    type: typeof SELECT_DRAFT_INVOICE;
    invoice: Invoice | undefined;
}


export type DraftInvoiceActionTypes =
    FetchDraftInvoicesStartAction | FetchDraftInvoicesFailedAction | ReceiveDraftInvoicesAction |
    AddDraftInvoiceAction | UpdateDraftInvoiceAction | RemoveDraftInvoiceAction | SelectDraftInvoiceAction;
