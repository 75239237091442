import {
    InvoiceConfigsState,
    InvoiceConfigActionTypes,
    FETCH_INVOICE_CONFIGS_START,
    FETCH_INVOICE_TAGS_START,
    FETCH_INVOICE_TAGS_FAILED,
    RECEIVE_INVOICE_TAGS,
} from './types'
import { updateObject } from '../../utils'
import { ppmVItemplates } from '../../../models/invoiceTemplates';


const initialState: InvoiceConfigsState = {
    loading: false,
    templates: ppmVItemplates,
    tags: [],
}

export function invoiceConfigReducer(
    state = initialState,
    action: InvoiceConfigActionTypes
): InvoiceConfigsState {
    switch (action.type) {
        case FETCH_INVOICE_CONFIGS_START:
            return updateObject(state, { loading: true });
        case FETCH_INVOICE_TAGS_START:
            return updateObject(state, { loading: true, tags: [] });
        case FETCH_INVOICE_TAGS_FAILED:
            return updateObject(state, { loading: false });
        case RECEIVE_INVOICE_TAGS:
            return updateObject(state, { loading: false, tags: [...action.tags] });
        default:
            return state
    }
}


