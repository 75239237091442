import {
    FinalInvoiceActionTypes,
    FETCH_FINAL_INVOICES_START, FETCH_FINAL_INVOICES_FAILED, RECEIVE_FINAL_INVOICES,
    ADD_FINAL_INVOICE, UPDATE_FINAL_INVOICE, SELECT_FINAL_INVOICE, InvoiceQuery, SET_FINAL_INVOICE_QUERY,
} from "./types";
import { Invoice } from "../../../models/invoice";

export function fetchingFinalInvoicesStart(): FinalInvoiceActionTypes {
    return {
        type: FETCH_FINAL_INVOICES_START,
    }
}

export function fetchingFinalInvoicesFailed(): FinalInvoiceActionTypes {
    return {
        type: FETCH_FINAL_INVOICES_FAILED,
    }
}

export function receiveFinalInvoices(invoices: Invoice[]): FinalInvoiceActionTypes {
    return {
        type: RECEIVE_FINAL_INVOICES,
        invoices: invoices,
    }
}

export function addFinalInvoice(newInvoice: Invoice): FinalInvoiceActionTypes {
    return {
        type: ADD_FINAL_INVOICE,
        newInvoice: newInvoice,
    }
}

export function updateFinalInvoice(updatedInvoice: Invoice): FinalInvoiceActionTypes {
    return {
        type: UPDATE_FINAL_INVOICE,
        updatedInvoice: updatedInvoice,
    }
}


export function selectFinalInvoice(invoice: Invoice | undefined): FinalInvoiceActionTypes {
    return {
        type: SELECT_FINAL_INVOICE,
        invoice: invoice,
    }
}

export function setFinalInvoiceQuery(query: InvoiceQuery): FinalInvoiceActionTypes {
    return {
        type: SET_FINAL_INVOICE_QUERY,
        query: query,
    }
}
