import InvoiceItem from "./invoiceItem";
import { InvoiceCustomerInfo, DeliveryDate, PaymentInfos, InvoiceComponent, InvoiceTotals } from "./invoiceComponents";
import PriceDiscount from "./surcharge";

export interface IInvoiceContent {
    customer: InvoiceCustomerInfo;
    items?: Array<InvoiceItem>;
    surcharges?: Array<PriceDiscount>;
    totals: InvoiceTotals;
    dates: { invoiceDate: number; deliveryDate: DeliveryDate };
    payment: PaymentInfos;
    standardInfos: Array<InvoiceComponent>;
    customInfos: Array<InvoiceComponent>;
}
export default class InvoiceContent implements IInvoiceContent {
    customer: InvoiceCustomerInfo = {
        customerId: '',
        customerNumber: '',
        companyName: '',
        department: '',
        contactPerson: { firstName: '', lastName: '', gender: '', title: '' },
        address: { city: '', country: '', street: '', streetExtra: '', postalCode: '' },
        VAT: '',
        internalReference: '',
        showCustomerNumber: true,
        showInternalReference: false,
        showVAT: true,
        useEmailForInvoice: false,
        emailForInvoice: '',
        defaultTaxRate: 0.19,
    };
    items?: InvoiceItem[] = [];
    surcharges?: PriceDiscount[] = [];
    totals = {
        netto: 0,
        brutto: 0,
        taxes: [],
    };
    dates = {
        invoiceDate: 0,
        deliveryDate: {
            type: '',
            label: '',
            startDate: 0,
            endDate: 0,
        }
    };
    payment: PaymentInfos = {
        cashDiscount: { enabled: false, targetDays: 0, amount: 0 }, // Skonto
        currency: 'EUR',
        useReverseCharge: false,
        showPaymentGoal: true,
        paymentGoal: 30
    };
    standardInfos: InvoiceComponent[] = [];
    customInfos: InvoiceComponent[] = [];

    constructor(obj?: object) {
        if (obj) {
            Object.assign(this, obj);
            return;
        }
    }
}
