import React, { useState, useEffect } from 'react';
import ContactEditor from '../../../components/ui/Addressbook/ContactEditor/ContactEditor';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Prompt, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Customer from '../../../models/customer';
import { createCustomer, updateCustomer, finishEditCustomer } from '../../../store/addressbook/actionAsync';
import { useReduxDispatch } from '../../../store/utils';
import { CustomerState } from '../../../store/addressbook/types';

const CustomerEditorPage: React.FC = () => {
    const isLoading = useSelector((state: { customers: CustomerState }) => state.customers.loading);
    const editorState = useSelector((state: { customers: CustomerState }) => state.customers.editor);

    const dispatch = useReduxDispatch();

    const [protect, setProtect] = useState(false);
    // To protect navigating away when changes happen - but ignore initial change
    useEffect(() => {
        setTimeout(() => {
            setProtect(false);
        }, 2000);
    }, []);

    const history = useHistory();

    const onSaveCustomer = (customer: Customer) => {
        console.warn('todo save customer');
        console.log(customer);
        let dpm = null;
        switch (editorState.mode) {
            case 'create':
                dpm = createCustomer(customer);
                break;
            case 'edit':
                dpm = updateCustomer(customer);
                break;
            default:
                return;
        }
        dispatch(dpm).then(async id => {
            console.log(id);
            if (id.length > 0) {
                if (editorState.mode === 'edit') {
                    const finished = await dispatch(finishEditCustomer(customer.id));
                    if (!finished) {
                        return;
                    }
                }
                console.log(protect);
                setProtect(false);
                history.replace('/home/addressbook');
            }
        });
    }

    return (
        <React.Fragment>
            {isLoading ? <LinearProgress /> : null
            }
            <Prompt when={protect} message="Nicht gespeicherte Änderungen gehen verloren. Wollen Sie trotzdem fortfahren?" />
            <Container maxWidth="lg" disableGutters={true}>
                <Box p={2} pb={0} mt={3}>
                    <Box display="flex" alignItems="center" mb={2}>
                        <Typography variant="h5">
                            {editorState.mode === 'create' ? 'Neuer Kunde' : 'Kundendaten bearbeiten'}
                        </Typography>
                    </Box>
                </Box>
                <ContactEditor
                    customer={editorState.customer}
                    onModified={() => { setProtect(true) }}
                    onSave={onSaveCustomer}
                    onCancel={(customer) => {
                        if (editorState.mode === 'edit') {
                            dispatch(finishEditCustomer(customer.id))
                        }
                        history.goBack();
                    }} />
            </Container>
        </React.Fragment>

    );
};

export default CustomerEditorPage;
