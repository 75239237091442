import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

interface DangerBtnProps extends ButtonProps {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cancelBtn: {
            color: "white",
            backgroundColor: red[500],
            "&:hover": {
                backgroundColor: red['A200'],
            }
        },
    })
);

const DangerBtn: React.FC<DangerBtnProps> = (props) => {
    const classes = useStyles();
    return (
        <Button {...props} className={classes.cancelBtn}></Button>
    );
};

export default DangerBtn;
