import {
    InvoiceItemTemplateActionTypes,
    FETCH_INVOICE_ITEM_TEMPLATES_START, FETCH_INVOICE_ITEM_TEMPLATES_FAILED,
    RECEIVE_INVOICE_ITEM_TEMPLATES, ADD_INVOICE_ITEM_TEMPLATE, REMOVE_INVOICE_ITEM_TEMPLATE
} from "./types";
import InvoiceItem from "../../../models/invoice/invoiceItem";


export function fetchInvoiceItemTemplatesStart(): InvoiceItemTemplateActionTypes {
    return {
        type: FETCH_INVOICE_ITEM_TEMPLATES_START,
    }
}

export function fetchInvoiceItemTemplatesFailed(): InvoiceItemTemplateActionTypes {
    return {
        type: FETCH_INVOICE_ITEM_TEMPLATES_FAILED,
    }
}

export function receiveInvoiceItemTemplates(templates: InvoiceItem[]): InvoiceItemTemplateActionTypes {
    return {
        type: RECEIVE_INVOICE_ITEM_TEMPLATES,
        templates,
    }
}

export function addInvoiceItemTemplate(template: InvoiceItem): InvoiceItemTemplateActionTypes {
    return {
        type: ADD_INVOICE_ITEM_TEMPLATE,
        template,
    }
}

export function removeInvoiceItemTemplate(template: InvoiceItem): InvoiceItemTemplateActionTypes {
    return {
        type: REMOVE_INVOICE_ITEM_TEMPLATE,
        template,
    }
}
